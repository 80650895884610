import React from "react"
import styled from "styled-components"

const Box = styled.button`
  outline: none;
  cursor: pointer;
  background: none;
  border: none;

  margin: 0.5rem;
  opacity: ${props => (props.isFiltering ? 1 : 0.5)};
  display: inline-block;
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  svg {
    filter: ${props =>
      props.isFiltering ? "drop-shadow(0px 0px 4px #FF974C)" : null};
  }
`

const ButtonCost = props => {
  return (
    <Box
      toExclude={props.toExclude}
      isFiltering={props.isFiltering}
      mode={props.mode}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        props.handleSwitchIsFiltering("manaCosts", props.code)
      }}
    >
      {props.svg}
    </Box>
  )
}

export default ButtonCost
