import React, { useState } from "react"
import styled from "styled-components"
const lzma = require("lzma/src/lzma_worker.js").LZMA_WORKER

import Table from "./table"
import Raccoglitore from "./raccoglitore"

function decrypt(cardPool, loadedDeck) {
  if (loadedDeck) {
    const hashed = loadedDeck.hashed
    const parsed = JSON.parse(hashed)
    const decompressed = lzma.decompress(parsed)
    const reparsed = JSON.parse(decompressed)
    // PRENDERE DA cardPool LE CARTE con .filter
    // fai un merge
    return reparsed.map(chosenCardEssential => {
      let bigCard = cardPool.find(el => el.name === chosenCardEssential.name)
      return {
        ...bigCard,
        ...chosenCardEssential,
      }
    })
  } else {
    return []
  }
}

const DeckBuilder = ({
  setTopBarWidth,
  displayTableInitial,
  displayRaccoglitoreInitial,
  cardPool,
  saveDeck,
  saveDeckInfo,
  loadedDeck,
  numRefFromParams,
  userTier,
}) => {
  const [displayTable, setDisplayTable] = useState(displayTableInitial)
  const [displayRaccoglitore, setDisplayRaccoglitore] = useState(
    displayRaccoglitoreInitial
  )
  const [chosenCards, setChosenCards] = useState(() =>
    decrypt(cardPool, loadedDeck)
  )

  const [showCTA, setShowCTA] = useState(true)

  return (
    <>
      <Raccoglitore
        displayTable={displayTable}
        setDisplayTable={setDisplayTable}
        setTopBarWidth={setTopBarWidth}
        displayRaccoglitore={displayRaccoglitore}
        setDisplayRaccoglitore={setDisplayRaccoglitore}
        cardPool={cardPool}
        chosenCards={chosenCards}
        setChosenCards={setChosenCards}
        showCTA={showCTA}
        setShowCTA={setShowCTA}
        userTier={userTier}
      />
      <Table
        displayTable={displayTable}
        setDisplayTable={setDisplayTable}
        setTopBarWidth={setTopBarWidth}
        displayRaccoglitore={displayRaccoglitore}
        setDisplayRaccoglitore={setDisplayRaccoglitore}
        chosenCards={chosenCards}
        setChosenCards={setChosenCards}
        saveDeck={saveDeck}
        saveDeckInfo={saveDeckInfo}
        numRefFromParams={numRefFromParams}
        userTier={userTier}
        loadedDeck={loadedDeck}
      />
    </>
  )
}

export default DeckBuilder
