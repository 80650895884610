import React from "react"
import styled from "styled-components"

const ButtonMana = props => {
  return (
    <Box
      isFiltering={props.isFiltering}
      isPermanent={props.isPermanent}
      hasBeenClickedBefore={props.hasBeenClickedBefore}
      mode={props.mode}
    >
      <ButtonSvgCode
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          props.handleSwitchIsFiltering("colors", props.code)
        }}
      >
        {props.svg}
      </ButtonSvgCode>
      {!props.isPermanent && props.mode !== "advanced" && (
        <ButtonDelete
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            props.handleDeleteTempFilter("colors", props.code)
          }}
        >
          X
        </ButtonDelete>
      )}
    </Box>
  )
}

const Box = styled.div`
  /*   margin: 0.5rem; */
  margin-right: 1rem;
  margin-bottom: 0.6rem;
  opacity: ${props => (props.isFiltering ? 1 : 0.5)};
  display: ${props => {
    if (
      props.isPermanent ||
      props.isFiltering ||
      props.hasBeenClickedBefore ||
      props.mode === "advanced"
    ) {
      return "flex"
    } else {
      return "none"
    }
  }};
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  svg {
    filter: ${props =>
      props.isFiltering ? "drop-shadow(0px 0px 4px #FF974C)" : null};
  }
`

const ButtonDelete = styled.button`
  display: flex;
  outline: none;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: #cccccc;
  margin-left: 0.1rem;
  margin-top: 1rem;
  color: #000000;
  justify-content: center;
  align-items: center;
  padding-top: 0.3rem;
  cursor: pointer;
  border: none;
`
const ButtonSvgCode = styled.button`
  outline: none;
  display: flex;
  background: none;
  color: #cccccc;
  padding-top: 0.2rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
`

export default ButtonMana
