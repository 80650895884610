import React, { useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const ButtonType = props => {
  const [buttonState, setButtonState] = useState("default")

  return (
    <Box
      toExclude={props.toExclude}
      isFiltering={props.isFiltering}
      isPermanent={props.isPermanent}
      hasBeenClickedBefore={props.hasBeenClickedBefore}
      variants={variants}
      initial="default"
      animate={buttonState}
      onHoverStart={() => setButtonState("hover")}
      onTapStart={() => setButtonState("tap")}
      onTap={() => setButtonState("default")}
      onHoverEnd={() => setButtonState("default")}
    >
      {props.isFiltering && (
        <ButtonCircle
          toExclude={props.toExclude}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            props.handleSwitchSuggest("types", props.code, "toExclude")
          }}
        />
      )}
      <ButtonSvgCode
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          props.handleSwitchSuggest("types", props.code, "isFiltering")
        }}
      >
        {props.svg && props.svg}
        <Text>{props.code}</Text>
      </ButtonSvgCode>

      {!props.isPermanent && (
        <ButtonDelete
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            props.handleDeleteSuggestion("types", props.code)
          }}
        >
          X
        </ButtonDelete>
      )}
    </Box>
  )
}

const Box = styled(motion.div)`
  margin: 0.5rem;
  padding: 0.1rem 0.8rem;
  border-radius: 1rem;

  opacity: ${props => (props.isFiltering ? 1 : 0.5)};
  display: ${props => {
    if (props.isFiltering || props.isPermanent || props.hasBeenClickedBefore) {
      return "flex"
    } else {
      return "none"
    }
  }};

  justify-content: center;
  align-items: center;
  line-height: 1.6;

  svg {
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
    padding: 0.2rem;
    path {
      fill: #cccccc;
    }
  }
`

const ButtonCircle = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  outline: none;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background-color: ${props => {
    if (props.toExclude) return "#D6001C"
    else return "#00892F"
  }};
  color: red;
  margin-right: 0.6rem;
  justify-content: center;
  align-items: center;
`
const ButtonSvgCode = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  background: none;
  color: #cccccc;
  padding-top: 0.2rem;
  justify-content: center;
  align-items: center;
`
const ButtonDelete = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  outline: none;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: #cccccc;
  margin-left: 0.6rem;
  margin-top: 0.1rem;
  color: #000000;
  justify-content: center;
  align-items: center;
  padding-top: 0.3rem;
`

const Text = styled.span`
  margin-top: 0.3rem;
`

const variants = {
  tap: {
    y: 0,
    backgroundColor: "#161616",
    boxShadow:
      "0px 0px 0px 0px #040404, 0px 1px 0px 0px rgba(255,255,255,0.6), 0px 0px 3px 0px rgba(0,0,0,0.3), inset 0px 1px 0px 0px rgba(255,255,255,0.2)",
  },
  hover: {
    y: -3,
    boxShadow:
      "0px 3px 0px 0px #040404, 0px 0px 0px 0px rgba(255,255,255,0.6), 0px 4px 4px 0px rgba(0,0,0,0.3), inset 0px 1px 0px 0px rgba(255,255,255,0.2)",
    backgroundImage:
      "linear-gradient(-180deg,rgba(255, 255, 255, 0.0) 0%,rgba(0, 0, 0, 0.0) 100%)",
  },
  default: {
    y: -4,
    backgroundColor: "#212121",
    boxShadow:
      "0px 4px 0px 0px #040404, 0px 0px 0px 0px rgba(255, 255, 255, 0.6), 0px 6px 4px 0px rgba(0, 0, 0, 0.3), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2)",
    backgroundImage:
      "linear-gradient(-180deg,rgba(255, 255, 255, 0.1) 0%,rgba(0, 0, 0, 0.1) 100%)",
    transition: {
      delay: 0,
      y: { type: "tween" },
    },
  },
}

export default ButtonType
