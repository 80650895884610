import React from "react"

export default (
  <svg viewBox="0 0 600 750" width={20} height={24}>
    <path
      d="M549.359 651.158c-7.261-3.305-9.76-6.384-9.76-12.025 0-9.638 8.087-14.822 32.814-21.036 10.615-2.668 21.087-6.334 23.271-8.147 6.811-5.653 4.5-16.598-5.236-24.79-23.098-19.435-67.63-21.564-115.409-5.518-36.947 12.408-52.507 14.063-79.627 8.471-34.649-7.144-67.84-25.037-93.952-50.648-26.212-25.709-39.071-47.213-47.172-78.887-13.78-53.877-1.312-108.987 31.842-140.754 22.185-21.256 63.33-33.24 73.219-21.325 3.038 3.66 1.38 5.78-9.816 12.543-38.98 23.544-42.447 77.096-7.393 114.16 29.419 31.107 66.365 43.043 133.333 43.077 77.97.04 108.533 6.47 138.173 29.069 15.918 12.136 33.351 35.333 37.52 49.921 5.02 17.57 7.823 20.679 15.123 16.772 9.13-4.886 17.305-26.03 17.38-44.952.17-43.532-29.413-86.808-73.864-108.048-17.368-8.298-26.877-10.32-98.177-20.878-23.048-3.413-33.23-7.907-48.713-21.501-11.79-10.351-19.405-22.99-19.405-32.203 0-8.913 3.135-9.475 23.067-4.135 14.857 3.981 19.782 4.202 27.008 1.209 13.294-5.507 20.365-19.683 20.421-40.941.113-42.386-35.185-71.21-114.037-93.123-43.447-12.074-82.244-41.43-98.422-74.47-17.206-35.14-22.25-83.462-12.584-120.573 17.922-68.804 82.063-110.928 161.114-105.806 35.583 2.305 62.687 13.968 58.744 25.277-.413 1.184-14.264 2.34-30.779 2.567-19.89.275-34.59 2.122-43.541 5.471-43.635 16.328-61.944 50.845-49.677 93.655 7.336 25.604 28.668 44.992 77.063 70.04 48.433 25.07 50.032 26.214 89.592 64.171 37.994 36.455 51.658 44.9 72.64 44.9 48.475 0 64.724-58.869 28.193-102.145-10.299-12.2-18.442-16.547-60.163-32.112-27.133-10.123-33.202-13.507-33.99-18.95-1.876-12.964 19.996-15.886 58.89-7.869 45.314 9.341 94.9 38.511 137.354 80.8 40.532 40.375 65.356 84.303 80.835 143.045 35.482 134.644-.274 268.712-90.851 340.65-22.29 17.704-59.431 35.454-87.678 41.902-31.37 7.161-94.459 9.004-107.35 3.136z"
      fill="#000"
      transform="translate(-248.758 103.8)"
    />
  </svg>
)
