import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
const lzma = require("lzma/src/lzma_worker.js").LZMA_WORKER

import ButtonMotion from "./buttonMotion"
import ButtonPlain from "./ButtonPlain"
import ButtonManaInDecksBar from "./ButtonManaInDecksBar"

import svgWhiteMana from "../assets/svgWhiteMana"
import svgBluMana from "../assets/svgBluMana"
import svgBlackMana from "../assets/svgBlackMana"
import svgRedMana from "../assets/svgRedMana"
import svgGreenMana from "../assets/svgGreenMana"
import svgBG from "../assets/svgBG"
import svgWBG from "../assets/svgWBG"
import svgBR from "../assets/svgBR"
import svgBRG from "../assets/svgBRG"
import svgUG from "../assets/svgUG"
import svgUBR from "../assets/svgUBR"
import svgWG from "../assets/svgWG"
import svgWUG from "../assets/svgWUG"
import svgWRG from "../assets/svgWRG"
import svgWR from "../assets/svgWR"
import svgWUR from "../assets/svgWUR"
import svgUB from "../assets/svgUB"
import svgUBG from "../assets/svgUBG"
import svgUR from "../assets/svgUR"
import svgURG from "../assets/svgURG"
import svgWBR from "../assets/svgWBR"
import svgWU from "../assets/svgWU"
import svgWUB from "../assets/svgWUB"
import svgWUBRG from "../assets/svgWUBRG"
import svgRG from "../assets/svgRG"
import svgWB from "../assets/svgWB"
import svgWUBR from "../assets/svgWUBR"
import svgWUBG from "../assets/svgWUBG"
import svgWURG from "../assets/svgWURG"
import svgWBRG from "../assets/svgWBRG"
import svgUBRG from "../assets/svgUBRG"
import InputComponent from "./inputComponent"
import Spinner from "./Spinner"

/* const deckObjToSave = {
  name: "pub-raro-Frostwalk Bastion,-StandardBO3",
  isPublic: true,
  format: "StandardBO3",
  color: "RG",
  cards: "Frostwalk Bastion,",
  hashed:
    "[93,0,0,-128,0,83,0,0,0,0,0,0,0,0,45,-98,-64,70,-29,67,106,81,-104,119,-121,-67,-42,19,-99,-111,-43,126,-96,-118,-51,-26,30,108,97,41,2,-119,-128,-96,-67,104,-83,82,-68,77,9,51,-57,-101,85,-26,28,-45,15,115,-6,-121,117,-121,91,122,-14,51,45,-41,-78,-115,-120,-49,-41,93,-56,29,-118,101,-80,-24,-45,6,75,2,7,-126,-32,-67,79,-85,45,72,55,-104,-2,-108,12,0]",
}
if (props.params["*"]?.length > 0) {
  deckObjToSave["numRefFromParams"] = props.params["*"]
} */

function encrypt(chosenCards) {
  if (chosenCards) {
    const chosenCardsEssential = chosenCards.map(card => {
      return {
        place: card.place,
        unico: card.unico,
        hasBeenDragged: card.hasBeenDragged,
        position: card.position,
        // non specific
        card_faces: card.card_faces,
        cmc: card.cmc,
        colors: card.colors,
        color_identity: card.color_identity,
        image_uris: card.image_uris,
        multiverse_ids: card.multiverse_ids,
        name: card.name,
        produced_mana: card.produced_mana,
        tcgplayer_id: card.tcgplayer_id,
        type_line: card.type_line,
      }

      /*       if (card.type_line.includes("Basic")) {
        return {
          name: card.name,
          place: card.place,
          unico: card.unico,
        }
      } else {
        return {
          name: card.name,
          place: card.place,
          unico: card.unico,
          hasBeenDragged: card.hasBeenDragged ? true : false,
          position: {
            x: card.position.x,
            y: card.position.y,
          },
        }
      } */
    })
    const stringified = JSON.stringify(chosenCardsEssential)
    const compressed = lzma.compress(stringified)
    const restringified = JSON.stringify(compressed)
    return restringified
  } else {
    return []
  }
}

function extractNames(chosenCards) {
  if (chosenCards) {
    return chosenCards
      .map(el =>
        el.place === "deck" || el.place === "sideboard" ? el.name + "," : null
      )
      .filter(el => el)
      .join("")
  } else {
    return ""
  }
}

function calculateValidity(name, format, bo, color, chosenCards) {
  if (!name) {
    /*     addToast("What's the name of your deck?", { appearance: "error" }) */
    return false
  } else if (!format) {
    /*     addToast("What's the format of your deck?", { appearance: "error" }) */
    return false
  } else if (!bo) {
    /*     addToast("Is this deck for best-of-one or best-of-three matches?", {
      appearance: "error",
    }) */
    return false
  } else if (!color) {
    /*     addToast("What's the color of your deck?", { appearance: "error" }) */
    return false
  } else if (chosenCards?.length < 1) {
    /*     addToast("What's the color of your deck?", { appearance: "error" }) */
    return false
  }
  return true
}

const SaveWindow = ({
  showSaveWindow,
  setShowSaveWindow,
  chosenCards,
  numRefFromParams,
  saveDeck,
  saveDeckInfo,
  userTier,
  loadedDeck,
}) => {
  /*   console.log(
    "🚀 ~ file: SaveWindow.js ~ line 151 ~ saveDeckInfo",
    saveDeckInfo
  ) */
  const [name, setName] = useState(loadedDeck?.name || "")
  const [isPublic, setIsPublic] = useState(loadedDeck?.isPublic || true)
  const [format, setFormat] = useState(loadedDeck?.format.slice(0, -3) || "")
  const [bo, setBo] = useState(loadedDeck?.format.slice(-3) || "")
  const [color, setColor] = useState(loadedDeck?.color || "")
  /*   const hashed = useState(() => encrypt(chosenCards))
  const cards = useState(() => extractNames(chosenCards)) */

  /*   const [isValid, setIsValid] = useState(() =>
    calculateValidity(name, format, bo, color, chosenCards)
  ) */

  // quando salvi, crea un oggetto usando queste variables,
  // e se numRefFromParams is true, aggiungi anche quello.
  /*   useEffect(() => {
  
    setIsValid(() =>
      calculateValidity(addToast, name, format, bo, color, chosenCards)
    )
  }, [name, format, bo, color, chosenCards]) */

  /*   const isValid = calculateValidity(
    addToast,
    name,
    format,
    bo,
    color,
    chosenCards
  ) */
  const isValid = calculateValidity(name, format, bo, color, chosenCards)

  return (
    <DeckDetailsWrapper
      variants={variants}
      initial="closed"
      animate={showSaveWindow ? "open" : "closed"}
      transition={{ damping: 300 }}
    >
      {saveDeckInfo?.isLoading ? <Spinner /> : null}
      <EasyViewToolbar>
        <ButtonMotion
          handleClick={() => setShowSaveWindow(false)}
          text="Table"
        />
        {/* <button>Buy the deck on TCGPlayer</button> */}
      </EasyViewToolbar>
      <MainSideboard
        style={{
          "--text-shadow": color
            ? "0 1px 12px rgba(0, 137, 47, 1)"
            : "0 1px 12px rgba(214, 0, 28, 1)",
        }}
      >
        Color
      </MainSideboard>
      <FlexRow>
        {allColors.map(singleColor => {
          return (
            <ButtonManaInDecksBar
              key={singleColor.color}
              hasSimpleLogic={true}
              handleSimpleLogicOnClick={() => setColor(singleColor.color)}
              svg={singleColor.svg}
              value={singleColor.color}
              opacity={color === singleColor.color ? 1 : 0.5}
            />
          )
        })}
      </FlexRow>
      <MainSideboard
        style={{
          "--text-shadow":
            format && bo
              ? "0 1px 12px rgba(0, 137, 47, 1)"
              : "0 1px 12px rgba(214, 0, 28, 1)",
        }}
      >
        Format
      </MainSideboard>
      <FlexRow>
        <ButtonPlain
          key={"BO1"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => setBo("BO1")}
          buttonText={"BO1"}
          tooltip={undefined}
          opacity={bo === "BO1" ? 1 : 0.4}
          padding="0rem 2rem"
          minWidth={undefined}
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="50%"
        />
        <ButtonPlain
          key={"BO3"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => setBo("BO3")}
          buttonText={"BO3"}
          tooltip={undefined}
          opacity={bo === "BO3" ? 1 : 0.4}
          padding="0rem 2rem"
          minWidth={undefined}
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="50%"
        />
      </FlexRow>
      <FlexRow>
        {allFormats.map(sFormat => {
          return (
            <ButtonPlain
              key={sFormat}
              hasSimpleLogic={true}
              handleSimpleOnClick={() => setFormat(sFormat)}
              buttonText={sFormat}
              tooltip={undefined}
              opacity={format === sFormat ? 1 : 0.4}
              padding="0rem 2rem"
              minWidth="10rem"
              paddingTop="0.2rem"
              lineHeight="1.6"
              letterSpacing="0.25rem"
              fontSize="1.8rem"
              borderRadius="2rem"
            />
          )
        })}
      </FlexRow>
      <MainSideboard
        style={{
          "--text-shadow": name
            ? "0 1px 12px rgba(0, 137, 47, 1)"
            : "0 1px 12px rgba(214, 0, 28, 1)",
        }}
      >
        Name
      </MainSideboard>
      <InputComponent
        width="50%"
        placeholder="Deck name..."
        type="text"
        value={name}
        handleOnChange={setName}
      />
      <MainSideboard
        style={{
          "--text-shadow": "0 1px 12px rgba(0, 137, 47, 1)",
        }}
      >
        Visibility
      </MainSideboard>
      <FlexRow>
        <ButtonPlain
          key={"Public"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => setIsPublic(true)}
          buttonText={"Public"}
          tooltip={undefined}
          opacity={isPublic ? 1 : 0.4}
          padding="0rem 2rem"
          minWidth={undefined}
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="50%"
        />
        <ButtonPlain
          key={"Private"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => setIsPublic(false)}
          buttonText={"Private"}
          tooltip={undefined}
          opacity={!isPublic ? 1 : 0.4}
          padding="0rem 2rem"
          minWidth={undefined}
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="50%"
          disabled={userTier !== "rare"}
        />
      </FlexRow>
      <br />
      <br />
      <ButtonPlain
        key={"Save"}
        hasSimpleLogic={true}
        handleSimpleOnClick={() => {
          const hashed = encrypt(chosenCards)
          const cards = extractNames(chosenCards)
          if (numRefFromParams) {
            return saveDeck({
              name: name,
              isPublic: isPublic,
              format: format + bo,
              color: color,
              cards: cards,
              hashed: hashed,
              numRefFromParams: numRefFromParams,
            })
          } else {
            return saveDeck({
              name: name,
              isPublic: isPublic,
              format: format + bo,
              color: color,
              cards: cards,
              hashed: hashed,
            })
          }
        }}
        buttonText={"Save"}
        tooltip={undefined}
        opacity={isValid ? 1 : 0.5}
        padding="0rem 2rem"
        minWidth="24rem"
        paddingTop="0.1rem"
        lineHeight="1.6"
        letterSpacing="0.25rem"
        fontSize="2rem"
        borderRadius="1rem"
        disabled={!isValid}
      />
    </DeckDetailsWrapper>
  )
}

const DeckDetailsWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: radial-gradient(#555, #111);
  padding: 1rem;
  z-index: 1000000;
  color: #cccccc;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const EasyViewToolbar = styled.nav`
  display: flex;
  justify-content: flex-start;

  align-self: flex-start;
`

const MainSideboard = styled.span`
  font-size: 2.5rem;
  margin: 1rem;
  margin-top: 4rem;
  text-shadow: var(--text-shadow);
`

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const variants = {
  open: { x: 0 },
  closed: {
    x: "-100%",
    transition: {
      delay: 0,
      x: { type: "tween" },
    },
  },
}

export default SaveWindow

const allColors = [
  {
    color: "W",
    svg: svgWhiteMana,
  },
  {
    color: "U",
    svg: svgBluMana,
  },
  {
    color: "B",
    svg: svgBlackMana,
  },
  {
    color: "R",
    svg: svgRedMana,
  },
  {
    color: "G",
    svg: svgGreenMana,
  },
  {
    color: "WU",
    svg: svgWU,
  },
  {
    color: "WB",
    svg: svgWB,
  },
  {
    color: "WR",
    svg: svgWR,
  },
  {
    color: "WG",
    svg: svgWG,
  },
  {
    color: "UB",
    svg: svgUB,
  },
  {
    color: "UR",
    svg: svgUR,
  },
  {
    color: "UG",
    svg: svgUG,
  },
  {
    color: "BR",
    svg: svgBR,
  },
  {
    color: "BG",
    svg: svgBG,
  },
  {
    color: "RG",
    svg: svgRG,
  },
  {
    color: "WRG",
    svg: svgWRG,
  },
  {
    color: "WUB",
    svg: svgWUB,
  },
  {
    color: "UBR",
    svg: svgUBR,
  },
  {
    color: "BRG",
    svg: svgBRG,
  },
  {
    color: "WUG",
    svg: svgWUG,
  },
  {
    color: "WBG",
    svg: svgWBG,
  },
  {
    color: "URG",
    svg: svgURG,
  },
  {
    color: "WUR",
    svg: svgWUR,
  },
  {
    color: "WBR",
    svg: svgWBR,
  },
  {
    color: "UBG",
    svg: svgUBG,
  },
  {
    color: "UBRG",
    svg: svgUBRG,
  },
  {
    color: "WBRG",
    svg: svgWBRG,
  },
  {
    color: "WURG",
    svg: svgWURG,
  },
  {
    color: "WUBG",
    svg: svgWUBG,
  },
  {
    color: "WUBR",
    svg: svgWUBR,
  },
  {
    color: "WUBRG",
    svg: svgWUBRG,
  },
]

const allFormats = [
  "Standard",
  "Historic",
  "Commander",
  "Modern",
  "Pioneer",
  "Legacy",
  "Pauper",
  "Vintage",
  "Penny",
  "Brawl",
  "Duel",
  "Oldschool",
  "Future",
]
