import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

import ButtonMotion from "../components/buttonMotion"
import DetailButton from "./detailButton"

import svgRedMana from "../assets/svgRedMana"
import svgGreenMana from "../assets/svgGreenMana"
import svgBluMana from "../assets/svgBluMana"
import svgBlackMana from "../assets/svgBlackMana"
import svgWhiteMana from "../assets/svgWhiteMana"
import svg0 from "../assets/svg0"
import svg1 from "../assets/svg1"
import svg2 from "../assets/svg2"
import svg3 from "../assets/svg3"
import svg4 from "../assets/svg4"
import svg5 from "../assets/svg5"
import svg6 from "../assets/svg6"
import svgInfinity from "../assets/svgInfinity"

const mapTypesFIRST = {
  Creature: true,
  Sorcery: true,
  Instant: true,
  Enchantment: true,
  Artifact: true,
  Planeswalker: true,
}

const mapColorIdentities = {
  WIdentity: svgWhiteMana,
  UIdentity: svgBluMana,
  BIdentity: svgBlackMana,
  RIdentity: svgRedMana,
  GIdentity: svgGreenMana,
}
const mapColorCostToSvg = {
  W: svgWhiteMana,
  U: svgBluMana,
  B: svgBlackMana,
  R: svgRedMana,
  G: svgGreenMana,
}

const mapProducedMana = {
  W: "Plains",
  U: "Island",
  B: "Swamp",
  R: "Mountain",
  G: "Forest",
  C: "Colorless",
}

const DeckDetails = ({
  showDeckDetails,
  setShowDeckDetails,
  mapDeckCardsTypeLine,
  mapDeckCardsProducedMana,
  mapDeckCardsColorIdentity,
  mapDeckCardsColorCost,
  totalAllLands,
  averageManaCost,
  mapManaCost,
}) => {
  const [isColorsOpen, switchIsColors] = useState(true)
  const [isTypesOpen, switchIsTypes] = useState(true)
  const [isLandOpen, switchIsLand] = useState(true)
  const [isCurveOpen, switchIsCurve] = useState(true)

  // TYPES
  const deckCardsTypeLineArrayFIRST = []
  const deckCardsTypeLineArraySECOND = []
  for (const [chiave, value] of Object.entries(mapDeckCardsTypeLine)) {
    if (mapTypesFIRST[chiave]) {
      deckCardsTypeLineArrayFIRST.push(
        <DetailButton key={chiave} chiave={chiave} quantity={value} />
      )
    } else {
      deckCardsTypeLineArraySECOND.push(
        <DetailButton key={chiave} chiave={chiave} quantity={value} />
      )
    }
  }
  deckCardsTypeLineArrayFIRST.sort((a, b) => (a.key > b.key ? 1 : -1))
  deckCardsTypeLineArraySECOND.sort((a, b) => (a.key > b.key ? 1 : -1))
  const renderTypes = [
    ...deckCardsTypeLineArrayFIRST,
    ...deckCardsTypeLineArraySECOND,
  ]

  // COLOR COST
  const renderColorCost = []
  const colorCostOrder = ["W", "U", "B", "R", "G"]
  for (const [chiave, valore] of Object.entries(mapDeckCardsColorCost)) {
    renderColorCost.push(
      <DetailButton
        key={chiave}
        chiave={mapColorCostToSvg[chiave]}
        quantity={valore}
      />
    )
  }
  renderColorCost.sort((a, b) =>
    colorCostOrder.indexOf(a.key) > colorCostOrder.indexOf(b.key) ? 1 : -1
  )

  // COLOR IDENTITIES
  /*   const renderColorIdentities = []
  const colorOrder = [
    "WIdentity",
    "UIdentity",
    "BIdentity",
    "RIdentity",
    "GIdentity",
  ]
  for (const [chiave, value] of Object.entries(mapDeckCardsColorIdentity)) {
    renderColorIdentities.push(
      <DetailButton
        key={chiave}
        chiave={mapColorIdentities[chiave]}
        quantity={value}
      />
    )
  }
  renderColorIdentities.sort((a, b) =>
    colorOrder.indexOf(a.key) > colorOrder.indexOf(b.key) ? 1 : -1
  ) */

  // PRODUCED MANA
  const renderProducedMana = []
  const landOrder = ["W", "U", "B", "R", "G"]
  for (const [chiave, value] of Object.entries(mapDeckCardsProducedMana)) {
    renderProducedMana.push(
      <DetailButton
        key={chiave}
        chiave={mapProducedMana[chiave]}
        quantity={value}
      />
    )
  }
  renderProducedMana
    .sort((a, b) =>
      landOrder.indexOf(a.key) > landOrder.indexOf(b.key) ? 1 : -1
    )
    .push(
      <DetailButton
        key={"TotalLand"}
        chiave={"Lands"}
        quantity={totalAllLands}
      />
    )

  return (
    <DeckDetailsWrapper
      variants={variants}
      initial="closed"
      animate={showDeckDetails ? "open" : "closed"}
      transition={{ damping: 300 }}
    >
      <ButtonCloseDetails>
        <ButtonMotion
          handleClick={() => setShowDeckDetails(prev => !prev)}
          text="Table"
        />
      </ButtonCloseDetails>
      <BoxTypes>
        <ButtonMotion
          handleClick={() => switchIsTypes(prev => !prev)}
          text="Type"
        />
        <BoxTypes_Accordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isTypesOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          {renderTypes}
        </BoxTypes_Accordion>
      </BoxTypes>
      <BoxTypes>
        <ButtonMotion
          handleClick={() => switchIsColors(prev => !prev)}
          text="Color"
        />
        <BoxTypes_Accordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isColorsOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          {renderColorCost}
        </BoxTypes_Accordion>
      </BoxTypes>
      <BoxTypes>
        <ButtonMotion
          handleClick={() => switchIsLand(prev => !prev)}
          text="Produced Mana"
        />
        <BoxTypes_Accordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isLandOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          {renderProducedMana}
        </BoxTypes_Accordion>
      </BoxTypes>
      <BoxCurve>
        <ButtonMotion
          handleClick={() => switchIsCurve(prev => !prev)}
          text="Mana Curve"
        />
        <BoxTypes_Accordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isCurveOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["1"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg1}</div>
            <TextQuantity>{mapManaCost["1"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["2"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg2}</div>
            <TextQuantity>{mapManaCost["2"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["3"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg3}</div>
            <TextQuantity>{mapManaCost["3"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["4"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg4}</div>
            <TextQuantity>{mapManaCost["4"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["5"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg5}</div>
            <TextQuantity>{mapManaCost["5"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["6"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg6}</div>
            <TextQuantity>{mapManaCost["6"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["7+"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svgInfinity}</div>
            <TextQuantity>{mapManaCost["7+"]}</TextQuantity>
          </FlexCol>

          <Average>Average: {averageManaCost.toFixed(2)}</Average>
        </BoxTypes_Accordion>
      </BoxCurve>
    </DeckDetailsWrapper>
  )
}

const Average = styled.div`
  flex-basis: 100%;
  margin-top: 1.2rem;
`
const TextQuantity = styled.div`
  margin: 0.1rem;
`
const BoxCurve = styled.div`
  display: flex;
  flex-direction: column;
`

const FlexCol = styled.div`
  margin-right: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

const Graph = styled.div`
  /*  height: 200px; */
  width: 30px;
  background-color: pink;
  margin: 0.8rem 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const Unity = styled.div`
  height: 30px;
  width: 30px;
  background-color: #ff974c;
`

const DeckDetailsWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: radial-gradient(#555, #111);
  padding: 1rem;
  z-index: 1000000;
  color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const BoxTypes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 2rem;
`
const BoxTypes_Accordion = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
`

const ButtonCloseDetails = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
`
const variants = {
  open: { x: 0 },
  closed: {
    x: "-100%",
    transition: {
      delay: 0,
      x: { type: "tween" },
    },
  },
}

const listVariants = {
  open: { opacity: 1, height: "auto" },
  closed: { opacity: 0, height: 0 },
}

export default DeckDetails
