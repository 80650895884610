import React from "react"

export default (
  <svg viewBox="0 0 191 227" width={24} height={24}>
    <g fill="none" fillRule="evenodd">
      <g fill="#000" fillRule="nonzero">
        <path d="M27.176 95.412l.706-.353-13.764-48.824L.824 115.882 0 120l2.353-3.412c.118-.117 10.353-14.823 24.823-21.176z" />
        <path d="M152.353 100l-13.294-83.53-18 94.589-.118.823.824.236c16.823 4.823 33.294 20 33.411 30.588 0 4.588-2.94 7.765-8.823 9.53-25.765 7.764-47.059 26.352-50.941 29.882-3.883-3.412-25.294-22.236-50.941-29.883-5.883-1.764-8.824-4.94-8.824-9.53.118-10.587 16.588-25.764 33.412-30.587l.588-.353L51.53 16.353 38.235 100c-4.117 2.47-37.53 23.882-37.53 45.647 0 21.177 25.295 28.824 25.53 28.824.47.117 53.294 19.764 68.941 52.47 15.53-32.823 68.471-52.235 68.942-52.47.235-.118 25.53-7.647 25.53-28.824.234-21.765-33.295-43.059-37.295-45.647z" />
        <path d="M188.235 116.588l2.353 3.412-13.412-70.118-.705-3.647-13.765 48.824.706.353c14.47 6.353 24.706 21.059 24.823 21.176zm-130 17.059c24.236 11.765 36.118 36.706 36.236 36.941l.823 1.647.824-1.647c.117-.235 12.117-25.176 36.235-36.94l1.176-.59L110.824 114 95.294 0l-15.53 114-22.823 19.059 1.294.588z" />
      </g>
      <path d="M95.294 172.235v69.647" />
    </g>
  </svg>
)
