import React from "react"

export default (
  <svg viewBox="0 -20 550 550" width={24} height={24}>
    <path
      d="M713.86 852.973c-13.242-13.242-14.847-23.546-7.09-45.53 6.991-19.817 16.573-30.22 46.999-51.033 15.378-10.52 42.363-30.019 59.965-43.331 30.717-23.23 46.246-32.887 138.579-86.174 67.217-38.792 157.997-74.98 157.997-62.982 0 5.727-21.602 21.173-51.86 37.08-38.85 20.426-148 94.342-180.465 122.212-25.574 21.955-59.523 58.951-95.232 103.781-32.312 40.565-48.283 46.587-68.893 25.977zm-131.413-36.767c-8.453-9.074-10.26-20.877-6.193-40.465 5.237-25.228 4.443-50.054-2.026-63.295-4.627-9.473-9.756-13.423-30.827-23.746-13.902-6.811-27.068-14.834-29.257-17.828-8.884-12.149-1.855-42.35 16.199-69.603 15.034-22.694 70.089-84.718 103.215-116.282 34.276-32.659 56.127-47.605 82.962-56.747 20.318-6.922 32.055-12.397 98.218-45.82 78.129-39.467 156.038-62.448 156.038-46.027 0 2.79-15.37 11.065-42.01 22.613-58.016 25.151-67.517 30.789-109.887 65.206-20.432 16.597-52.724 41.955-71.759 56.351-36.375 27.512-64.188 55.37-93.044 93.197-37.094 48.626-41.041 58.817-29.874 77.133 3.295 5.404 5.941 13.844 5.99 18.755.12 11.894 5.923 8.12 11.542 3.708 8.326-6.536 22.885-19.754 46.973-42.43 63.708-59.973 148.655-122.486 207.542-152.733 37.968-19.5 139.966-61.43 168.99-69.468 26.622-7.372 42.07-8.092 42.07-1.96 0 5.343-7.412 9.847-70.71 42.962-87.207 45.624-123.096 71.603-191.854 138.878-37.247 36.443-103.393 96.203-150.305 135.793-5.416 4.57-24.868 25.803-43.225 47.182-18.358 21.379-36.857 41.607-41.108 44.952-9.977 7.848-20.157 7.728-27.66-.326z"
      fill="#000"
      transform="translate(-510.31 -331.031)"
    />
  </svg>
)
