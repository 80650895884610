import React, { useRef, useState, useEffect, useLayoutEffect } from "react"
import styled from "styled-components"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { motion, AnimatePresence } from "framer-motion"
import { useToasts } from "react-toast-notifications"

import {
  add,
  remove,
  downloadTxtFile,
  getArenaExport,
  getMtgoExport,
} from "../utils/helpers"
import ButtonBinderTable from "./buttonBinderTable"
import AddRemoveMana from "../components/addRemoveMana"
import ButtonMotion from "./buttonMotion"
import DetailButton from "../components/detailButton"
import svgRedMana from "../assets/svgRedMana"
import svgGreenMana from "../assets/svgGreenMana"
import svgBluMana from "../assets/svgBluMana"
import svgBlackMana from "../assets/svgBlackMana"
import svgWhiteMana from "../assets/svgWhiteMana"
import svgArtifact from "../assets/svgArtifact"
import svgCreature from "../assets/svgCreature"
import svgEnchantment from "../assets/svgEnchantment"
import svgInstant from "../assets/svgInstant"
import svgPW from "../assets/svgPW"
import svgSorcery from "../assets/svgSorcery"
import svgLandSymbol from "../assets/svgLandSymbol"
import svgLandSymbolBasic from "../assets/svgLandSymbolBasic"
import svgarena from "../assets/svgarena"
import svgmtgo from "../assets/svgmtgo"
import svgColorlessMana from "../assets/svgColorlessMana"
import { userLocked } from "../fauna/helpers/errors"

const TableToolsComponent = ({
  setDisplayRaccoglitore,
  setDisplayTable,
  setChosenCards,
  mapMana,
  setShowDeckDetails,
  setShowEasyView,
  setShowSaveWindow,
  mapDeckCardsTypeLine,
  mapDeckCardsProducedMana,
  mapDeckCardsColorIdentity,
  totalAllLands,
  averageManaCost,
  deckCards,
  sideboardCards,
  chosenCards,
  userTier,
  setShowHelperView,
}) => {
  const { addToast } = useToasts()

  const mapSymbols = {
    W: svgWhiteMana,
    U: svgBluMana,
    B: svgBlackMana,
    R: svgRedMana,
    G: svgGreenMana,
    C: svgColorlessMana,
  }
  const mapLetterToLand = {
    W: "Plains",
    U: "Island",
    B: "Swamp",
    R: "Mountain",
    G: "Forest",
    C: "Colorless",
  }

  const manaSymbols = []
  for (const [key, value] of Object.entries(mapMana)) {
    if (mapMana[key]["quantity"] > 0) {
      manaSymbols.push(
        <AddRemoveMana
          key={key}
          svg={mapSymbols[key]}
          landName={mapLetterToLand[key]}
          quantity={mapMana[key]["quantity"]}
          setChosenCards={setChosenCards}
          unicoIds={mapMana[key]["unicoIds"]}
        />
      )
    }
  }

  const mapDetail = {
    WIdentity: svgWhiteMana,
    UIdentity: svgBluMana,
    BIdentity: svgBlackMana,
    RIdentity: svgRedMana,
    GIdentity: svgGreenMana,
    Creature: svgCreature,
    Sorcery: svgSorcery,
    Instant: svgInstant,
    Enchantment: svgEnchantment,
    Artifact: svgArtifact,
    Planeswalker: svgPW,
    W: "Plains",
    U: "Island",
    B: "Swamp",
    R: "Mountain",
    G: "Forest",
    C: "Colorless",
  }

  // TYPE
  /*   const deckCardsTypeLineArray = []
  for (const [chiave, value] of Object.entries(mapDeckCardsTypeLine)) {
    if (mapDetail[chiave]) {
      deckCardsTypeLineArray.push(
        <DetailButton
          key={chiave}
          chiave={mapDetail[chiave]}
          quantity={value}
        />
      )
    }
  }
  deckCardsTypeLineArray.sort((a, b) => (a.key > b.key ? 1 : -1)) */

  // COLOR IDENTITY
  /*   const deckCardsColorIdentityArray = []
  const colorOrder = [
    "WIdentity",
    "UIdentity",
    "BIdentity",
    "RIdentity",
    "GIdentity",
  ]
  for (const [chiave, value] of Object.entries(mapDeckCardsColorIdentity)) {
    if (mapDetail[chiave]) {
      deckCardsColorIdentityArray.push(
        <DetailButton
          key={chiave}
          chiave={mapDetail[chiave]}
          quantity={value}
        />
      )
    }
  }
  deckCardsColorIdentityArray.sort((a, b) =>
    colorOrder.indexOf(a.key) > colorOrder.indexOf(b.key) ? 1 : -1
  ) */

  // PRODUCED MANA
  const deckCardsProducedManaArray = []
  const landOrder = ["W", "U", "B", "R", "G", "C"]
  for (const [chiave, value] of Object.entries(mapDeckCardsProducedMana)) {
    if (mapDetail[chiave]) {
      deckCardsProducedManaArray.push(
        <DetailButton
          key={chiave}
          chiave={mapDetail[chiave]}
          quantity={value}
        />
      )
    }
  }
  deckCardsProducedManaArray
    .sort((a, b) =>
      landOrder.indexOf(a.key) > landOrder.indexOf(b.key) ? 1 : -1
    )
    .push(
      <DetailButton
        key={"TotalLand"}
        chiave={"Lands"}
        quantity={totalAllLands}
      />
    )

  // PUT ALL TOGETHER
  const detailButtons = [
    /*     ...deckCardsTypeLineArray, */
    /* ...deckCardsColorIdentityArray, */
    ...deckCardsProducedManaArray,
  ]

  const [notifications, setNotifications] = useState([])

  let toPrint = getArenaExport(chosenCards)

  return (
    <WrapperTableTools>
      <ButtonBinderTable
        goTo="Binder"
        setDisplayRaccoglitore={setDisplayRaccoglitore}
        setDisplayTable={setDisplayTable}
      />
      <ButtonMotion
        handleClick={() => setShowDeckDetails(prev => !prev)}
        text="Details"
        propPadTop={"0rem"}
        propFontSize={"2rem"}
      />
      <ButtonMotion
        handleClick={() => setShowEasyView(prev => !prev)}
        text="Visual"
        propPadTop={"0rem"}
        propFontSize={"2rem"}
      />
      {userTier ? (
        <ButtonMotion
          handleClick={() => setShowSaveWindow(prev => !prev)}
          text="Save"
          propPadTop={"0rem"}
          propFontSize={"2rem"}
        />
      ) : (
        <ButtonMotion
          handleClick={() => {
            addToast("Subscribers can export and save decks", {
              appearance: "error",
            })
          }}
          text="Save"
          propPadTop={"0rem"}
          propFontSize={"2rem"}
        />
      )}
      {manaSymbols}
      {detailButtons}
      <DetailButton
        chiave={"Deck"}
        quantity={chosenCards.filter(card => card.place === "deck").length}
      />
      <DetailButton chiave={"Sideboard"} quantity={sideboardCards.length} />
      {userTier ? (
        <CopyToClipboard text={toPrint}>
          <ButtonExport
            onClick={() => {
              setNotifications(add(notifications))
              setTimeout(() => {
                setNotifications(
                  remove(notifications, notifications[notifications.length])
                )
              }, 1000)
            }}
          >
            {svgarena}
            <WrapperNotifications>
              <AnimatePresence initial={false}>
                {notifications.map(idNotification => (
                  <Notification
                    onClick={e => e.stopPropagation()}
                    key={idNotification}
                    positionTransition
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{
                      opacity: 0,
                      scale: 0.5,
                      transition: { duration: 0.2 },
                    }}
                  >
                    {/*       <CloseButton
                    close={e => {
                      e.stopPropagation()
                      setNotifications(remove(notifications, idNotification))
                    }}
                  /> */}
                    <NotificationText
                      onClick={() =>
                        setNotifications(remove(notifications, idNotification))
                      }
                    >
                      Copied to clipboard
                      <ButtonDelete>X</ButtonDelete>
                    </NotificationText>
                  </Notification>
                ))}
              </AnimatePresence>
            </WrapperNotifications>
          </ButtonExport>
        </CopyToClipboard>
      ) : (
        <ButtonExport
          onClick={() => {
            addToast("Subscribers can export and save decks", {
              appearance: "error",
            })
          }}
        >
          {svgarena}
        </ButtonExport>
      )}
      {userTier ? (
        <ButtonExport
          onClick={() => {
            let deckList = getMtgoExport(chosenCards)
            downloadTxtFile(deckList)
          }}
        >
          {svgmtgo}
        </ButtonExport>
      ) : (
        <ButtonExport
          onClick={() => {
            addToast("Subscribers can export and save decks", {
              appearance: "error",
            })
          }}
        >
          {svgmtgo}
        </ButtonExport>
      )}

      <HelperToggle onClick={() => setShowHelperView(true)}>i</HelperToggle>
    </WrapperTableTools>
  )
}

const ButtonDelete = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: #cccccc;
  margin-left: 0.6rem;
  margin-top: 0.1rem;
  color: #000000;

  /* padding-top: 0.3rem; */
`

const WrapperNotifications = styled.ul`
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  top: 0;
  z-index: 300;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  list-style: none;
`

const Notification = styled(motion.div)`
  background-image: linear-gradient(
    -180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  border-radius: 10px;
  margin: 0.5rem;
`
const NotificationText = styled.p`
  color: #cccccc;
  padding: 1rem;
`

const WrapperTableTools = styled.div`
  background: radial-gradient(#555, #111);
  /*   padding: 0.5rem;
  padding-top: 0.8rem; */
  padding: 0.5rem 2rem;
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
`

const ButtonExport = styled.div`
  width: 24px;
  height: 24px;

  /*  outline: none; */
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 50%;
  color: #cccccc;

  margin-right: 0.5rem;

  position: relative;

  :hover {
    svg {
      filter: drop-shadow(0px 0px 4px #ff974c);
    }
  }
`

const HelperToggle = styled.button`
  position: absolute;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  background-color: #cccccc;
  width: 26px;
  height: 26px;
  font-size: 22px;
  top: 10px;
  right: 5px;
  color: #000000;
`

export default TableToolsComponent
