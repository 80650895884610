import React from "react"

export default (
  <svg viewBox="0 -50 600 500" width={24} height={24}>
    <path
      d="M227.474 561.758c-20.228-3.353-147.566-32.504-150.854-34.536-4.459-2.756.634-6.374 11.943-8.484 9.973-1.861 82.172-20.27 112.536-28.692 13.228-3.67 19.033-12.073 14.364-20.797-2.702-5.05-66.26-60.979-123.866-108.998-17.448-14.545-20.996-20.17-12.72-20.17 2.496 0 41.615 14.965 86.932 33.256 45.318 18.29 84.808 32.799 87.756 32.24 12.293-2.33 8.134-17.724-30.484-112.857-36.694-90.393-42.613-108.764-33.376-103.595 2.373 1.328 33.449 41.239 69.058 88.69 65.018 86.644 70.754 92.552 79.55 81.955 2.102-2.534 9.057-56.116 16.807-129.486 13.496-127.762 14.72-136.318 19.504-136.318 4.35 0 5.273 6.636 19.169 137.69 13.71 129.308 14.976 135.293 27.747 131.24 3.142-.997 35.222-40.98 71.29-88.85 36.068-47.87 66.739-87.037 68.157-87.037 5.885 0 .857 15.018-33.705 100.67-19.957 49.455-37.47 93.805-38.919 98.556-3.152 10.338 1.037 19.876 8.73 19.876 2.942 0 40.375-14.356 83.183-31.902 77.073-31.59 94.342-37.447 94.342-31.998 0 1.547-20.513 20.271-45.585 41.61-99.322 84.533-101.269 86.51-95.61 97.084 3.636 6.793 6.017 7.574 78.166 25.648 66.485 16.656 64.761 16.108 61.28 19.457-4.078 3.922-104.244 26.85-163.47 37.417-14.612 2.607-16.359-1.262-16.44-36.405-.061-26.69-1.29-35.416-7.007-49.732-9.698-24.284-34.126-51.398-59.91-62.872-34.777-15.476-73.572-14.516-103.075-.024-47.34 23.25-71.404 66.165-67.373 119.917 1.677 22.37 1.204 24.748-3.966 27.763-8.052 4.693-17.165.843-24.154-.316z"
      fill="#000"
      transform="translate(-74.618 -93.966)"
    />
  </svg>
)
