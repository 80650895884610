import React from "react"

export default (
  <svg viewBox="0 -90 600 950" width={16} height={24}>
    <path
      d="M277.302 767.02c-14.984-4.606-20.642-12.005-17.72-23.175 2.542-9.724 25.174-17.243 60.091-19.964 17.637-1.375 37.915-4.627 45.062-7.226 37.254-13.548 73.665-41.337 82.795-63.187 2.407-5.762 7-43.799 10.86-89.96 6.169-73.742 6.311-80.394 1.835-85.34-2.67-2.951-24.655-15.272-48.855-27.38-38.71-19.367-46.786-24.758-67.157-44.828-21.016-20.705-24.479-25.867-37.454-55.833-15.831-36.562-43.036-116.82-51.307-151.364-4.961-20.72-6.656-23.574-24.063-40.522-14.058-13.687-19.246-21.038-20.812-29.489-3.754-20.263.635-32.423 17.273-47.854l15.187-14.086-2.149-61.024c-2.087-59.28-1.967-61.255 4.21-69.108 3.497-4.446 11.363-9.473 17.478-11.172 15.257-4.237 478.25-4.237 493.505 0 6.116 1.699 14.012 6.756 17.547 11.24 6.245 7.92 6.362 9.719 4.15 63.682-1.251 30.542-2.125 58.087-1.942 61.21.183 3.124 7.045 11.904 15.246 19.512 16.349 15.164 20.733 27.442 16.998 47.6-1.567 8.46-6.763 15.812-20.918 29.592-17.484 17.023-19.216 19.925-24.182 40.523-8.568 35.536-35.543 115.433-51.07 151.26-12.981 29.955-16.457 35.135-37.465 55.833-20.372 20.07-28.448 25.461-67.157 44.828-24.2 12.108-46.185 24.429-48.855 27.38-4.477 4.946-4.333 11.63 1.841 85.744 3.76 45.14 8.484 84.691 10.773 90.213 8.722 21.037 45.997 49.152 82.875 62.511 7.148 2.59 27.636 5.934 45.53 7.433 35.26 2.953 57.121 10.204 59.624 19.776 3.012 11.52-2.671 18.503-19.005 23.354-22.894 6.799-440.59 6.637-462.769-.18z"
      fill="#000"
      transform="translate(-209.326 77.67)"
    />
  </svg>
)
