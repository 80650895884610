import React, { useState, useEffect, useContext, useMemo } from "react"
import { graphql, navigate, Link } from "gatsby"
import { useQueryCache, useQuery, useMutation } from "react-query"
import styled from "styled-components"
import { useToasts } from "react-toast-notifications"

import { faunaQueries } from "./../fauna/query-manager"
import { safeVerifyError } from "./../fauna/helpers/errors"
import {
  logoutBecauseUnauthorized,
  mapDiscordWebhooks,
} from "../fauna/helpers/util"
import SessionContext from "./../context/session"
import { useWindowSize } from "./../utils/hooks"
import Profile from "./../components/profile"
import PrivateRoute from "./../components/private-route"
import DeckBuilder from "./../components/deckBuilder"
import DecksBar from "./../components/decksBar"
import { array } from "prop-types"
import Spinner from "../components/Spinner"
import SEO from "./../components/seo"

function calculateCardpool(user, arrayOfCards) {
  if (user?.tier === "silver" || user?.tier === "rare") {
    return arrayOfCards.filter(card => {
      if (
        // card.promo_types?.includes("boosterfun") || // directly in query
        card.promo_types?.includes("promopack") || // directly in query
        // card.layout === "token" ||
        card.layout === "double_faced_token" ||
        card.layout === "emblem" ||
        card.layout === "art_series" ||
        card.layout === "double_sided" ||
        // directly in query card.set_type === "funny" ||
        card.set_type === "memorabilia"
      ) {
        return false
      }
      return true
    })
  } else {
    return arrayOfCards.filter(
      card =>
        card.set === "eld" ||
        card.type_line === "Basic Land — Swamp" ||
        card.type_line === "Basic Land — Mountain" ||
        card.type_line === "Basic Land — Island" ||
        card.type_line === "Basic Land — Forest" ||
        card.type_line === "Basic Land — Plains"
    )
  }
}

function calculateCardType(arrayOfCards) {
  return arrayOfCards.filter(card => {
    if (
      // card.promo_types?.includes("boosterfun") || // directly in query
      card.promo_types?.includes("promopack") || // directly in query
      // card.layout === "token" ||
      card.layout === "double_faced_token" ||
      card.layout === "emblem" ||
      card.layout === "art_series" ||
      card.layout === "double_sided" ||
      card.set_type === "funny" || // directly in query
      // card.set_type === "token" ||
      card.set_type === "memorabilia"
    ) {
      return false
    }
    return true
  })
}

const MtgBuilderPage = props => {
  /*   console.log("🚀 ~ file: mtg-deck-builder.js ~ line 61 ~ props", props) */
  const windowSize = useWindowSize()
  const [topBarWidth, setTopBarWidth] = useState(windowSize.width)
  const { addToast } = useToasts()
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  /* console.log("🚀 ~ user", user) */
  const queryCache = useQueryCache()
  /*   const cardPool = useMemo(
    () => calculateCardpool(user, props.data.allCard.nodes),
    [user]
  ) */
  const cardPool = useMemo(
    () => calculateCardType(props.data.allCard.nodes),
    []
  )

  const [saveDeck, saveDeckInfo] = useSaveDeck(
    queryCache,
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )

  const queryInfo = useDeckByRef(
    queryCache,
    props.params["*"],
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )

  useEffect(() => {
    if (!user && faunaQueries.getUser()) {
      sessionContext.dispatch({ type: "login", data: faunaQueries.getUser() })
    }
    return function cleanup() {
      /*       console.log("MtgBuilderPage/unmounting") */
    }
  }, [props.params["*"], user, sessionContext])

  return (
    <PageContainer topBarWidth={props.topBarWidth}>
      <SEO
        slug="/mtg-deck-builder/"
        title="MTG Deck Builder as never before"
        description="MTG Deck Builder with a beautiful UI. Virtual table to drag cards from Alpha to Kaldheim, buttons to filter quickly, shortcuts and visual spoilers."
      />
      <Profile />
      {queryInfo.isLoading ? (
        <Spinner />
      ) : queryInfo.isSuccess && queryInfo.data?.myError ? (
        <h1>ERROR MDB001</h1>
      ) : queryInfo.isSuccess && !queryInfo.data ? (
        <h1>ERROR MDB002</h1>
      ) : queryInfo.isSuccess && queryInfo.data ? (
        <>
          <DeckBuilder
            setTopBarWidth={setTopBarWidth}
            cardPool={cardPool}
            displayTableInitial="block"
            displayRaccoglitoreInitial="none"
            saveDeck={saveDeck}
            saveDeckInfo={saveDeckInfo}
            loadedDeck={queryInfo.data}
            numRefFromParams={props.params["*"]}
            userTier={user?.tier}
          />
        </>
      ) : queryInfo.isIdle ? (
        <DeckBuilder
          setTopBarWidth={setTopBarWidth}
          cardPool={cardPool}
          displayTableInitial="none"
          displayRaccoglitoreInitial="block"
          saveDeck={saveDeck}
          saveDeckInfo={saveDeckInfo}
          loadedDeck={null}
          numRefFromParams={null}
          userTier={user?.tier}
        />
      ) : queryInfo.isError ? (
        <h1>ERROR MDB004</h1>
      ) : (
        <h1>ERROR MDB005</h1>
      )}
    </PageContainer>
  )
}
export default MtgBuilderPage

const PageContainer = styled.div`
  background-color: transparent;
  width: ${props => props.topBarWidth};

  /*   border: 5px solid red;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;

  > div {
    max-width: 900px;
  }

  > nav {
    max-width: 900px;
  } */
`

// UNCOMMENT HERE 2/2
// prettier-ignore
export const pageQuery = graphql`
  query getAllCard {
    allCard(
      filter: {
        layout: { ne: "token" }
        set_type: { ne: "funny" }
      }
      sort: { fields: cmc, order: ASC }
    ) {
      nodes {
        arena_id
        artist
        artist_ids
        card_faces {
          colors
          image_uris {
            border_crop
          }
          power
          toughness
        }
        cmc
        colors
        color_identity
        edhrec_rank
        id
        image_uris {
          border_crop
        }
        keywords
        layout
        legalities {
          brawl
          duel
          commander
          future
          historic
          legacy
          modern
          oldschool
          pauper
          penny
          pioneer
          standard
          vintage
        }
        mana_cost
        mtgo_id
        multiverse_ids
        name
        oracle_text
        power
        produced_mana
        promo_types
        rarity
        reserved
        set
        set_name
        set_type
        tcgplayer_id
        toughness
        type_line
      }
    }
  }
`

function useSaveDeck(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useMutation(
    values =>
      fetchSaveDeck(
        queryCache,
        values, // deckObjUDF
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      onMutate: async values => {
        if (values["numRefFromParams"]) {
          await queryCache.cancelQueries([
            "QM_get_deck_where_ref",
            values["numRefFromParams"],
          ])
        }
      },
      onError: (error, values, rollback) => {
        /*         console.log("useSaveDeck/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
      },
      onSuccess: (data, values) => {
        /*         console.log("useSaveDeck/onSuccess: ", data) */
        addToast("Deck saved", { appearance: "success" })
      },
      onSettled: (data, error, values, rollback) => {
        queryCache.invalidateQueries(
          `QM_get_decks_where_alias-${sessionContext.state.user.alias}`
        )
        queryCache.invalidateQueries("QM_get_decks_mine")
        if (data) {
          queryCache.invalidateQueries([
            "QM_get_deck_where_ref",
            data.ref.value.id,
          ])
          navigate(`/dashboard/my-decks/`)
          if (values.isPublic && mapDiscordWebhooks[values.format]) {
            faunaQueries.discordPostDeck(
              mapDiscordWebhooks[values.format],
              data.ref.value.id,
              values.name,
              values.color
            )
          }
        }
      },
    }
  )
}

async function fetchSaveDeck(
  queryCache,
  deckObjUDF,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  /*   console.log("fetchSaveDeck ~ deckObjUDF: ", deckObjUDF) */
  // Update() || Create(),
  /// Abort("Only Rare members can have private decks!")
  /// Abort("You've already reached the maximum number of decks you can create!")
  /// Abort("Subscribe now to unlock advanced features!")
  try {
    const response = await faunaQueries.QM_save_deck(deckObjUDF)
    /*     console.log("fetchSaveDeck -> response", response) */
    return response
  } catch (err) {
    /*     console.log("fetchFollowUser -> err", err) */
    throw err
    /* logoutBecauseUnauthorized(sessionContext, addToast, err) */
  }
}

function useDeckByRef(
  queryCache,
  deckRefUDF,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useQuery(
    ["QM_get_deck_where_ref", deckRefUDF],
    (prefix, key, after = null) =>
      fetch_get_deck_where_ref(
        queryCache,
        deckRefUDF,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      staleTime: 0,
      enabled: deckRefUDF?.length > 0,
      initialData: () => queryCache.getQueryData(["initial_deck", deckRefUDF]),
      initialStale: true,

      onError: (error, values, rollback) => {
        /*         console.log("useDeckByRef/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
      },
    }
  )
}

async function fetch_get_deck_where_ref(
  queryCache,
  deckRefUDF,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  // deck {}
  /// Abort("Would you like to create a deck? Subscribe!")
  /// Abort("This deck doesn't exist")
  /// Abort("This deck is private!")
  try {
    const response = await faunaQueries.QM_get_deck_where_ref_for_builder(
      deckRefUDF
    )
    /*     console.log("QM_get_deck_where_ref_for_builder/response: ", response) */
    return response
  } catch (err) {
    /*     console.log("QM_get_deck_where_ref_for_builder/err: ", err) */
    throw err
  }
}
