import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react"
import styled from "styled-components"
import { useWindowSize } from "../utils/hooks"
import {
  ShortcutDetails,
  ShortcutEasyView,
  ShortcutBinder,
} from "../utils/shortcuts"

import DeckDetails from "../components/deckDetails"
import EasyView from "../components/easyView"
import TableTools from "../components/tableTools"
import HelperView from "./HelperView"
import Card from "../components/card"
import SaveWindow from "./SaveWindow"
import ButtonPlain from "./ButtonPlain"

/* function scatterHorizontally(chosenCardsInput, setChosenCards, windowSize) {
  const hasCardsToScatter = chosenCardsInput.some(
    card => !card.hasBeenDragged && !card.type_line.includes("Basic")
  )
  if (!hasCardsToScatter) {
    return
  }
  const chosenCards = [...chosenCardsInput]
  chosenCards
    .sort((a, b) =>
      a.multiverse_ids.length > 0 && b.multiverse_ids.length > 0
        ? a.multiverse_ids[0] - b.multiverse_ids[0]
        : a.cmc - b.cmc
    )
    .sort((a, b) => a.cmc - b.cmc)

  const rows = {
    1: 0,
    2: 257,
    3: 514,
  }
  const maxWidth = windowSize.width

  let curRow = 1
  let distanceFromLeft = 3

  for (let i = 0; i < chosenCards.length; i++) {
    let curCard = chosenCards[i]
    if (curCard.type_line.includes("Basic")) {
      continue
    } else if (!curCard.hasBeenDragged) {
      if (distanceFromLeft + 184 > maxWidth) {
        curRow++
        distanceFromLeft = 0
        if (curRow > 3) {
          break
        }
      }
      curCard.position = {
        x: distanceFromLeft,
        y: rows[curRow],
      }
      curCard["hasBeenDragged"] = true
      distanceFromLeft = distanceFromLeft + 50
    } else {
      continue
    }
  }
  setChosenCards(chosenCards)
} */
function scatterHorizontally(chosenCardsInput, setChosenCards, windowSize) {
  const hasCardsToScatter = chosenCardsInput.some(
    card => !card.hasBeenDragged && !card.type_line.includes("Basic")
  )
  if (!hasCardsToScatter) {
    return
  }
  const chosenCards = [...chosenCardsInput]
  chosenCards
    .sort((a, b) =>
      a.multiverse_ids.length > 0 && b.multiverse_ids.length > 0
        ? a.multiverse_ids[0] - b.multiverse_ids[0]
        : a.cmc - b.cmc
    )
    .sort((a, b) => a.cmc - b.cmc)
  const maxWidth = windowSize.width

  const rows = {
    1: 1120,
    /*     2: 257,
    3: 514, */
  }
  const increaseDistance = maxWidth < 760 ? 13 : 40
  let curRow = 1
  let distanceFromLeft = 5

  for (let i = 0; i < chosenCards.length; i++) {
    let curCard = chosenCards[i]
    if (curCard.type_line.includes("Basic")) {
      continue
    } else if (!curCard.hasBeenDragged) {
      if (distanceFromLeft + 184 > maxWidth) {
        curRow++
        distanceFromLeft = 0
        /*         if (curRow > 3) { */
        if (curRow > 1) {
          break
        }
      }
      curCard.position = {
        x: distanceFromLeft,
        y: rows[curRow],
      }
      curCard["hasBeenDragged"] = true

      distanceFromLeft = distanceFromLeft + increaseDistance
    } else {
      continue
    }
  }
  setChosenCards(chosenCards)
}

function scatterVertically(chosenCardsInput, setChosenCards, windowSize) {
  const hasCardsToScatter = chosenCardsInput.some(
    card => !card.hasBeenDragged && !card.type_line.includes("Basic")
  )
  if (!hasCardsToScatter) {
    return
  }
  const chosenCards = [...chosenCardsInput]
  chosenCards
    .sort((a, b) =>
      a.multiverse_ids.length > 0 && b.multiverse_ids.length > 0
        ? a.multiverse_ids[0] - b.multiverse_ids[0]
        : a.cmc - b.cmc
    )
    .sort((a, b) => a.cmc - b.cmc)
  const maxWidth = windowSize.width

  let curCol = 1
  let distanceFromLeft = 0
  let distanceFromTop = 5

  for (let i = 0; i < chosenCards.length; i++) {
    let curCard = chosenCards[i]
    if (curCard.type_line.includes("Basic")) {
      continue
    } else if (!curCard.hasBeenDragged) {
      curCard["hasBeenDragged"] = true
      curCard.position = {
        x: curCol * 184 - 184,
        y: distanceFromTop,
      }
      distanceFromTop = distanceFromTop + 25

      if (distanceFromTop > 505) {
        distanceFromLeft = distanceFromLeft + 184
        if (distanceFromLeft + 184 > maxWidth) {
          break
        } else {
          curCol = curCol + 1
          distanceFromTop = 0
        }
      }
    } else {
      continue
    }
  }
  setChosenCards(chosenCards)
}

function arrangeVertically(chosenCardsInput, setChosenCards, windowSize) {
  const hasCardsToArrange = chosenCardsInput.some(
    card =>
      card.hasBeenDragged &&
      !card.type_line.includes("Basic") &&
      card.place === "deck"
  )
  if (!hasCardsToArrange) {
    return
  }
  const chosenCards = [...chosenCardsInput]
  chosenCards
    .sort((a, b) =>
      a.multiverse_ids.length > 0 && b.multiverse_ids.length > 0
        ? a.multiverse_ids[0] - b.multiverse_ids[0]
        : a.cmc - b.cmc
    )
    .sort((a, b) => a.cmc - b.cmc)
  const maxWidth = windowSize.width

  let curCol = 1
  let distanceFromLeft = 0
  let distanceFromTop = 5

  for (let i = 0; i < chosenCards.length; i++) {
    let curCard = chosenCards[i]
    if (curCard.type_line.includes("Basic")) {
      continue
    } else if (curCard.hasBeenDragged && curCard.place === "deck") {
      curCard.position = {
        x: curCol * 184 - 184,
        y: distanceFromTop,
      }
      distanceFromTop = distanceFromTop + 25

      if (distanceFromTop > 505) {
        distanceFromLeft = distanceFromLeft + 184
        if (distanceFromLeft + 184 > maxWidth) {
          break
        } else {
          curCol = curCol + 1
          distanceFromTop = 0
        }
      }
    } else {
      continue
    }
  }
  setChosenCards(chosenCards)
}

function arrangeHorizontally(chosenCardsInput, setChosenCards, windowSize) {
  const hasCardsToArrange = chosenCardsInput.some(
    card => card.hasBeenDragged && !card.type_line.includes("Basic")
  )
  if (!hasCardsToArrange) {
    return
  }
  const chosenCards = [...chosenCardsInput]
  chosenCards
    .sort((a, b) =>
      a.multiverse_ids.length > 0 && b.multiverse_ids.length > 0
        ? a.multiverse_ids[0] - b.multiverse_ids[0]
        : a.cmc - b.cmc
    )
    .sort((a, b) => a.cmc - b.cmc)
  const maxWidth = windowSize.width

  const rows = {
    1: 1125,
    /*     2: 257,
    3: 514, */
  }
  const increaseDistance = maxWidth < 760 ? 13 : 40
  let curRow = 1
  let distanceFromLeft = 5

  for (let i = 0; i < chosenCards.length; i++) {
    let curCard = chosenCards[i]
    if (curCard.type_line.includes("Basic")) {
      continue
    } else if (curCard.hasBeenDragged && curCard.place === "sideboard") {
      if (distanceFromLeft + 184 > maxWidth) {
        curRow++
        distanceFromLeft = 0
        /*         if (curRow > 3) { */
        if (curRow > 1) {
          break
        }
      }
      curCard.position = {
        x: distanceFromLeft,
        y: rows[curRow],
      }

      distanceFromLeft = distanceFromLeft + increaseDistance
    } else {
      continue
    }
  }
  setChosenCards(chosenCards)
}

const TableComponent = ({
  displayTable,
  setDisplayTable,
  setTopBarWidth,
  displayRaccoglitore,
  setDisplayRaccoglitore,
  chosenCards,
  setChosenCards,
  saveDeck,
  saveDeckInfo,
  numRefFromParams,
  userTier,
  loadedDeck,
}) => {
  /* console.log("Table is Rendering")
  console.log("🚀 ~ table.js ~ chosenCards", chosenCards) */
  const [windowSize, setWindowSize] = useWindowSize()
  const [tableHeight, setTableHeight] = useState(1400)
  const [showDeckDetails, setShowDeckDetails] = useState(false)
  const [showEasyView, setShowEasyView] = useState(false)
  const [showSaveWindow, setShowSaveWindow] = useState(false)
  const [showHelperView, setShowHelperView] = useState(false)

  const ref = useRef(null)

  useLayoutEffect(() => {
    setTopBarWidth(ref.current.clientWidth)
  })

  const deckCards = []
  const sideboardCards = []

  // color_identity: ["G", "U"],
  const mapMana = {
    W: {
      quantity: 0,
      unicoIds: [],
    },
    U: {
      quantity: 0,
      unicoIds: [],
    },
    B: {
      quantity: 0,
      unicoIds: [],
    },
    R: {
      quantity: 0,
      unicoIds: [],
    },
    G: {
      quantity: 0,
      unicoIds: [],
    },
    C: {
      quantity: 0,
      unicoIds: [],
    },
  }
  let totalBasicLand = 0

  let cardsNotDraggedCounter = 1
  for (let i = 0; i < chosenCards.length; i++) {
    let curCard = chosenCards[i]
    if (curCard.type_line.includes("Basic")) {
      deckCards.push(curCard)
      curCard.place = "deck"
      totalBasicLand = totalBasicLand + 1
      mapMana[curCard.produced_mana[0]]["quantity"] =
        mapMana[curCard.produced_mana[0]]["quantity"] + 1
      mapMana[curCard.produced_mana[0]]["unicoIds"].push(curCard.unico)
      continue
    }
    if (!curCard.hasBeenDragged) {
      curCard.position = {
        x: windowSize.width - 200,
        y: cardsNotDraggedCounter++ * 25,
      }
      /*       curCard.temporary = true */
    }
    if (
      curCard.hasBeenDragged &&
      curCard.position.y <= tableHeight * 0.55 - 125
    ) {
      // CANCEL PROPERTY, measure based of windowWidth
      deckCards.push(curCard)
      curCard.place = "deck"
    } else if (
      curCard.hasBeenDragged &&
      curCard.position.y > tableHeight * 0.55 - 125 &&
      curCard.position.y < tableHeight * 0.8 - 125
    ) {
      curCard.place = "temporary"
    } else if (
      curCard.hasBeenDragged &&
      curCard.position.y >= tableHeight * 0.8 - 125
    ) {
      sideboardCards.push(curCard)
      curCard.place = "sideboard"
    }
  }

  const regExpr = /\b[^\d\W]+\b/g

  let totalManaCost = 0
  let totalNonBasicLand = 0
  const mapManaCost = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    "7+": 0,
  }
  const mapDeckCardsTypeLine = {}
  const mapDeckCardsProducedMana = {}
  const mapDeckCardsColorIdentity = {}
  const mapDeckCardsColorCost = { W: 0, U: 0, B: 0, R: 0, G: 0 }

  let nOfCardsBothLandAndNonland = 0

  for (let i = 0; i < deckCards.length; i++) {
    let curCard = deckCards[i]
    const allTypes = curCard.type_line?.match(regExpr)
    const typesArr = [...new Set(allTypes)]
    if (curCard.colors) {
      for (let i = 0; i < curCard.colors.length; i++) {
        let curLetter = curCard.colors[i]
        mapDeckCardsColorCost[curLetter] = mapDeckCardsColorCost[curLetter] + 1
      }
    } else if (curCard.card_faces?.length > 0 && curCard.card_faces[0].colors) {
      for (let i = 0; i < curCard.card_faces[0].colors.length; i++) {
        let curLetter = curCard.card_faces[0].colors[i]
        mapDeckCardsColorCost[curLetter] = mapDeckCardsColorCost[curLetter] + 1
      }
    }

    typesArr.forEach(typeString => {
      if (!mapDeckCardsTypeLine[typeString])
        mapDeckCardsTypeLine[typeString] = 0
      mapDeckCardsTypeLine[typeString] = mapDeckCardsTypeLine[typeString] + 1
    })
    totalManaCost = totalManaCost + curCard.cmc
    if (typesArr.includes("Land") && !typesArr.includes("Basic")) {
      totalNonBasicLand = totalNonBasicLand + 1
      if (curCard.cmc > 0) {
        nOfCardsBothLandAndNonland = nOfCardsBothLandAndNonland + 1
      }
      // IF LAND, INCREMENT MANA PRODUCED
      if (curCard.produced_mana) {
        for (let j = 0; j < curCard.produced_mana.length; j++) {
          //     produced_mana: ["B", "G", "R", "U", "W"],
          let manaLetter = curCard.produced_mana[j]
          if (!mapDeckCardsProducedMana[manaLetter])
            mapDeckCardsProducedMana[manaLetter] = 0
          mapDeckCardsProducedMana[manaLetter] =
            mapDeckCardsProducedMana[manaLetter] + 1
        }
      }
    } else if (!typesArr.includes("Basic")) {
      // IF !LAND, INCREMENT COLOR IDENTITY
      if (curCard.cmc < 7) {
        mapManaCost[curCard.cmc] = mapManaCost[curCard.cmc] + 1
      } else {
        mapManaCost["7+"] = mapManaCost["7+"] + 1
      }
      for (let z = 0; z < curCard.color_identity?.length; z++) {
        let identityLetter = curCard.color_identity[z]
        identityLetter = identityLetter + "Identity"
        if (!mapDeckCardsColorIdentity[identityLetter])
          mapDeckCardsColorIdentity[identityLetter] = 0
        mapDeckCardsColorIdentity[identityLetter] =
          mapDeckCardsColorIdentity[identityLetter] + 1
      }
    }
  }

  let averageManaCost =
    totalManaCost /
    (deckCards.length -
      totalNonBasicLand -
      totalBasicLand +
      nOfCardsBothLandAndNonland)

  // ADD THE BASIC LAND COLORS
  for (const [key, value] of Object.entries(mapMana)) {
    if (mapMana[key]["quantity"] > 0) {
      if (!mapDeckCardsProducedMana[key]) mapDeckCardsProducedMana[key] = 0
      mapDeckCardsProducedMana[key] =
        mapDeckCardsProducedMana[key] + mapMana[key]["quantity"]
    }
  }

  const hasCardsInHand = useMemo(
    () =>
      chosenCards.some(
        card => !card.hasBeenDragged && !card.type_line.includes("Basic")
      ),
    [chosenCards]
  )

  const hasCardsInDeck = useMemo(
    () =>
      chosenCards.some(
        card =>
          card.hasBeenDragged &&
          !card.type_line.includes("Basic") &&
          card.place === "deck"
      ),
    [chosenCards]
  )

  const hasCardsInSideboard = useMemo(
    () =>
      chosenCards.some(
        card =>
          card.hasBeenDragged &&
          !card.type_line.includes("Basic") &&
          card.place === "sideboard"
      ),
    [chosenCards]
  )

  return (
    <TableWrapper
      tableHeight={tableHeight}
      windowSize={windowSize}
      displayTable={displayTable}
    >
      {displayTable !== "none" && (
        <>
          <ShortcutDetails setShowDeckDetails={setShowDeckDetails} />
          <ShortcutEasyView setShowEasyView={setShowEasyView} />
          <ShortcutBinder
            setDisplayRaccoglitore={setDisplayRaccoglitore}
            setDisplayTable={setDisplayTable}
          />
        </>
      )}
      <DeckDetails
        showDeckDetails={showDeckDetails}
        setShowDeckDetails={setShowDeckDetails}
        mapDeckCardsTypeLine={mapDeckCardsTypeLine}
        mapDeckCardsProducedMana={mapDeckCardsProducedMana}
        mapDeckCardsColorIdentity={mapDeckCardsColorIdentity}
        mapDeckCardsColorCost={mapDeckCardsColorCost}
        averageManaCost={averageManaCost}
        mapManaCost={mapManaCost}
        totalAllLands={totalBasicLand + totalNonBasicLand}
      />
      <EasyView
        showEasyView={showEasyView}
        setShowEasyView={setShowEasyView}
        chosenCards={chosenCards}
        sideboardCards={sideboardCards}
      />
      <SaveWindow
        showSaveWindow={showSaveWindow}
        setShowSaveWindow={setShowSaveWindow}
        chosenCards={chosenCards}
        /*        sideboardCards={sideboardCards} */
        numRefFromParams={numRefFromParams}
        saveDeck={saveDeck}
        saveDeckInfo={saveDeckInfo}
        userTier={userTier}
        loadedDeck={loadedDeck}
      />
      <HelperView
        showHelperView={showHelperView}
        setShowHelperView={setShowHelperView}
        helperPage="table"
      />
      <TableTools
        setDisplayRaccoglitore={setDisplayRaccoglitore}
        setDisplayTable={setDisplayTable}
        setChosenCards={setChosenCards}
        mapMana={mapMana}
        setShowDeckDetails={setShowDeckDetails}
        setShowEasyView={setShowEasyView}
        setShowSaveWindow={setShowSaveWindow}
        mapDeckCardsTypeLine={mapDeckCardsTypeLine}
        mapDeckCardsProducedMana={mapDeckCardsProducedMana}
        mapDeckCardsColorIdentity={mapDeckCardsColorIdentity}
        mapDeckCardsColorCost={mapDeckCardsColorCost}
        averageManaCost={averageManaCost}
        totalAllLands={totalBasicLand + totalNonBasicLand}
        deckCards={deckCards}
        sideboardCards={sideboardCards}
        chosenCards={chosenCards}
        userTier={userTier}
        setShowHelperView={setShowHelperView}
      />
      <TableMain ref={ref}>
        {hasCardsInDeck ? (
          <ScatterButtons left={true} isForDecks={true}>
            <ButtonPlain
              key={"arrange deck"}
              hasSimpleLogic={true}
              handleSimpleOnClick={() => {
                arrangeVertically(chosenCards, setChosenCards, windowSize)
              }}
              buttonText={"arrange deck"}
              tooltip={undefined}
              opacity={1}
              padding="0rem 1rem"
              /*  minWidth="50%" */
              /* paddingTop="0.2rem" */
              lineHeight="1.6"
              letterSpacing="0.25rem"
              fontSize="1.6rem"
              borderRadius="0"
              margin="0"
              height="2.5rem"
            />
          </ScatterButtons>
        ) : null}
        {hasCardsInSideboard ? (
          <ScatterButtons left={true}>
            <ButtonPlain
              key={"arrange sideB"}
              hasSimpleLogic={true}
              handleSimpleOnClick={() => {
                arrangeHorizontally(chosenCards, setChosenCards, windowSize)
              }}
              buttonText={"arrange sideB"}
              tooltip={undefined}
              opacity={1}
              padding="0rem 1rem"
              /*  minWidth="50%" */
              /* paddingTop="0.2rem" */
              lineHeight="1.6"
              letterSpacing="0.25rem"
              fontSize="1.6rem"
              borderRadius="0"
              margin="0"
              height="2.5rem"
            />
          </ScatterButtons>
        ) : null}
        {hasCardsInHand ? (
          <ScatterButtons left={false}>
            {/*           <button
            onClick={() =>
              scatterHorizontally(chosenCards, setChosenCards, windowSize)
            }
          >
            Scatter Horizontally
          </button> */}
            {/*           <button
            onClick={() =>
              scatterVertically(chosenCards, setChosenCards, windowSize)
            }
          >
            Scatter
          </button>
          <button
            onClick={() =>
              arrangeVertically(chosenCards, setChosenCards, windowSize)
            }
          >
            Arrange
          </button> */}
            <ButtonPlain
              key={"to deck"}
              hasSimpleLogic={true}
              handleSimpleOnClick={() => {
                scatterVertically(chosenCards, setChosenCards, windowSize)
              }}
              buttonText={"to deck"}
              tooltip={undefined}
              opacity={1}
              padding="0rem 1rem"
              /*  minWidth="50%" */
              paddingTop="0.1rem"
              lineHeight="1.6"
              letterSpacing="0.25rem"
              fontSize="1.6rem"
              borderRadius="0"
              margin="0"
              height="2.5rem"
            />
            <ButtonPlain
              key={"to sideboard"}
              hasSimpleLogic={true}
              handleSimpleOnClick={() => {
                scatterHorizontally(chosenCards, setChosenCards, windowSize)
              }}
              buttonText={"to sideB"}
              tooltip={undefined}
              opacity={1}
              padding="0rem 1rem"
              /*  minWidth="50%" */
              paddingTop="0.1rem"
              lineHeight="1.6"
              letterSpacing="0.25rem"
              fontSize="1.6rem"
              borderRadius="0"
              margin="0"
              height="2.5rem"
            />
          </ScatterButtons>
        ) : null}
        <Explain top="1%">Deck</Explain>
        <Explain top="56%" darker={true}>
          Temporary
        </Explain>
        <Explain top="81%">Sideboard</Explain>
        {chosenCards
          ?.filter(curCard => !curCard.type_line.includes("Basic"))
          .map((card, i) => {
            return (
              <Card
                key={card.unico}
                setChosenCards={setChosenCards}
                refTableMain={ref}
                {...card}
                /*                 key={card.unico}
                unico={card.unico}
                cardId={card.id}
                position={card.position}
                setChosenCards={setChosenCards}
                hasBeenDragged={card.hasBeenDragged}
                {...card} */
              />
            )
          })}
      </TableMain>
      <TableBottom>
        {/*         <div>
          <button
            onClick={() => {
              setWindowSize(prev => ({ ...prev, width: prev.width + 200 }))
            }}
          >
            Add Table Width
          </button>
          <button
            onClick={() => {
              setWindowSize(prev => ({ ...prev, width: prev.width - 200 }))
            }}
          >
            Remove Table Width
          </button>
        </div> */}
        {/*         <div>tableHeight = H{tableHeight}</div>
        <div>windowSize.width (from window.Size)= W{windowSize.width}</div>
        <div>
          TableMain_REF.current.clientHeight - {ref.current?.clientHeight}
        </div>
        <div>
          TableMain_REF.current.clientWidth - {ref.current?.clientWidth}
        </div> */}
        <ButtonPlain
          key={"remove space"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => {
            setWindowSize(prev => ({ ...prev, width: prev.width - 200 }))
          }}
          buttonText={"remove space"}
          tooltip={undefined}
          opacity={1}
          padding="0rem 2rem"
          minWidth="50%"
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="0"
          margin="0"
        />
        <ButtonPlain
          key={"add space"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => {
            setWindowSize(prev => ({ ...prev, width: prev.width + 200 }))
          }}
          buttonText={"add space"}
          tooltip={undefined}
          opacity={1}
          padding="0rem 2rem"
          minWidth="50%"
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="0"
          margin="0"
        />
      </TableBottom>
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  display: ${props => props.displayTable};
  min-width: ${props => props.windowSize.width}px;
  min-height: ${props => props.tableHeight}px;
  width: ${props => props.windowSize.width}px;
  height: ${props => props.tableHeight}px;

  position: relative;
  /*   padding-top: 1rem; */
`

const TableMain = styled.main`
  /*   background-color: lightcoral; */
  background: linear-gradient(
    #212121 55%,
    #030708 55%,
    #030708 80%,
    #212121 80%
  );
  width: 100%;
  height: 100%;
  position: relative;
`

const Explain = styled.span`
  position: absolute;
  top: ${props => props.top};
  color: ${props => (props.darker ? "#838383" : "#AFAFAF")};
  margin-left: 1rem;
`

const ScatterButtons = styled.span`
  position: absolute;
  top: ${props =>
    props.left ? (props.isForDecks ? "750px" : "1372px") : "-6px"};
  left: ${props => (props.left ? "0px" : null)};
  right: ${props => (props.left ? null : "24px")};
  z-index: 1000;
  /* color: #ccc; */
  /* margin-left: 1rem; */
`

const TableBottom = styled.div`
  /*   background-color: cornflowerblue; */
`

export default TableComponent
