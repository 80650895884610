import React, { useState } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { v4 as uuidv4 } from "uuid"
import Img from "gatsby-image"

import ButtonMotion from "../components/buttonMotion"

const EasyView = ({
  showEasyView,
  setShowEasyView,
  chosenCards,
  sideboardCards,
}) => {
  const [idHoveredCard, setIdHoveredCard] = useState(null)

  // MAIN DECK
  const renderBoxes = []
  let fromTop = 0

  // filter out the cards that have not been dragged but let the lands count
  const notTemporaryNotLands = chosenCards.filter(
    card => card.place === "deck" && !card.type_line.includes("Land") // cancella dopo &&, e metti !card.type_line.includes("Basic")
  )
  const notTemporaryLands = chosenCards.filter(
    card => card.place === "deck" && card.type_line.includes("Land") // aggiungi && card.type_line.includes("Basic")
  )
  // sort lands vs !lands in a visualArray
  notTemporaryNotLands
    .sort((a, b) =>
      a.multiverse_ids.length > 0 && b.multiverse_ids.length > 0
        ? a.multiverse_ids[0] - b.multiverse_ids[0]
        : a.cmc - b.cmc
    )
    .sort((a, b) => a.cmc - b.cmc)

  notTemporaryLands.sort((a, b) =>
    a.multiverse_ids.length > 0 && b.multiverse_ids.length > 0
      ? a.multiverse_ids[0] - b.multiverse_ids[0]
      : a.cmc - b.cmc
  )
  const visualArray = [...notTemporaryNotLands, ...notTemporaryLands]

  for (let i = 0; i < visualArray.length; i++) {
    let carta = visualArray[i]

    if (i % 4 === 0) {
      // create a new outer box!
      const outerBox = []
      renderBoxes.push(outerBox)
      fromTop = 0
    }

    renderBoxes[renderBoxes.length - 1].push(
      <a
        href={`https://shop.tcgplayer.com/product/productsearch?id=${carta.tcgplayer_id}&utm_campaign=affiliate&utm_medium=api&utm_source=scryfall`}
        target="_blank"
        rel="noopener"
        key={uuidv4()}
      >
        <HoverSection
          top={fromTop + "rem"}
          onMouseEnter={() => setIdHoveredCard(carta.unico)}
          onMouseLeave={() => setIdHoveredCard(null)}
        />
        <NonGatsbyImg
          top={fromTop + "rem"}
          src={
            carta.image_uris?.border_crop ||
            carta.card_faces[0]?.image_uris.border_crop
          }
          isHovered={idHoveredCard === carta.unico}
        />
      </a>
    )

    fromTop = fromTop + 5
  }

  // SIDEBOARD
  const renderBoxesSideboard = []
  let fromTopSideboard = 0

  const sideboardCardsFiltered = chosenCards.filter(
    card => card.place === "sideboard"
  )

  sideboardCardsFiltered
    .sort((a, b) => a.multiverse_ids[0] - b.multiverse_ids[0])
    .sort((a, b) => a.cmc - b.cmc)

  for (let i = 0; i < sideboardCardsFiltered.length; i++) {
    let carta = sideboardCardsFiltered[i]

    if (i % 4 === 0) {
      // create a new outer box!
      const outerBox = []
      renderBoxesSideboard.push(outerBox)
      fromTopSideboard = 0
    }

    renderBoxesSideboard[renderBoxesSideboard.length - 1].push(
      <a
        href={`https://shop.tcgplayer.com/product/productsearch?id=${carta.tcgplayer_id}&utm_campaign=affiliate&utm_medium=api&utm_source=scryfall`}
        target="_blank"
        rel="noopener"
        key={uuidv4()}
      >
        <HoverSection
          top={fromTopSideboard + "rem"}
          onMouseEnter={() => setIdHoveredCard(carta.unico)}
          onMouseLeave={() => setIdHoveredCard(null)}
        />
        <NonGatsbyImg
          top={fromTopSideboard + "rem"}
          src={
            carta.image_uris?.border_crop ||
            carta.card_faces[0]?.image_uris.border_crop
          }
          isHovered={idHoveredCard === carta.unico}
        />
      </a>
    )

    fromTopSideboard = fromTopSideboard + 5
  }

  return (
    <DeckDetailsWrapper
      variants={variants}
      initial="closed"
      animate={showEasyView ? "open" : "closed"}
      transition={{ damping: 300 }}
    >
      <EasyViewToolbar>
        <ButtonMotion handleClick={() => setShowEasyView(false)} text="Table" />
        {/* <button>Buy the deck on TCGPlayer</button> */}
      </EasyViewToolbar>
      <MainSideboard>Deck</MainSideboard>
      <BoxesWrapperFlex>
        {renderBoxes.map((singleBox, i) => (
          <Box key={i}>{[...singleBox]}</Box>
        ))}
      </BoxesWrapperFlex>
      <MainSideboard>Sideboard</MainSideboard>
      <BoxesWrapperFlex>
        {renderBoxesSideboard.map((singleBox, i) => (
          <Box key={i}>{[...singleBox]}</Box>
        ))}
      </BoxesWrapperFlex>
    </DeckDetailsWrapper>
  )
}

const DeckDetailsWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: radial-gradient(#555, #111);
  padding: 1rem;
  z-index: 1000000;
  color: #cccccc;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const EasyViewToolbar = styled.nav`
  display: flex;
  justify-content: flex-start;

  align-self: flex-start;
`

const BoxesWrapperFlex = styled.section`
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

/* card h208 w149 */
const Box = styled.div`
  height: 43.2rem;
  width: 20.2rem;
  position: relative;
  margin: 1rem;
`

const Card = styled.img`
  position: absolute;
  top: ${props => props.top};
`

const GatsbyImg = styled(Img)`
  width: 20.2rem;
  height: 28.2rem;
  top: ${props => props.top};
  border-radius: 1rem;

  z-index: ${props => props.isHovered && 100};
  box-shadow: ${props => props.isHovered && "0px 0px 5px 3px #ff974c"};
  :hover {
    box-shadow: 0px 0px 5px 3px #ff974c;
  }
`

const NonGatsbyImg = styled.img`
  width: 20.2rem;
  height: 28.2rem;
  top: ${props => props.top};
  border-radius: 1rem;
  position: absolute;

  z-index: ${props => props.isHovered && 100};
  box-shadow: ${props => props.isHovered && "0px 0px 5px 3px #ff974c"};
  :hover {
    box-shadow: 0px 0px 5px 3px #ff974c;
  }
`

const MainSideboard = styled.span`
  font-size: 2.5rem;
  margin: 1rem;
`
const HoverSection = styled.div`
  top: ${props => props.top};
  width: 100%;
  height: 5rem;
  position: absolute;
  z-index: 200;
`

const variants = {
  open: { x: 0 },
  closed: {
    x: "-100%",
    transition: {
      delay: 0,
      x: { type: "tween" },
    },
  },
}

export default EasyView

const NonGatsbyImgOLD = styled.img`
  /*   width: 14.9rem;
  height: 20.8rem; */
  position: absolute;
  top: ${props => props.top};
`
