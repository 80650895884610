import React, { useState } from "react"
import styled from "styled-components"
import Autosuggest from "react-autosuggest"

import "./suggest.css"

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
function getSuggestionValue(suggestion) {
  return suggestion.code
  // suggestion.name se si tratta di sets, .code se si tratta di types o di keywords
}

function renderSectionTitle(section) {
  return <strong>{section.main}</strong>
}

function getSectionSuggestions(section) {
  return section.sub
}

// https://github.com/moroshko/react-autosuggest#shouldrendersuggestions-optional
function shouldRenderSuggestions() {
  return true
}

const Suggest = ({ suggestKey, suggestData, handleSwitchSuggest }) => {
  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState([])

  // Use your imagination to render suggestions.
  /*   function renderSuggestion(suggestion) {
    if (suggestKey === "types") {
      return (
        <span>
          {suggestion.svg}&nbsp;
          {suggestion.code}
        </span>
      )
    } else if (suggestKey === "sets") {
      return (
        <span>
          {suggestion.svg}&nbsp;
          {suggestion.name}
        </span>
      )
    }
  } */
  function renderSuggestion(suggestion) {
    return (
      <span>
        {suggestion.svg}&nbsp;
        {suggestKey === "sets" ? suggestion.name : suggestion.code}
      </span>
    )
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  function getSuggestions(value) {
    if (suggestKey === "types" || suggestKey === "keywords") {
      return suggestData
        .map(typeObj => {
          return {
            main: typeObj.main,
            sub: typeObj.sub.filter(creatureObj =>
              creatureObj.code
                .toLowerCase()
                .includes(value.trim().toLowerCase())
            ),
          }
        })
        .filter(section => section.sub.length > 0)
    } else if (suggestKey === "sets") {
      return suggestData
        .map(setObj => {
          return {
            main: setObj.main,
            sub: setObj.sub.filter(suggestionObj =>
              suggestionObj.name
                .toLowerCase()
                .includes(value.trim().toLowerCase())
            ),
          }
        })
        .filter(section => section.sub.length > 0)
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // reason === "input-changed" || "input focused"
  function onSuggestionsFetchRequested({ value, reason }) {
    setValue(value)
    setSuggestions(getSuggestions(value))
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  function onSuggestionsClearRequested() {
    setSuggestions([])
  }

  // Autosuggest will pass through all these props to the input. It must contain at least value and onChange.
  const inputProps = {
    placeholder: suggestKey,
    value: value,
    onChange: (_, { newValue, method }) => {
      setValue(newValue)
    },
  }

  // NOT REQUIRED, Will be called every time suggestion is selected via mouse or keyboard.
  // https://github.com/moroshko/react-autosuggest#onsuggestionselected-optional
  function onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    handleSwitchSuggest(suggestKey, suggestionValue, "isFiltering")
    setValue("")
    setSuggestions([])
  }

  // ALL <Autosuggest/> PROPS ----> https://github.com/moroshko/react-autosuggest#props

  return (
    <Autosuggest
      multiSection={true}
      suggestions={suggestions}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      inputProps={inputProps}
      id={suggestKey}
      shouldRenderSuggestions={shouldRenderSuggestions}
      /*       highlightFirstSuggestion={true} */
    />
  )
}

export default Suggest
