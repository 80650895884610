import React from "react"
import { useHotkeys } from "react-hotkeys-hook"

export const ShortcutDetails = props => {
  const { setShowDeckDetails } = props
  useHotkeys(
    "alt+c",
    (KeyboardEvent, HotkeysEvent) => {
      setShowDeckDetails(prev => !prev)
    },
    { keydown: true }
  )

  return <></>
}

export const ShortcutEasyView = props => {
  const { setShowEasyView } = props
  useHotkeys(
    "alt+v",
    (KeyboardEvent, HotkeysEvent) => {
      setShowEasyView(prev => !prev)
    },
    { keydown: true }
  )

  return <></>
}

export const ShortcutBinder = props => {
  const { setDisplayRaccoglitore, setDisplayTable } = props
  useHotkeys(
    "alt+b",
    (KeyboardEvent, HotkeysEvent) => {
      setDisplayRaccoglitore("block")
      setDisplayTable("none")
    },
    { keydown: true }
  )

  return <></>
}

export const ShortcutTable = props => {
  const { setDisplayRaccoglitore, setDisplayTable } = props
  useHotkeys(
    "alt+t",
    (KeyboardEvent, HotkeysEvent) => {
      setDisplayRaccoglitore("none")
      setDisplayTable("block")
    },
    { keydown: true }
  )

  return <></>
}

export const ShortcutFilters = props => {
  const { setShowFilters } = props
  useHotkeys(
    "alt+a",
    (KeyboardEvent, HotkeysEvent) => {
      setShowFilters(prev => !prev)
    },
    { keydown: true }
  )

  return <></>
}

export const ShortcutPrevPage = props => {
  const { doThis } = props
  useHotkeys(
    "alt+z",
    (KeyboardEvent, HotkeysEvent) => {
      doThis()
    },
    { keydown: true }
  )

  return <></>
}

export const ShortcutNextPage = props => {
  const { doThis } = props
  useHotkeys(
    "alt+x",
    (KeyboardEvent, HotkeysEvent) => {
      doThis()
    },
    { keydown: true }
  )

  return <></>
}
