import React from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"

const Box = styled.div`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccccc;
  margin: 0 0.5rem;
`

const Quantity = styled.span`
  margin: 0 0.5rem;
  font-size: 2rem;
`

const Plus = styled.button`
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 50%;
  color: #cccccc;
  font-size: 2.2rem;
  margin-right: 0.5rem;

  :hover {
    text-shadow: 0 0 8px hsla(37, 100%, 50%, 1);
  }
`

const AddRemoveManaComponent = props => {
  return (
    <Box title={`Quantity of ${props.landName}`}>
      {props.svg}
      <Quantity>{props.quantity}</Quantity>
      <Plus
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          /*           props.handleDeleteTempFilter("colors", props.code) */
          props.setChosenCards(prevCards => {
            const copyOfPrevCards = [...prevCards]
            const indexOfCardToActOnto = copyOfPrevCards.findIndex(
              card => card.unico === props.unicoIds[0]
            )
            copyOfPrevCards.splice(indexOfCardToActOnto, 1)
            return copyOfPrevCards
          })
        }}
      >
        -
      </Plus>
      <Plus
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          /*           props.handleDeleteTempFilter("colors", props.code) */
          props.setChosenCards(prevCards => {
            const copyOfPrevCards = [...prevCards]
            const indexOfCardToActOnto = copyOfPrevCards.findIndex(
              card => card.unico === props.unicoIds[0]
            )
            const toIncrementCard = copyOfPrevCards[indexOfCardToActOnto]
            copyOfPrevCards.push({
              ...toIncrementCard,
              unico: uuidv4(),
            })
            return copyOfPrevCards
          })
        }}
      >
        +
      </Plus>
    </Box>
  )
}

export default AddRemoveManaComponent
