import React, { useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const Button = styled(motion.button)`
  outline: none;
  cursor: pointer;
  border-radius: 0.3rem;

  width: 16rem;
  height: 3.2rem;
  margin: 0.5rem;
  padding: 0rem;
  padding-top: 0.1rem;

  line-height: 1.6;
  letter-spacing: 0.25rem;
  font-size: 2.2rem;
  color: #cccccc;

  border: 1px solid #141414;
`

const variants = {
  tap: {
    y: 0,
    backgroundColor: "#161616",
    boxShadow:
      "0px 0px 0px 0px #040404, 0px 1px 0px 0px rgba(255,255,255,0.6), 0px 0px 3px 0px rgba(0,0,0,0.3), inset 0px 1px 0px 0px rgba(255,255,255,0.2)",
  },
  hover: {
    y: -3,
    boxShadow:
      "0px 3px 0px 0px #040404, 0px 0px 0px 0px rgba(255,255,255,0.6), 0px 4px 4px 0px rgba(0,0,0,0.3), inset 0px 1px 0px 0px rgba(255,255,255,0.2)",
    backgroundImage:
      "linear-gradient(-180deg,rgba(255, 255, 255, 0.0) 0%,rgba(0, 0, 0, 0.0) 100%)",
  },
  default: {
    y: -4,
    backgroundColor: "#212121",
    boxShadow:
      "0px 4px 0px 0px #040404, 0px 0px 0px 0px rgba(255, 255, 255, 0.6), 0px 6px 4px 0px rgba(0, 0, 0, 0.3), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2)",
    backgroundImage:
      "linear-gradient(-180deg,rgba(255, 255, 255, 0.1) 0%,rgba(0, 0, 0, 0.1) 100%)",
    transition: {
      delay: 0,
      y: { type: "tween" },
    },
  },
}

const ButtonBinderTable = ({
  goTo,
  setDisplayRaccoglitore,
  setDisplayTable,
}) => {
  const [buttonState, setButtonState] = useState("default")
  const handleClick = () => {
    if (goTo === "Table") {
      setDisplayRaccoglitore("none")
      setDisplayTable("block")
    } else if (goTo === "Binder") {
      setDisplayRaccoglitore("block")
      setDisplayTable("none")
    }
  }
  return (
    <Button
      onClick={handleClick}
      variants={variants}
      initial="default"
      animate={buttonState}
      onHoverStart={() => setButtonState("hover")}
      onTapStart={() => setButtonState("tap")}
      onTap={() => setButtonState("default")}
      onHoverEnd={() => setButtonState("default")}
    >
      {goTo}
    </Button>
  )
}

export default ButtonBinderTable
