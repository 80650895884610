import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useDoubleTap } from "../utils/hooks"

const CardComponent = props => {
  const [refCallback, elem] = useDoubleTap(removeCard)

  function removeCard() {
    props.setChosenCards(prevCards => {
      const copyOfPrevCards = [...prevCards]
      const indexOfCardToActOnto = copyOfPrevCards.findIndex(
        card => card.unico === props.unico
      )
      copyOfPrevCards.splice(indexOfCardToActOnto, 1)
      return copyOfPrevCards
    })
  }

  return (
    <FramerBox
      isInBinder={props.isInBinder}
      margLeft={props =>
        props.isInBinder ? "var(--overlap-card-negative)" : "0px"
      }
      myid={props.cardId}
      onDoubleClick={e => {
        removeCard()
      }}
      ref={refCallback}
      drag={props.isInBinder ? false : true}
      /*       dragConstraints={props.refTableMain} */
      style={
        props.isInBinder ? {} : { x: props.position.x, y: props.position.y }
      }
      whileTap={{ boxShadow: "0px 0px 10px 1px #2DE2E6", zIndex: 100000 }}
      whileHover={{ boxShadow: "0px 0px 10px 1px #FF974C" }}
      dragMomentum={false}
      onDragStart={e => e.preventDefault()}
      onDragEnd={(event, info) => {
        props.setChosenCards(prevCards => {
          const copyOfPrevCards = [...prevCards]
          const indexOfCardToActOnto = copyOfPrevCards.findIndex(
            card => card.unico === props.unico
          )
          const draggedCard = copyOfPrevCards[indexOfCardToActOnto]
          copyOfPrevCards.splice(indexOfCardToActOnto, 1)
          draggedCard.position = { x: info.point.x, y: info.point.y }
          draggedCard["hasBeenDragged"] = true
          /*          draggedCard["temporary"] = false */
          copyOfPrevCards.push(draggedCard)
          return copyOfPrevCards
        })
      }}
    >
      <NonGatsbyImg
        alt={props.name}
        draggable="false"
        src={
          props.image_uris?.border_crop ||
          props.card_faces[0]?.image_uris.border_crop
        }
      />
    </FramerBox>
  )
}

const FramerBox = styled(motion.div)`
  cursor: ${props => (props.isInBinder ? "pointer" : "grab")};
  width: 18.4rem;
  height: 25.7rem;
  border-radius: 1rem;
  position: ${props => (props.isInBinder ? "static" : "absolute")};

  margin-left: ${props => props.margLeft};
`

/* const Card = styled(motion.img)`
  height: 257px;
  width: 184px;
  position: absolute;
  border-radius: 8px;
` */

const NonGatsbyImg = styled.img`
  width: 18.4rem;
  height: 25.7rem;
`

export default CardComponent
