import React, { useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { v4 as uuidv4 } from "uuid"
import Img from "gatsby-image"
import svgflip from "../assets/svgflip"

function calculateIsFlippable(card) {
  if (card.image_uris) {
    return false
  }
  return true
}

const RaccoglitoreCarta = ({ setChosenCards, ...carta }) => {
  const [flipped, setFlipped] = useState(false)
  const isFlippable = calculateIsFlippable(carta)

  return (
    <Box
      onClick={() =>
        setChosenCards(prevChosenCards => {
          /*           console.log("CLICK ON BOX") */
          const copyChosenCards = [...prevChosenCards]
          copyChosenCards.push({
            ...carta,
            unico: uuidv4(),
          })
          return copyChosenCards
        })
      }
      variants={variants}
      initial="default"
      /*     animate={buttonState} */
      /*       onHoverStart={() => setButtonState("hover")}
      onTapStart={() => setButtonState("tap")}
      onTap={() => setButtonState("default")}
      onHoverEnd={() => setButtonState("default")} */
      whileHover="hover"
      whileTap="tap"
    >
      <NonGatsbyImg
        alt={carta.name}
        src={
          flipped
            ? carta.card_faces[1]?.image_uris.border_crop
            : carta.image_uris?.border_crop ||
              carta.card_faces[0]?.image_uris.border_crop
        }
      />
      {isFlippable && (
        /*         <Flipper
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setFlipped(prev => !prev)
          }}
        /> */
        <ST_svg
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setFlipped(prev => !prev)
          }}
          style={{
            "--width": "32px",
            "--height": "32px",
            "--fill": "#e6e6e6",
          }}
        >
          {svgflip}
        </ST_svg>
      )}
    </Box>
  )
}

const Box = styled(motion.div)`
  cursor: pointer;

  width: 20.2rem;
  height: 28.2rem;

  border-radius: 1rem;

  margin-bottom: 1rem;

  position: relative;
`
/* const Box = styled(motion.img)`
  cursor: pointer;

  width: 20.2rem;
  height: 28.2rem;

  border-radius: 1rem;

  margin-bottom: 1rem;
` */

const variants = {
  tap: {
    boxShadow: "0px 0px 8px 8px #2DE2E6",
  },
  hover: {
    boxShadow: "0px 0px  6px 4px #FF974C",
  },
  default: {
    boxShadow: "0px 0px 0px 0px #000000", // metti meutrlae neutrale
    transition: {
      delay: 0,
    },
  },
}

const GatsbyImg = styled(Img)`
  width: 20.2rem;
  height: 28.2rem;
`
const NonGatsbyImg = styled.img`
  width: 20.2rem;
  height: 28.2rem;
`

const ST_svg = styled.button`
  cursor: grabbing;
  outline: none;
  border: none;
  background: none;
  position: absolute;
  top: 35px;
  left: 15px;
  z-index: 2000;

  svg {
    fill: var(--fill);
    width: var(--width);
    height: var(--height);
    filter: drop-shadow(0px 0px 3px #222);
  }
`

const Flipper = styled.button`
  /* display: flex;
  cursor: pointer;
  border: none;
  outline: none;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: #cccccc;
  margin-left: 0.6rem;
  margin-top: 0.1rem;
  color: #000000;
  justify-content: center;
  align-items: center;
  padding-top: 0.3rem; */

  position: absolute;
  top: 5.5rem;
  left: 3rem;
  background-color: red;
  z-index: 2000;
  width: 2rem;
  height: 2rem;
`

export default RaccoglitoreCarta
