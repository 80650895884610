import React from "react"

export default (
  <svg width={24} height={24} viewBox="-20 -10 640 400">
    <defs>
      <radialGradient
        gradientUnits="userSpaceOnUse"
        cx={409.58}
        cy={308.301}
        r={300}
        id="a"
      >
        <stop offset={0} stopColor="#ffe9ff" />
        <stop offset={1} stopColor="#ff83ff" />
      </radialGradient>
    </defs>
    <path
      d="M328.64 541.68c-82.101-7.902-162.796-28.983-196.567-51.352-35.431-23.47-29.968-47.962 23.264-104.285 47.797-50.572 69.786-92.95 100.818-194.298 20.38-66.56 39.187-108.4 51.901-115.469 19.635-10.914 33.197 4.883 59.186 68.934 27.624 68.081 51.284 109.37 80.491 140.463 8.817 9.386 17.39 15.774 21.172 15.774 7.472 0 18.422-13.086 38.063-45.488 15.9-26.233 28.051-34.478 46.56-31.594 17.139 2.671 30.08 19.694 45.289 59.576 7.138 18.717 17.377 42.82 22.754 53.56 10.088 20.151 35.724 57.039 39.718 57.15 4.605.13 39.132 34.821 43.896 44.105 14.445 28.15-6.889 53.008-61.484 71.642-65.618 22.395-124.916 31.36-217.512 32.883-38.007.625-81.905-.096-97.55-1.602z"
      fill="#000"
      transform="translate(-109.58 -73.096)"
    />
    <path
      d="M328.64 541.68c-82.101-7.902-162.796-28.983-196.567-51.352-35.431-23.47-29.968-47.962 23.264-104.285 47.797-50.572 69.786-92.95 100.818-194.298 20.38-66.56 39.187-108.4 51.901-115.469 19.635-10.914 33.197 4.883 59.186 68.934 27.624 68.081 51.284 109.37 80.491 140.463 8.817 9.386 17.39 15.774 21.172 15.774 7.472 0 18.422-13.086 38.063-45.488 15.9-26.233 28.051-34.478 46.56-31.594 17.139 2.671 30.08 19.694 45.289 59.576 7.138 18.717 17.377 42.82 22.754 53.56 10.088 20.151 35.724 57.039 39.718 57.15 4.605.13 39.132 34.821 43.896 44.105 14.445 28.15-6.889 53.008-61.484 71.642-65.618 22.395-124.916 31.36-217.512 32.883-38.007.625-81.905-.096-97.55-1.602z"
      paintOrder="fill"
      fill="url(#a)"
      transform="matrix(.78369 .00371 .00767 .50648 -18.66 135.463)"
    />
  </svg>
)
