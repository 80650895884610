import React, { useState, useEffect, useReducer, useMemo } from "react"

import svgRedMana from "../assets/svgRedMana"
import svgGreenMana from "../assets/svgGreenMana"
import svgBluMana from "../assets/svgBluMana"
import svgBlackMana from "../assets/svgBlackMana"
import svgWhiteMana from "../assets/svgWhiteMana"
import svgColorlessMana from "../assets/svgColorlessMana"

import svg0 from "../assets/svg0"
import svg1 from "../assets/svg1"
import svg2 from "../assets/svg2"
import svg3 from "../assets/svg3"
import svg4 from "../assets/svg4"
import svg5 from "../assets/svg5"
import svg6 from "../assets/svg6"
import svgInfinity from "../assets/svgInfinity"

import svgArtifact from "../assets/svgArtifact"
import svgCreature from "../assets/svgCreature"
import svgEnchantment from "../assets/svgEnchantment"
import svgInstant from "../assets/svgInstant"
import svgPW from "../assets/svgPW"
import svgSorcery from "../assets/svgSorcery"
import svgLandSymbol from "../assets/svgLandSymbol"
import svgLandSymbolBasic from "../assets/svgLandSymbolBasic"

import svgcommon from "../assets/svgcommon"
import svguncommon from "../assets/svguncommon"
import svgrare from "../assets/svgrare"
import svgmythic from "../assets/svgmythic"

import svgiko from "../assets/svgiko"
import svgthb from "../assets/svgthb"
import svgeld from "../assets/svgeld"
import svgwar from "../assets/svgwar"
import svgrna from "../assets/svgrna"
import svggrn from "../assets/svggrn"
import svgdom from "../assets/svgdom"
import svgrix from "../assets/svgrix"
import svgxln from "../assets/svgxln"
import svghou from "../assets/svghou"
import svgakh from "../assets/svgakh"
import svgaer from "../assets/svgaer"
import svgkld from "../assets/svgkld"
import svgemn from "../assets/svgemn"
import svgsoi from "../assets/svgsoi"
import svgogw from "../assets/svgogw"
import svgbfz from "../assets/svgbfz"
import svgdtk from "../assets/svgdtk"
import svgfrf from "../assets/svgfrf"
import svgktk from "../assets/svgktk"
import svgjou from "../assets/svgjou"
import svgbng from "../assets/svgbng"
import svgths from "../assets/svgths"
import svgdgm from "../assets/svgdgm"
import svggtc from "../assets/svggtc"
import svgrtr from "../assets/svgrtr"
import svgavr from "../assets/svgavr"
import svgdka from "../assets/svgdka"
import svgisd from "../assets/svgisd"
import svgnph from "../assets/svgnph"
import svgmbs from "../assets/svgmbs"
import svgsom from "../assets/svgsom"
import svgroe from "../assets/svgroe"
import svgwwk from "../assets/svgwwk"
import svgzen from "../assets/svgzen"
import svgarb from "../assets/svgarb"
import svgcon from "../assets/svgcon"
import svgala from "../assets/svgala"
import svgeve from "../assets/svgeve"
import svgshm from "../assets/svgshm"
import svgmor from "../assets/svgmor"
import svglrw from "../assets/svglrw"
import svgfut from "../assets/svgfut"
import svgplc from "../assets/svgplc"
import svgtsp from "../assets/svgtsp"
import svgcsp from "../assets/svgcsp"
import svgdis from "../assets/svgdis"
import svggpt from "../assets/svggpt"
import svgrav from "../assets/svgrav"
import svgsok from "../assets/svgsok"
import svgbok from "../assets/svgbok"
import svgchk from "../assets/svgchk"
import svg5dn from "../assets/svg5dn"
import svgdst from "../assets/svgdst"
import svgmrd from "../assets/svgmrd"
import svgscg from "../assets/svgscg"
import svglgn from "../assets/svglgn"
import svgons from "../assets/svgons"
import svgjud from "../assets/svgjud"
import svgtor from "../assets/svgtor"
import svgody from "../assets/svgody"
import svgapc from "../assets/svgapc"
import svgpls from "../assets/svgpls"
import svginv from "../assets/svginv"
import svgpcy from "../assets/svgpcy"
import svgnem from "../assets/svgnem"
import svgmmq from "../assets/svgmmq"
import svguds from "../assets/svguds"
import svgulg from "../assets/svgulg"
import svgusg from "../assets/svgusg"
import svgexo from "../assets/svgexo"
import svgsth from "../assets/svgsth"
import svgtmp from "../assets/svgtmp"
import svgwth from "../assets/svgwth"
import svgvis from "../assets/svgvis"
import svgmir from "../assets/svgmir"
import svgall from "../assets/svgall"
import svghml from "../assets/svghml"
import svgice from "../assets/svgice"
import svgfem from "../assets/svgfem"
import svgdrk from "../assets/svgdrk"
import svgleg from "../assets/svgleg"
import svgatq from "../assets/svgatq"
import svgarn from "../assets/svgarn"
import svgm21 from "../assets/svgm21"
import svgm20 from "../assets/svgm20"
import svgm19 from "../assets/svgm19"
import svgori from "../assets/svgori"
import svgm15 from "../assets/svgm15"
import svgm14 from "../assets/svgm14"
import svgm13 from "../assets/svgm13"
import svgm12 from "../assets/svgm12"
import svgm11 from "../assets/svgm11"
import svgm10 from "../assets/svgm10"
import svg10e from "../assets/svg10e"
import svg9ed from "../assets/svg9ed"
import svg8ed from "../assets/svg8ed"
import svg7ed from "../assets/svg7ed"
import svg6ed from "../assets/svg6ed"
import svg5ed from "../assets/svg5ed"
import svg4ed from "../assets/svg4ed"
import svgsum from "../assets/svgsum"
import svg3ed from "../assets/svg3ed"
import svgfbb from "../assets/svgfbb"
import svg2ed from "../assets/svg2ed"
import svgleb from "../assets/svgleb"
import svglea from "../assets/svglea"
import svgznr from "../assets/svgznr"
import svgkhm from "../assets/svgkhm"
import svgstx from "../assets/svgstx"
import svgtsb from "../assets/svgtsb"
import svgmh2 from "../assets/svgmh2"
import svgafr from "../assets/svgafr"

export function useFilters() {
  const filtersEmptyState = {
    power: "",
    toughness: "",
    card_name: "",
    card_text: "",
    indexes: { from: 0, to: 30 },
    rarities: [
      {
        code: "common",
        isFiltering: false,
        svg: svgcommon,
      },
      {
        code: "uncommon",
        isFiltering: false,
        svg: svguncommon,
      },
      {
        code: "rare",
        isFiltering: false,
        svg: svgrare,
      },
      {
        code: "mythic",
        isFiltering: false,
        svg: svgmythic,
      },
    ],
    manaCosts: [
      {
        code: 0,
        isFiltering: false,
        svg: svg0,
      },
      {
        code: 1,
        isFiltering: false,
        svg: svg1,
      },
      {
        code: 2,
        isFiltering: false,
        svg: svg2,
      },
      {
        code: 3,
        isFiltering: false,
        svg: svg3,
      },
      {
        code: 4,
        isFiltering: false,
        svg: svg4,
      },
      {
        code: 5,
        isFiltering: false,
        svg: svg5,
      },
      {
        code: 6,
        isFiltering: false,
        svg: svg6,
      },
      {
        code: Infinity,
        isFiltering: false,
        svg: svgInfinity,
      },
    ],
    search_mode: "exact",
    legalities: [
      {
        code: "standard",
        isFiltering: false,
        isPermanent: true,
      },
      {
        code: "historic",
        isFiltering: false,
        isPermanent: true,
      },
      {
        code: "commander",
        isFiltering: false,
        isPermanent: true,
      },
      {
        code: "modern",
        isFiltering: false,
        isPermanent: true,
      },
      {
        code: "pioneer",
        isFiltering: false,
        isPermanent: true,
      },
      {
        code: "legacy",
        isFiltering: false,
        isPermanent: true,
      },
      {
        code: "pauper",
        isFiltering: false,
        isPermanent: true,
      },
      {
        code: "vintage",
        isFiltering: false,
        isPermanent: true,
      },
      {
        code: "penny",
        isFiltering: false,
        isPermanent: false,
      },
      {
        code: "brawl",
        isFiltering: false,
        isPermanent: false,
      },
      {
        code: "duel",
        isFiltering: false,
        isPermanent: false,
      },
      {
        code: "oldschool",
        isFiltering: false,
        isPermanent: false,
      },
      {
        code: "future",
        isFiltering: false,
        isPermanent: false,
      },
    ],
    colors: [
      {
        isFiltering: false,
        code: "W",
        svg: svgWhiteMana,
        isPermanent: true,
      },
      {
        isFiltering: false,
        code: "U",
        svg: svgBluMana,
        isPermanent: true,
      },
      {
        isFiltering: false,
        code: "B",
        svg: svgBlackMana,
        isPermanent: true,
      },
      {
        isFiltering: false,
        code: "R",
        svg: svgRedMana,
        isPermanent: true,
      },
      {
        isFiltering: false,
        code: "G",
        svg: svgGreenMana,
        isPermanent: true,
      },
      {
        isFiltering: false,
        code: "",
        svg: svgColorlessMana,
        isPermanent: true,
      },
      {
        isFiltering: false,
        code: "WU",
        svg: [svgWhiteMana, svgBluMana],
        isPermanent: false,
      },
      {
        isFiltering: false,
        code: "WB",
        svg: [svgWhiteMana, svgBlackMana],
        isPermanent: false,
      },
      {
        isFiltering: false,
        code: "WR",
        svg: [svgWhiteMana, svgRedMana],
        isPermanent: false,
      },
      {
        isFiltering: false,
        code: "WG",
        svg: [svgWhiteMana, svgGreenMana],
        isPermanent: false,
      },
      {
        isFiltering: false,
        code: "UB",
        svg: [svgBluMana, svgBlackMana],
        isPermanent: false,
      },
      {
        isFiltering: false,
        code: "UR",
        svg: [svgBluMana, svgRedMana],
        isPermanent: false,
      },
      {
        isFiltering: false,
        code: "UG",
        svg: [svgBluMana, svgGreenMana],
        isPermanent: false,
      },
      {
        isFiltering: false,
        code: "BR",
        svg: [svgBlackMana, svgRedMana],
        isPermanent: false,
      },
      {
        isFiltering: false,
        code: "BG",
        svg: [svgBlackMana, svgGreenMana],
        isPermanent: false,
      },
      {
        isFiltering: false,
        code: "RG",
        svg: [svgRedMana, svgGreenMana],
        isPermanent: false,
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "WRG",
        svg: [svgWhiteMana, svgRedMana, svgGreenMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "WUB",
        svg: [svgWhiteMana, svgBluMana, svgBlackMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "UBR",
        svg: [svgBluMana, svgBlackMana, svgRedMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "BRG",
        svg: [svgBlackMana, svgRedMana, svgGreenMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "WUG",
        svg: [svgWhiteMana, svgBluMana, svgGreenMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "WBG",
        svg: [svgWhiteMana, svgBlackMana, svgGreenMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "URG",
        svg: [svgBluMana, svgRedMana, svgGreenMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "WUR",
        svg: [svgWhiteMana, svgBluMana, svgRedMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "WBR",
        svg: [svgWhiteMana, svgBlackMana, svgRedMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "UBG",
        svg: [svgBluMana, svgBlackMana, svgGreenMana],
      },
      {
        toExclude: false,
        isFiltering: false,
        code: "WUBRG",
        svg: [svgWhiteMana, svgBluMana, svgBlackMana, svgRedMana, svgGreenMana],
      },
    ],
    sets: [
      {
        main: "expansion",
        sub: [
          {
            code: "afr",
            name: "Adventures in the Forgotten Realms",
            released_at: "2021-07-23",
            svg: svgafr,
            isFiltering: false,
          },
          {
            code: "stx",
            name: "Strixhaven: School of Mages",
            released_at: "2021-04-23",
            svg: svgstx,
            isFiltering: false,
          },
          {
            code: "khm",
            name: "Kaldheim",
            released_at: "2021-02-05",
            svg: svgkhm,
            isFiltering: false,
          },
          {
            code: "znr",
            name: "Zendikar Rising",
            released_at: "2020-09-25",
            svg: svgznr,
            isFiltering: false,
          },
          {
            code: "iko",
            name: "Ikoria: Lair of Behemoths",
            released_at: "2020-04-24",
            svg: svgiko,
            isFiltering: false,
          },
          {
            code: "thb",
            name: "Theros Beyond Death",
            released_at: "2020-01-24",
            svg: svgthb,
            isFiltering: false,
          },
          {
            code: "eld",
            name: "Throne of Eldraine",
            released_at: "2019-10-04",
            svg: svgeld,
            isFiltering: false,
          },
          {
            code: "war",
            name: "War of the Spark",
            released_at: "2019-05-03",
            svg: svgwar,
            isFiltering: false,
          },
          {
            code: "rna",
            name: "Ravnica Allegiance",
            released_at: "2019-01-25",
            svg: svgrna,
            isFiltering: false,
          },
          {
            code: "grn",
            name: "Guilds of Ravnica",
            released_at: "2018-10-05",
            svg: svggrn,
            isFiltering: false,
          },
          {
            code: "dom",
            name: "Dominaria",
            released_at: "2018-04-27",
            svg: svgdom,
            isFiltering: false,
          },
          {
            code: "rix",
            name: "Rivals of Ixalan",
            released_at: "2018-01-19",
            svg: svgrix,
            isFiltering: false,
          },
          {
            code: "xln",
            name: "Ixalan",
            released_at: "2017-09-29",
            svg: svgxln,
            isFiltering: false,
          },
          {
            code: "hou",
            name: "Hour of Devastation",
            released_at: "2017-07-14",
            svg: svghou,
            isFiltering: false,
          },
          {
            code: "akh",
            name: "Amonkhet",
            released_at: "2017-04-28",
            svg: svgakh,
            isFiltering: false,
          },
          {
            code: "aer",
            name: "Aether Revolt",
            released_at: "2017-01-20",
            svg: svgaer,
            isFiltering: false,
          },
          {
            code: "kld",
            name: "Kaladesh",
            released_at: "2016-09-30",
            svg: svgkld,
            isFiltering: false,
          },
          {
            code: "emn",
            name: "Eldritch Moon",
            released_at: "2016-07-22",
            svg: svgemn,
            isFiltering: false,
          },
          {
            code: "soi",
            name: "Shadows over Innistrad",
            released_at: "2016-04-08",
            svg: svgsoi,
            isFiltering: false,
          },
          {
            code: "ogw",
            name: "Oath of the Gatewatch",
            released_at: "2016-01-22",
            svg: svgogw,
            isFiltering: false,
          },
          {
            code: "bfz",
            name: "Battle for Zendikar",
            released_at: "2015-10-02",
            svg: svgbfz,
            isFiltering: false,
          },
          {
            code: "dtk",
            name: "Dragons of Tarkir",
            released_at: "2015-03-27",
            svg: svgdtk,
            isFiltering: false,
          },
          {
            code: "frf",
            name: "Fate Reforged",
            released_at: "2015-01-23",
            svg: svgfrf,
            isFiltering: false,
          },
          {
            code: "ktk",
            name: "Khans of Tarkir",
            released_at: "2014-09-26",
            svg: svgktk,
            isFiltering: false,
          },
          {
            code: "jou",
            name: "Journey into Nyx",
            released_at: "2014-05-02",
            svg: svgjou,
            isFiltering: false,
          },
          {
            code: "bng",
            name: "Born of the Gods",
            released_at: "2014-02-07",
            svg: svgbng,
            isFiltering: false,
          },
          {
            code: "ths",
            name: "Theros",
            released_at: "2013-09-27",
            svg: svgths,
            isFiltering: false,
          },
          {
            code: "dgm",
            name: "Dragon's Maze",
            released_at: "2013-05-03",
            svg: svgdgm,
            isFiltering: false,
          },
          {
            code: "gtc",
            name: "Gatecrash",
            released_at: "2013-02-01",
            svg: svggtc,
            isFiltering: false,
          },
          {
            code: "rtr",
            name: "Return to Ravnica",
            released_at: "2012-10-05",
            svg: svgrtr,
            isFiltering: false,
          },
          {
            code: "avr",
            name: "Avacyn Restored",
            released_at: "2012-05-04",
            svg: svgavr,
            isFiltering: false,
          },
          {
            code: "dka",
            name: "Dark Ascension",
            released_at: "2012-02-03",
            svg: svgdka,
            isFiltering: false,
          },
          {
            code: "isd",
            name: "Innistrad",
            released_at: "2011-09-30",
            svg: svgisd,
            isFiltering: false,
          },
          {
            code: "nph",
            name: "New Phyrexia",
            released_at: "2011-05-13",
            svg: svgnph,
            isFiltering: false,
          },
          {
            code: "mbs",
            name: "Mirrodin Besieged",
            released_at: "2011-02-04",
            svg: svgmbs,
            isFiltering: false,
          },
          {
            code: "som",
            name: "Scars of Mirrodin",
            released_at: "2010-10-01",
            svg: svgsom,
            isFiltering: false,
          },
          {
            code: "roe",
            name: "Rise of the Eldrazi",
            released_at: "2010-04-23",
            svg: svgroe,
            isFiltering: false,
          },
          {
            code: "wwk",
            name: "Worldwake",
            released_at: "2010-02-05",
            svg: svgwwk,
            isFiltering: false,
          },
          {
            code: "zen",
            name: "Zendikar",
            released_at: "2009-10-02",
            svg: svgzen,
            isFiltering: false,
          },
          {
            code: "arb",
            name: "Alara Reborn",
            released_at: "2009-04-30",
            svg: svgarb,
            isFiltering: false,
          },
          {
            code: "con",
            name: "Conflux",
            released_at: "2009-02-06",
            svg: svgcon,
            isFiltering: false,
          },
          {
            code: "ala",
            name: "Shards of Alara",
            released_at: "2008-10-03",
            svg: svgala,
            isFiltering: false,
          },
          {
            code: "eve",
            name: "Eventide",
            released_at: "2008-07-25",
            svg: svgeve,
            isFiltering: false,
          },
          {
            code: "shm",
            name: "Shadowmoor",
            released_at: "2008-05-02",
            svg: svgshm,
            isFiltering: false,
          },
          {
            code: "mor",
            name: "Morningtide",
            released_at: "2008-02-01",
            svg: svgmor,
            isFiltering: false,
          },
          {
            code: "lrw",
            name: "Lorwyn",
            released_at: "2007-10-12",
            svg: svglrw,
            isFiltering: false,
          },
          {
            code: "fut",
            name: "Future Sight",
            released_at: "2007-05-04",
            svg: svgfut,
            isFiltering: false,
          },
          {
            code: "plc",
            name: "Planar Chaos",
            released_at: "2007-02-02",
            svg: svgplc,
            isFiltering: false,
          },
          {
            code: "tsb",
            name: "Time Spiral Timeshifted",
            released_at: "2006-10-06",
            svg: svgtsb,
            isFiltering: false,
          },
          {
            code: "tsp",
            name: "Time Spiral",
            released_at: "2006-10-06",
            svg: svgtsp,
            isFiltering: false,
          },
          {
            code: "csp",
            name: "Coldsnap",
            released_at: "2006-07-21",
            svg: svgcsp,
            isFiltering: false,
          },
          {
            code: "dis",
            name: "Dissension",
            released_at: "2006-05-05",
            svg: svgdis,
            isFiltering: false,
          },
          {
            code: "gpt",
            name: "Guildpact",
            released_at: "2006-02-03",
            svg: svggpt,
            isFiltering: false,
          },
          {
            code: "rav",
            name: "Ravnica: City of Guilds",
            released_at: "2005-10-07",
            svg: svgrav,
            isFiltering: false,
          },
          {
            code: "sok",
            name: "Saviors of Kamigawa",
            released_at: "2005-06-03",
            svg: svgsok,
            isFiltering: false,
          },
          {
            code: "bok",
            name: "Betrayers of Kamigawa",
            released_at: "2005-02-04",
            svg: svgbok,
            isFiltering: false,
          },
          {
            code: "chk",
            name: "Champions of Kamigawa",
            released_at: "2004-10-01",
            svg: svgchk,
            isFiltering: false,
          },
          {
            code: "5dn",
            name: "Fifth Dawn",
            released_at: "2004-06-04",
            svg: svg5dn,
            isFiltering: false,
          },
          {
            code: "dst",
            name: "Darksteel",
            released_at: "2004-02-06",
            svg: svgdst,
            isFiltering: false,
          },
          {
            code: "mrd",
            name: "Mirrodin",
            released_at: "2003-10-02",
            svg: svgmrd,
            isFiltering: false,
          },
          {
            code: "scg",
            name: "Scourge",
            released_at: "2003-05-26",
            svg: svgscg,
            isFiltering: false,
          },
          {
            code: "lgn",
            name: "Legions",
            released_at: "2003-02-03",
            svg: svglgn,
            isFiltering: false,
          },
          {
            code: "ons",
            name: "Onslaught",
            released_at: "2002-10-07",
            svg: svgons,
            isFiltering: false,
          },
          {
            code: "jud",
            name: "Judgment",
            released_at: "2002-05-27",
            svg: svgjud,
            isFiltering: false,
          },
          {
            code: "tor",
            name: "Torment",
            released_at: "2002-02-04",
            svg: svgtor,
            isFiltering: false,
          },
          {
            code: "ody",
            name: "Odyssey",
            released_at: "2001-10-01",
            svg: svgody,
            isFiltering: false,
          },
          {
            code: "apc",
            name: "Apocalypse",
            released_at: "2001-06-04",
            svg: svgapc,
            isFiltering: false,
          },
          {
            code: "pls",
            name: "Planeshift",
            released_at: "2001-02-05",
            svg: svgpls,
            isFiltering: false,
          },
          {
            code: "inv",
            name: "Invasion",
            released_at: "2000-10-02",
            svg: svginv,
            isFiltering: false,
          },
          {
            code: "pcy",
            name: "Prophecy",
            released_at: "2000-06-05",
            svg: svgpcy,
            isFiltering: false,
          },
          {
            code: "nem",
            name: "Nemesis",
            released_at: "2000-02-14",
            svg: svgnem,
            isFiltering: false,
          },
          {
            code: "mmq",
            name: "Mercadian Masques",
            released_at: "1999-10-04",
            svg: svgmmq,
            isFiltering: false,
          },
          {
            code: "uds",
            name: "Urza's Destiny",
            released_at: "1999-06-07",
            svg: svguds,
            isFiltering: false,
          },
          {
            code: "ulg",
            name: "Urza's Legacy",
            released_at: "1999-02-15",
            svg: svgulg,
            isFiltering: false,
          },
          {
            code: "usg",
            name: "Urza's Saga",
            released_at: "1998-10-12",
            svg: svgusg,
            isFiltering: false,
          },
          {
            code: "exo",
            name: "Exodus",
            released_at: "1998-06-15",
            svg: svgexo,
            isFiltering: false,
          },
          {
            code: "sth",
            name: "Stronghold",
            released_at: "1998-03-02",
            svg: svgsth,
            isFiltering: false,
          },
          {
            code: "tmp",
            name: "Tempest",
            released_at: "1997-10-14",
            svg: svgtmp,
            isFiltering: false,
          },
          {
            code: "wth",
            name: "Weatherlight",
            released_at: "1997-06-09",
            svg: svgwth,
            isFiltering: false,
          },
          {
            code: "vis",
            name: "Visions",
            released_at: "1997-02-03",
            svg: svgvis,
            isFiltering: false,
          },
          {
            code: "mir",
            name: "Mirage",
            released_at: "1996-10-08",
            svg: svgmir,
            isFiltering: false,
          },
          {
            code: "all",
            name: "Alliances",
            released_at: "1996-06-10",
            svg: svgall,
            isFiltering: false,
          },
          {
            code: "hml",
            name: "Homelands",
            released_at: "1995-10-01",
            svg: svghml,
            isFiltering: false,
          },
          {
            code: "ice",
            name: "Ice Age",
            released_at: "1995-06-03",
            svg: svgice,
            isFiltering: false,
          },
          {
            code: "fem",
            name: "Fallen Empires",
            released_at: "1994-11-01",
            svg: svgfem,
            isFiltering: false,
          },
          {
            code: "drk",
            name: "The Dark",
            released_at: "1994-08-01",
            svg: svgdrk,
            isFiltering: false,
          },
          {
            code: "leg",
            name: "Legends",
            released_at: "1994-06-01",
            svg: svgleg,
            isFiltering: false,
          },
          {
            code: "atq",
            name: "Antiquities",
            released_at: "1994-03-04",
            svg: svgatq,
            isFiltering: false,
          },
          {
            code: "arn",
            name: "Arabian Nights",
            released_at: "1993-12-17",
            svg: svgarn,
            isFiltering: false,
          },
        ],
      },
      {
        main: "core",
        sub: [
          {
            code: "m21",
            name: "Core Set 2021",
            released_at: "2020-07-03",
            svg: svgm21,
            isFiltering: false,
          },
          {
            code: "m20",
            name: "Core Set 2020",
            released_at: "2019-07-12",
            svg: svgm20,
            isFiltering: false,
          },
          {
            code: "m19",
            name: "Core Set 2019",
            released_at: "2018-07-13",
            svg: svgm19,
            isFiltering: false,
          },
          {
            code: "ori",
            name: "Magic Origins",
            released_at: "2015-07-17",
            svg: svgori,
            isFiltering: false,
          },
          {
            code: "m15",
            name: "Magic 2015",
            released_at: "2014-07-18",
            svg: svgm15,
            isFiltering: false,
          },
          {
            code: "m14",
            name: "Magic 2014",
            released_at: "2013-07-19",
            svg: svgm14,
            isFiltering: false,
          },
          {
            code: "m13",
            name: "Magic 2013",
            released_at: "2012-07-13",
            svg: svgm13,
            isFiltering: false,
          },
          {
            code: "m12",
            name: "Magic 2012",
            released_at: "2011-07-15",
            svg: svgm12,
            isFiltering: false,
          },
          {
            code: "m11",
            name: "Magic 2011",
            released_at: "2010-07-16",
            svg: svgm11,
            isFiltering: false,
          },
          {
            code: "m10",
            name: "Magic 2010",
            released_at: "2009-07-17",
            svg: svgm10,
            isFiltering: false,
          },
          {
            code: "10e",
            name: "Tenth Edition",
            released_at: "2007-07-13",
            svg: svg10e,
            isFiltering: false,
          },
          {
            code: "9ed",
            name: "Ninth Edition",
            released_at: "2005-07-29",
            svg: svg9ed,
            isFiltering: false,
          },
          {
            code: "8ed",
            name: "Eighth Edition",
            released_at: "2003-07-28",
            svg: svg8ed,
            isFiltering: false,
          },
          {
            code: "7ed",
            name: "Seventh Edition",
            released_at: "2001-04-11",
            svg: svg7ed,
            isFiltering: false,
          },
          {
            code: "6ed",
            name: "Classic Sixth Edition",
            released_at: "1999-04-21",
            svg: svg6ed,
            isFiltering: false,
          },
          {
            code: "5ed",
            name: "Fifth Edition",
            released_at: "1997-03-24",
            svg: svg5ed,
            isFiltering: false,
          },
          {
            code: "4bb",
            name: "Fourth Edition Foreign Black Border",
            released_at: "1995-04-01",
            svg: svg4ed,
            isFiltering: false,
          },
          {
            code: "4ed",
            name: "Fourth Edition",
            released_at: "1995-04-01",
            svg: svg4ed,
            isFiltering: false,
          },
          {
            code: "sum",
            name: "Summer Magic / Edgar",
            released_at: "1994-06-21",
            svg: svgsum,
            isFiltering: false,
          },
          {
            code: "fbb",
            name: "Foreign Black Border",
            released_at: "1994-04-01",
            svg: svgfbb,
            isFiltering: false,
          },
          {
            code: "3ed",
            name: "Revised Edition",
            released_at: "1994-04-01",
            svg: svg3ed,
            isFiltering: false,
          },
          {
            code: "2ed",
            name: "Unlimited Edition",
            released_at: "1993-12-01",
            svg: svg2ed,
            isFiltering: false,
          },
          {
            code: "leb",
            name: "Limited Edition Beta",
            released_at: "1993-10-04",
            svg: svgleb,
            isFiltering: false,
          },
          {
            code: "lea",
            name: "Limited Edition Alpha",
            released_at: "1993-08-05",
            svg: svglea,
            isFiltering: false,
          },
        ],
      },
      {
        main: "commander",
        sub: [
          {
            code: "c21",
            name: "Commander 2021",
            released_at: "2021-04-23",
            svg: null,
            isFiltering: false,
          },
          {
            code: "khc",
            name: "Kaldheim Commander",
            released_at: "2021-02-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "znc",
            name: "Zendikar Rising Commander",
            released_at: "2020-09-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "c20",
            name: "Commander 2020",
            released_at: "2020-04-17",
            svg: null,
            isFiltering: false,
          },
          {
            code: "c19",
            name: "Commander 2019",
            released_at: "2019-08-23",
            svg: null,
            isFiltering: false,
          },
          {
            code: "c18",
            name: "Commander 2018",
            released_at: "2018-08-09",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cm2",
            name: "Commander Anthology Volume II",
            released_at: "2018-06-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "c17",
            name: "Commander 2017",
            released_at: "2017-08-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cma",
            name: "Commander Anthology",
            released_at: "2017-06-09",
            svg: null,
            isFiltering: false,
          },
          {
            code: "c16",
            name: "Commander 2016",
            released_at: "2016-11-11",
            svg: null,
            isFiltering: false,
          },
          {
            code: "c15",
            name: "Commander 2015",
            released_at: "2015-11-13",
            svg: null,
            isFiltering: false,
          },
          {
            code: "c14",
            name: "Commander 2014",
            released_at: "2014-11-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "c13",
            name: "Commander 2013",
            released_at: "2013-11-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cm1",
            name: "Commander's Arsenal",
            released_at: "2012-11-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cmd",
            name: "Commander 2011",
            released_at: "2011-06-17",
            svg: null,
            isFiltering: false,
          },
        ],
      },
      /*       {
        main: "masterpiece",
        sub: [
          {
            code: "sta",
            name: "Strixhaven Mystical Archive",
            released_at: "2021-04-23",
            svg: null,
            isFiltering: false,
          },
          {
            code: "zne",
            name: "Zendikar Rising Expeditions",
            released_at: "2020-09-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "puma",
            name: "Ultimate Box Topper",
            released_at: "2018-12-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "med",
            name: "Mythic Edition",
            released_at: "2018-10-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "mp2",
            name: "Amonkhet Invocations",
            released_at: "2017-04-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "mps",
            name: "Kaladesh Inventions",
            released_at: "2016-09-30",
            svg: null,
            isFiltering: false,
          },
          {
            code: "exp",
            name: "Zendikar Expeditions",
            released_at: "2015-10-02",
            svg: null,
            isFiltering: false,
          },
        ],
      }, */
      /*       {
        main: "masters",
        sub: [
          {
            code: "tsr",
            name: "Time Spiral Remastered",
            released_at: "2021-03-19",
            svg: null,
            isFiltering: false,
          },
          {
            code: "klr",
            name: "Kaladesh Remastered",
            released_at: "2020-11-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "plist",
            name: "The List",
            released_at: "2020-09-26",
            svg: null,
            isFiltering: false,
          },
          {
            code: "akr",
            name: "Amonkhet Remastered",
            released_at: "2020-08-13",
            svg: null,
            isFiltering: false,
          },
          {
            code: "2xm",
            name: "Double Masters",
            released_at: "2020-08-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "fmb1",
            name: "Mystery Booster Retail Edition Foils",
            released_at: "2020-03-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "mb1",
            name: "Mystery Booster",
            released_at: "2019-11-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "uma",
            name: "Ultimate Masters",
            released_at: "2018-12-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "a25",
            name: "Masters 25",
            released_at: "2018-03-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ima",
            name: "Iconic Masters",
            released_at: "2017-11-17",
            svg: null,
            isFiltering: false,
          },
          {
            code: "mm3",
            name: "Modern Masters 2017",
            released_at: "2017-03-17",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ema",
            name: "Eternal Masters",
            released_at: "2016-06-10",
            svg: null,
            isFiltering: false,
          },
          {
            code: "mm2",
            name: "Modern Masters 2015",
            released_at: "2015-05-22",
            svg: null,
            isFiltering: false,
          },
          {
            code: "tpr",
            name: "Tempest Remastered",
            released_at: "2015-05-06",
            svg: null,
            isFiltering: false,
          },
          {
            code: "vma",
            name: "Vintage Masters",
            released_at: "2014-06-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "mma",
            name: "Modern Masters",
            released_at: "2013-06-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "me4",
            name: "Masters Edition IV",
            released_at: "2011-01-10",
            svg: null,
            isFiltering: false,
          },
          {
            code: "me3",
            name: "Masters Edition III",
            released_at: "2009-09-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "me2",
            name: "Masters Edition II",
            released_at: "2008-09-22",
            svg: null,
            isFiltering: false,
          },
          {
            code: "me1",
            name: "Masters Edition",
            released_at: "2007-09-10",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ren",
            name: "Renaissance",
            released_at: "1995-08-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "rin",
            name: "Rinascimento",
            released_at: "1995-08-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "chr",
            name: "Chronicles",
            released_at: "1995-07-01",
            svg: null,
            isFiltering: false,
          },
        ],
      }, */
      /*       {
        main: "box",
        sub: [
          {
            code: "ha4",
            name: "Historic Anthology 4",
            released_at: "2021-03-11",
            svg: null,
            isFiltering: false,
          },
          {
            code: "slu",
            name: "Secret Lair: Ultimate Edition",
            released_at: "2020-05-29",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ha3",
            name: "Historic Anthology 3",
            released_at: "2020-05-21",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ha2",
            name: "Historic Anthology 2",
            released_at: "2020-03-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "sld",
            name: "Secret Lair Drop",
            released_at: "2019-12-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ha1",
            name: "Historic Anthology 1",
            released_at: "2019-11-21",
            svg: null,
            isFiltering: false,
          },
          {
            code: "gn2",
            name: "Game Night 2019",
            released_at: "2019-11-15",
            svg: null,
            isFiltering: false,
          },
          {
            code: "gk2",
            name: "RNA Guild Kit",
            released_at: "2019-02-15",
            svg: null,
            isFiltering: false,
          },
          {
            code: "gnt",
            name: "Game Night",
            released_at: "2018-11-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g18",
            name: "M19 Gift Pack",
            released_at: "2018-11-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "gk1",
            name: "GRN Guild Kit",
            released_at: "2018-11-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "e02",
            name: "Explorers of Ixalan",
            released_at: "2017-11-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g17",
            name: "2017 Gift Pack",
            released_at: "2017-10-20",
            svg: null,
            isFiltering: false,
          },
          {
            code: "md1",
            name: "Modern Event Deck 2014",
            released_at: "2014-05-30",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ps11",
            name: "Salvat 2011",
            released_at: "2011-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "td0",
            name: "Magic Online Theme Decks",
            released_at: "2010-11-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "dpa",
            name: "Duels of the Planeswalkers",
            released_at: "2010-06-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cst",
            name: "Coldsnap Theme Decks",
            released_at: "2006-07-21",
            svg: null,
            isFiltering: false,
          },
          {
            code: "phuk",
            name: "Hachette UK",
            released_at: "2006-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "psal",
            name: "Salvat 2005",
            released_at: "2005-08-22",
            svg: null,
            isFiltering: false,
          },
          {
            code: "dkm",
            name: "Deckmasters",
            released_at: "2001-12-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "psdg",
            name: "Sega Dreamcast Cards",
            released_at: "2001-06-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "btd",
            name: "Beatdown Box Set",
            released_at: "2000-10-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "brb",
            name: "Battle Royale Box Set",
            released_at: "1999-11-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ath",
            name: "Anthologies",
            released_at: "1998-11-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "past",
            name: "Astral Cards",
            released_at: "1997-04-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "mgb",
            name: "Multiverse Gift Box",
            released_at: "1996-11-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "rqs",
            name: "Rivals Quick Start Set",
            released_at: "1996-07-01",
            svg: null,
            isFiltering: false,
          },
        ],
      }, */
      /*       {
        main: "promo",
        sub: [
          {
            code: "pkhc",
            name: "Kaldheim Commander Promos",
            released_at: "2021-02-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pkhm",
            name: "Kaldheim Promos",
            released_at: "2021-02-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pl21",
            name: "2021 Lunar New Year",
            released_at: "2021-01-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j21",
            name: "Judge Gift Cards 2021",
            released_at: "2021-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pznr",
            name: "Zendikar Rising Promos",
            released_at: "2020-09-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pm21",
            name: "Core Set 2021 Promos",
            released_at: "2020-07-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "plgs",
            name: "Love Your LGS",
            released_at: "2020-05-18",
            svg: null,
            isFiltering: false,
          },
          {
            code: "piko",
            name: "Ikoria: Lair of Behemoths Promos",
            released_at: "2020-04-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pthb",
            name: "Theros Beyond Death Promos",
            released_at: "2020-01-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pf20",
            name: "MagicFest 2020",
            released_at: "2020-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j20",
            name: "Judge Gift Cards 2020",
            released_at: "2020-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "peld",
            name: "Throne of Eldraine Promos",
            released_at: "2019-10-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ps19",
            name: "San Diego Comic-Con 2019",
            released_at: "2019-07-18",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pm20",
            name: "Core Set 2020 Promos",
            released_at: "2019-07-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ppp1",
            name: "M20 Promo Packs",
            released_at: "2019-07-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmh1",
            name: "Modern Horizons Promos",
            released_at: "2019-06-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwar",
            name: "War of the Spark Promos",
            released_at: "2019-05-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j19",
            name: "Judge Gift Cards 2019",
            released_at: "2019-04-10",
            svg: null,
            isFiltering: false,
          },
          {
            code: "prw2",
            name: "RNA Ravnica Weekend",
            released_at: "2019-02-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "prna",
            name: "Ravnica Allegiance Promos",
            released_at: "2019-01-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pf19",
            name: "MagicFest 2019",
            released_at: "2019-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "prwk",
            name: "GRN Ravnica Weekend",
            released_at: "2018-11-09",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pgrn",
            name: "Guilds of Ravnica Promos",
            released_at: "2018-10-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ps18",
            name: "San Diego Comic-Con 2018",
            released_at: "2018-07-19",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pana",
            name: "MTG Arena Promos",
            released_at: "2018-07-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pm19",
            name: "Core Set 2019 Promos",
            released_at: "2018-07-13",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pss3",
            name: "M19 Standard Showdown",
            released_at: "2018-07-13",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pbbd",
            name: "Battlebond Promos",
            released_at: "2018-06-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdom",
            name: "Dominaria Promos",
            released_at: "2018-04-27",
            svg: null,
            isFiltering: false,
          },
          {
            code: "plny",
            name: "2018 Lunar New Year",
            released_at: "2018-02-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pnat",
            name: "Nationals Promos",
            released_at: "2018-01-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "prix",
            name: "Rivals of Ixalan Promos",
            released_at: "2018-01-19",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j18",
            name: "Judge Gift Cards 2018",
            released_at: "2018-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f18",
            name: "Friday Night Magic 2018",
            released_at: "2017-12-27",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pust",
            name: "Unstable Promos",
            released_at: "2017-12-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pxtc",
            name: "XLN Treasure Chest",
            released_at: "2017-11-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pxln",
            name: "Ixalan Promos",
            released_at: "2017-09-29",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pss2",
            name: "XLN Standard Showdown",
            released_at: "2017-09-29",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ps17",
            name: "San Diego Comic-Con 2017",
            released_at: "2017-07-20",
            svg: null,
            isFiltering: false,
          },
          {
            code: "phou",
            name: "Hour of Devastation Promos",
            released_at: "2017-07-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pakh",
            name: "Amonkhet Promos",
            released_at: "2017-04-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "paer",
            name: "Aether Revolt Promos",
            released_at: "2017-01-20",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f17",
            name: "Friday Night Magic 2017",
            released_at: "2017-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j17",
            name: "Judge Gift Cards 2017",
            released_at: "2017-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ps16",
            name: "San Diego Comic-Con 2016",
            released_at: "2016-10-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pkld",
            name: "Kaladesh Promos",
            released_at: "2016-09-30",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pemn",
            name: "Eldritch Moon Promos",
            released_at: "2016-07-22",
            svg: null,
            isFiltering: false,
          },
          {
            code: "psoi",
            name: "Shadows over Innistrad Promos",
            released_at: "2016-04-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pogw",
            name: "Oath of the Gatewatch Promos",
            released_at: "2016-01-22",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f16",
            name: "Friday Night Magic 2016",
            released_at: "2016-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j16",
            name: "Judge Gift Cards 2016",
            released_at: "2016-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pbfz",
            name: "Battle for Zendikar Promos",
            released_at: "2015-10-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pss1",
            name: "BFZ Standard Series",
            released_at: "2015-10-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pori",
            name: "Magic Origins Promos",
            released_at: "2015-07-17",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ps15",
            name: "San Diego Comic-Con 2015",
            released_at: "2015-07-09",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ptkdf",
            name: "Tarkir Dragonfury",
            released_at: "2015-04-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdtk",
            name: "Dragons of Tarkir Promos",
            released_at: "2015-03-27",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pfrf",
            name: "Fate Reforged Promos",
            released_at: "2015-01-23",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ugin",
            name: "Ugin's Fate",
            released_at: "2015-01-17",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j15",
            name: "Judge Gift Cards 2015",
            released_at: "2015-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f15",
            name: "Friday Night Magic 2015",
            released_at: "2015-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pktk",
            name: "Khans of Tarkir Promos",
            released_at: "2014-09-26",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pm15",
            name: "Magic 2015 Promos",
            released_at: "2014-07-17",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ps14",
            name: "San Diego Comic-Con 2014",
            released_at: "2014-07-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pcns",
            name: "Conspiracy Promos",
            released_at: "2014-06-06",
            svg: null,
            isFiltering: false,
          },
          {
            code: "thp3",
            name: "Journey into Nyx Hero's Path",
            released_at: "2014-05-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pjou",
            name: "Journey into Nyx Promos",
            released_at: "2014-04-26",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pbng",
            name: "Born of the Gods Promos",
            released_at: "2014-02-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdp15",
            name: "Duels of the Planeswalkers 2015 Promos ",
            released_at: "2014-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pi14",
            name: "IDW Comics 2014",
            released_at: "2014-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f14",
            name: "Friday Night Magic 2014",
            released_at: "2014-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j14",
            name: "Judge Gift Cards 2014",
            released_at: "2014-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pths",
            name: "Theros Promos",
            released_at: "2013-09-21",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pm14",
            name: "Magic 2014 Promos",
            released_at: "2013-07-18",
            svg: null,
            isFiltering: false,
          },
          {
            code: "psdc",
            name: "San Diego Comic-Con 2013",
            released_at: "2013-07-18",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdgm",
            name: "Dragon's Maze Promos",
            released_at: "2013-04-27",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwcq",
            name: "World Magic Cup Qualifiers",
            released_at: "2013-04-06",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pgtc",
            name: "Gatecrash Promos",
            released_at: "2013-01-26",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdp14",
            name: "Duels of the Planeswalkers 2014 Promos ",
            released_at: "2013-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pi13",
            name: "IDW Comics 2013",
            released_at: "2013-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j13",
            name: "Judge Gift Cards 2013",
            released_at: "2013-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f13",
            name: "Friday Night Magic 2013",
            released_at: "2013-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "prtr",
            name: "Return to Ravnica Promos",
            released_at: "2012-10-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pm13",
            name: "Magic 2013 Promos",
            released_at: "2012-07-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "phel",
            name: "Open the Helvault",
            released_at: "2012-04-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pavr",
            name: "Avacyn Restored Promos",
            released_at: "2012-04-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdka",
            name: "Dark Ascension Promos",
            released_at: "2012-01-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pidw",
            name: "IDW Comics 2012",
            released_at: "2012-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwp12",
            name: "Wizards Play Network 2012",
            released_at: "2012-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdp13",
            name: "Duels of the Planeswalkers 2013 Promos ",
            released_at: "2012-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f12",
            name: "Friday Night Magic 2012",
            released_at: "2012-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "j12",
            name: "Judge Gift Cards 2012",
            released_at: "2012-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pisd",
            name: "Innistrad Promos",
            released_at: "2011-09-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pm12",
            name: "Magic 2012 Promos",
            released_at: "2011-07-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pnph",
            name: "New Phyrexia Promos",
            released_at: "2011-05-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmbs",
            name: "Mirrodin Besieged Promos",
            released_at: "2011-02-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmps11",
            name: "Magic Premiere Shop 2011",
            released_at: "2011-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdp12",
            name: "Duels of the Planeswalkers 2012 Promos ",
            released_at: "2011-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwp11",
            name: "Wizards Play Network 2011",
            released_at: "2011-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p11",
            name: "Magic Player Rewards 2011",
            released_at: "2011-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f11",
            name: "Friday Night Magic 2011",
            released_at: "2011-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g11",
            name: "Judge Gift Cards 2011",
            released_at: "2011-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "psom",
            name: "Scars of Mirrodin Promos",
            released_at: "2010-09-30",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pm11",
            name: "Magic 2011 Promos",
            released_at: "2010-07-15",
            svg: null,
            isFiltering: false,
          },
          {
            code: "proe",
            name: "Rise of the Eldrazi Promos",
            released_at: "2010-04-23",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwwk",
            name: "Worldwake Promos",
            released_at: "2010-02-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdp10",
            name: "Duels of the Planeswalkers 2010 Promos ",
            released_at: "2010-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwp10",
            name: "Wizards Play Network 2010",
            released_at: "2010-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmps10",
            name: "Magic Premiere Shop 2010",
            released_at: "2010-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g10",
            name: "Judge Gift Cards 2010",
            released_at: "2010-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f10",
            name: "Friday Night Magic 2010",
            released_at: "2010-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p10",
            name: "Magic Player Rewards 2010",
            released_at: "2010-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pzen",
            name: "Zendikar Promos",
            released_at: "2009-10-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "phop",
            name: "Promotional Planes",
            released_at: "2009-09-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pm10",
            name: "Magic 2010 Promos",
            released_at: "2009-07-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "parb",
            name: "Alara Reborn Promos",
            released_at: "2009-04-30",
            svg: null,
            isFiltering: false,
          },
          {
            code: "purl",
            name: "URL/Convention Promos",
            released_at: "2009-02-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pcon",
            name: "Conflux Promos",
            released_at: "2009-02-06",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pbook",
            name: "Miscellaneous Book Promos",
            released_at: "2009-01-27",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwp09",
            name: "Wizards Play Network 2009",
            released_at: "2009-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdtp",
            name: "Duels of the Planeswalkers 2009 Promos ",
            released_at: "2009-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmps09",
            name: "Magic Premiere Shop 2009",
            released_at: "2009-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p09",
            name: "Magic Player Rewards 2009",
            released_at: "2009-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g09",
            name: "Judge Gift Cards 2009",
            released_at: "2009-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f09",
            name: "Friday Night Magic 2009",
            released_at: "2009-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pala",
            name: "Shards of Alara Promos",
            released_at: "2008-10-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwpn",
            name: "Wizards Play Network 2008",
            released_at: "2008-10-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "peve",
            name: "Eventide Promos",
            released_at: "2008-07-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pshm",
            name: "Shadowmoor Promos",
            released_at: "2008-05-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p15a",
            name: "15th Anniversary Cards",
            released_at: "2008-04-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmor",
            name: "Morningtide Promos",
            released_at: "2008-02-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmps08",
            name: "Magic Premiere Shop 2008",
            released_at: "2008-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pg08",
            name: "Gateway 2008",
            released_at: "2008-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p08",
            name: "Magic Player Rewards 2008",
            released_at: "2008-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g08",
            name: "Judge Gift Cards 2008",
            released_at: "2008-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f08",
            name: "Friday Night Magic 2008",
            released_at: "2008-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "plrw",
            name: "Lorwyn Promos",
            released_at: "2007-10-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "psum",
            name: "Summer of Magic",
            released_at: "2007-07-21",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p10e",
            name: "Tenth Edition Promos",
            released_at: "2007-07-13",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pfut",
            name: "Future Sight Promos",
            released_at: "2007-05-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pgpx",
            name: "Grand Prix Promos",
            released_at: "2007-02-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ppro",
            name: "Pro Tour Promos",
            released_at: "2007-02-09",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pplc",
            name: "Planar Chaos Promos",
            released_at: "2007-02-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pres",
            name: "Resale Promos",
            released_at: "2007-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pg07",
            name: "Gateway 2007",
            released_at: "2007-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmps07",
            name: "Magic Premiere Shop 2007",
            released_at: "2007-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p07",
            name: "Magic Player Rewards 2007",
            released_at: "2007-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f07",
            name: "Friday Night Magic 2007",
            released_at: "2007-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g07",
            name: "Judge Gift Cards 2007",
            released_at: "2007-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ptsp",
            name: "Time Spiral Promos",
            released_at: "2006-10-06",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pcsp",
            name: "Coldsnap Promos",
            released_at: "2006-07-21",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdis",
            name: "Dissension Promos",
            released_at: "2006-05-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pcmp",
            name: "Champs and States",
            released_at: "2006-03-18",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pgpt",
            name: "Guildpact Promos",
            released_at: "2006-02-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pal06",
            name: "Arena League 2006",
            released_at: "2006-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmps06",
            name: "Magic Premiere Shop 2006",
            released_at: "2006-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pjas",
            name: "Junior APAC Series",
            released_at: "2006-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g06",
            name: "Judge Gift Cards 2006",
            released_at: "2006-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f06",
            name: "Friday Night Magic 2006",
            released_at: "2006-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p06",
            name: "Magic Player Rewards 2006",
            released_at: "2006-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pgtw",
            name: "Gateway 2006",
            released_at: "2006-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p2hg",
            name: "Two-Headed Giant Tournament",
            released_at: "2005-12-09",
            svg: null,
            isFiltering: false,
          },
          {
            code: "prav",
            name: "Ravnica: City of Guilds Promos",
            released_at: "2005-10-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p9ed",
            name: "Ninth Edition Promos",
            released_at: "2005-07-29",
            svg: null,
            isFiltering: false,
          },
          {
            code: "psok",
            name: "Saviors of Kamigawa Promos",
            released_at: "2005-06-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pbok",
            name: "Betrayers of Kamigawa Promos",
            released_at: "2005-02-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmps",
            name: "Magic Premiere Shop 2005",
            released_at: "2005-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pal05",
            name: "Arena League 2005",
            released_at: "2005-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pjse",
            name: "Junior Series Europe",
            released_at: "2005-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g05",
            name: "Judge Gift Cards 2005",
            released_at: "2005-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p05",
            name: "Magic Player Rewards 2005",
            released_at: "2005-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f05",
            name: "Friday Night Magic 2005",
            released_at: "2005-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "punh",
            name: "Unhinged Promos",
            released_at: "2004-11-19",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pchk",
            name: "Champions of Kamigawa Promos",
            released_at: "2004-10-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p5dn",
            name: "Fifth Dawn Promos",
            released_at: "2004-06-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmrd",
            name: "Mirrodin Promos",
            released_at: "2004-06-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdst",
            name: "Darksteel Promos",
            released_at: "2004-06-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pal04",
            name: "Arena League 2004",
            released_at: "2004-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f04",
            name: "Friday Night Magic 2004",
            released_at: "2004-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p04",
            name: "Magic Player Rewards 2004",
            released_at: "2004-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g04",
            name: "Judge Gift Cards 2004",
            released_at: "2004-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p8ed",
            name: "Eighth Edition Promos",
            released_at: "2003-07-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pscg",
            name: "Scourge Promos",
            released_at: "2003-05-26",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pons",
            name: "Onslaught Promos",
            released_at: "2003-02-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "plgn",
            name: "Legions Promos",
            released_at: "2003-02-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pjjt",
            name: "Japan Junior Tournament",
            released_at: "2003-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pal03",
            name: "Arena League 2003",
            released_at: "2003-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f03",
            name: "Friday Night Magic 2003",
            released_at: "2003-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g03",
            name: "Judge Gift Cards 2003",
            released_at: "2003-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p03",
            name: "Magic Player Rewards 2003",
            released_at: "2003-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "phj",
            name: "Hobby Japan Promos",
            released_at: "2002-07-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "prm",
            name: "Magic Online Promos",
            released_at: "2002-06-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pjud",
            name: "Judgment Promos",
            released_at: "2002-05-27",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ptor",
            name: "Torment Promos",
            released_at: "2002-02-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pal02",
            name: "Arena League 2002",
            released_at: "2002-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pr2",
            name: "Magic Player Rewards 2002",
            released_at: "2002-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f02",
            name: "Friday Night Magic 2002",
            released_at: "2002-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g02",
            name: "Judge Gift Cards 2002",
            released_at: "2002-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pody",
            name: "Odyssey Promos",
            released_at: "2001-10-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "papc",
            name: "Apocalypse Promos",
            released_at: "2001-06-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ppls",
            name: "Planeshift Promos",
            released_at: "2001-02-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pal01",
            name: "Arena League 2001",
            released_at: "2001-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "mpr",
            name: "Magic Player Rewards 2001",
            released_at: "2001-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "f01",
            name: "Friday Night Magic 2001",
            released_at: "2001-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g01",
            name: "Judge Gift Cards 2001",
            released_at: "2001-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pinv",
            name: "Invasion Promos",
            released_at: "2000-10-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ppcy",
            name: "Prophecy Promos",
            released_at: "2000-06-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pnem",
            name: "Nemesis Promos",
            released_at: "2000-02-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pelp",
            name: "European Land Program",
            released_at: "2000-02-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pal00",
            name: "Arena League 2000",
            released_at: "2000-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "fnm",
            name: "Friday Night Magic 2000",
            released_at: "2000-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g00",
            name: "Judge Gift Cards 2000",
            released_at: "2000-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "psus",
            name: "Junior Super Series",
            released_at: "1999-12-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmmq",
            name: "Mercadian Masques Promos",
            released_at: "1999-10-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwos",
            name: "Wizards of the Coast Online Store",
            released_at: "1999-09-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pwor",
            name: "World Championship Promos",
            released_at: "1999-08-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pgru",
            name: "Guru",
            released_at: "1999-07-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pptk",
            name: "Portal: Three Kingdoms Promos",
            released_at: "1999-07-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "puds",
            name: "Urza's Destiny Promos",
            released_at: "1999-06-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pulg",
            name: "Urza's Legacy Promos",
            released_at: "1999-02-15",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pal99",
            name: "Arena League 1999",
            released_at: "1999-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "g99",
            name: "Judge Gift Cards 1999",
            released_at: "1999-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pusg",
            name: "Urza's Saga Promos",
            released_at: "1998-10-12",
            svg: null,
            isFiltering: false,
          },
          {
            code: "palp",
            name: "Asia Pacific Land Program",
            released_at: "1998-09-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pexo",
            name: "Exodus Promos",
            released_at: "1998-06-15",
            svg: null,
            isFiltering: false,
          },
          {
            code: "psth",
            name: "Stronghold Promos",
            released_at: "1998-03-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "jgp",
            name: "Judge Gift Cards 1998",
            released_at: "1998-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ptmp",
            name: "Tempest Promos",
            released_at: "1997-10-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pred",
            name: "Redemption Program",
            released_at: "1996-10-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "parl",
            name: "Arena League 1996",
            released_at: "1996-08-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pmei",
            name: "Magazine Inserts",
            released_at: "1995-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "plgm",
            name: "DCI Legend Membership",
            released_at: "1995-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "phpr",
            name: "HarperPrism Book Promos",
            released_at: "1994-09-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pdrc",
            name: "Dragon Con",
            released_at: "1994-07-15",
            svg: null,
            isFiltering: false,
          },
        ],
      }, */
      /*       {
        main: "from_the_vault",
        sub: [
          {
            code: "cc1",
            name: "Commander Collection: Green",
            released_at: "2020-12-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "v17",
            name: "From the Vault: Transform",
            released_at: "2017-11-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "v16",
            name: "From the Vault: Lore",
            released_at: "2016-08-19",
            svg: null,
            isFiltering: false,
          },
          {
            code: "v15",
            name: "From the Vault: Angels",
            released_at: "2015-08-21",
            svg: null,
            isFiltering: false,
          },
          {
            code: "v14",
            name: "From the Vault: Annihilation",
            released_at: "2014-08-22",
            svg: null,
            isFiltering: false,
          },
          {
            code: "v13",
            name: "From the Vault: Twenty",
            released_at: "2013-08-23",
            svg: null,
            isFiltering: false,
          },
          {
            code: "v12",
            name: "From the Vault: Realms",
            released_at: "2012-08-31",
            svg: null,
            isFiltering: false,
          },
          {
            code: "v11",
            name: "From the Vault: Legends",
            released_at: "2011-08-26",
            svg: null,
            isFiltering: false,
          },
          {
            code: "v10",
            name: "From the Vault: Relics",
            released_at: "2010-08-27",
            svg: null,
            isFiltering: false,
          },
          {
            code: "v09",
            name: "From the Vault: Exiled",
            released_at: "2009-08-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "drb",
            name: "From the Vault: Dragons",
            released_at: "2008-08-29",
            svg: null,
            isFiltering: false,
          },
        ],
      }, */
      {
        main: "draft_innovation",
        sub: [
          {
            code: "mh2",
            name: "Modern Horizons 2",
            released_at: "2021-06-18",
            svg: svgmh2,
            isFiltering: false,
          },
          {
            code: "cmr",
            name: "Commander Legends",
            released_at: "2020-11-20",
            svg: null,
            isFiltering: false,
          },
          {
            code: "jmp",
            name: "Jumpstart",
            released_at: "2020-07-17",
            svg: null,
            isFiltering: false,
          },
          {
            code: "mh1",
            name: "Modern Horizons",
            released_at: "2019-06-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "bbd",
            name: "Battlebond",
            released_at: "2018-06-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cn2",
            name: "Conspiracy: Take the Crown",
            released_at: "2016-08-26",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cns",
            name: "Conspiracy",
            released_at: "2014-06-06",
            svg: null,
            isFiltering: false,
          },
        ],
      },
      {
        main: "starter",
        sub: [
          {
            code: "anb",
            name: "Arena Beginner Set",
            released_at: "2020-08-13",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ajmp",
            name: "Jumpstart Arena Exclusives",
            released_at: "2020-07-17",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ana",
            name: "Arena New Player Experience",
            released_at: "2018-07-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "oana",
            name: "Arena New Player Experience Cards",
            released_at: "2018-07-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "w17",
            name: "Welcome Deck 2017",
            released_at: "2017-04-15",
            svg: null,
            isFiltering: false,
          },
          {
            code: "w16",
            name: "Welcome Deck 2016",
            released_at: "2016-04-08",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cp3",
            name: "Magic Origins Clash Pack",
            released_at: "2015-07-17",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cp2",
            name: "Fate Reforged Clash Pack",
            released_at: "2015-01-23",
            svg: null,
            isFiltering: false,
          },
          {
            code: "cp1",
            name: "Magic 2015 Clash Pack",
            released_at: "2014-07-18",
            svg: null,
            isFiltering: false,
          },
          {
            code: "s00",
            name: "Starter 2000",
            released_at: "2000-04-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ptk",
            name: "Portal Three Kingdoms",
            released_at: "1999-07-06",
            svg: null,
            isFiltering: false,
          },
          {
            code: "s99",
            name: "Starter 1999",
            released_at: "1999-07-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "p02",
            name: "Portal Second Age",
            released_at: "1998-06-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "por",
            name: "Portal",
            released_at: "1997-05-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ppod",
            name: "Portal Demo Game",
            released_at: "1997-05-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "itp",
            name: "Introductory Two-Player Set",
            released_at: "1996-12-31",
            svg: null,
            isFiltering: false,
          },
        ],
      },
      /*       {
        main: "spellbook",
        sub: [
          {
            code: "ss3",
            name: "Signature Spellbook: Chandra",
            released_at: "2020-06-26",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ss2",
            name: "Signature Spellbook: Gideon",
            released_at: "2019-06-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ss1",
            name: "Signature Spellbook: Jace",
            released_at: "2018-06-15",
            svg: null,
            isFiltering: false,
          },
        ],
      }, */
      {
        main: "planechase",
        sub: [
          {
            code: "opca",
            name: "Planechase Anthology Planes",
            released_at: "2018-12-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pca",
            name: "Planechase Anthology",
            released_at: "2016-11-25",
            svg: null,
            isFiltering: false,
          },
          {
            code: "opc2",
            name: "Planechase 2012 Planes",
            released_at: "2012-06-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pc2",
            name: "Planechase 2012",
            released_at: "2012-06-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ohop",
            name: "Planechase Planes",
            released_at: "2009-09-04",
            svg: null,
            isFiltering: false,
          },
          {
            code: "hop",
            name: "Planechase",
            released_at: "2009-09-04",
            svg: null,
            isFiltering: false,
          },
        ],
      },
      {
        main: "duel_deck",
        sub: [
          {
            code: "gs1",
            name: "Global Series Jiang Yanggu & Mu Yanling",
            released_at: "2018-06-22",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddu",
            name: "Duel Decks: Elves vs. Inventors",
            released_at: "2018-04-06",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddt",
            name: "Duel Decks: Merfolk vs. Goblins",
            released_at: "2017-10-24",
            svg: null,
            isFiltering: false,
          },
          {
            code: "dds",
            name: "Duel Decks: Mind vs. Might",
            released_at: "2017-03-31",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddr",
            name: "Duel Decks: Nissa vs. Ob Nixilis",
            released_at: "2016-09-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddq",
            name: "Duel Decks: Blessed vs. Cursed",
            released_at: "2016-02-26",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddp",
            name: "Duel Decks: Zendikar vs. Eldrazi",
            released_at: "2015-08-28",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddo",
            name: "Duel Decks: Elspeth vs. Kiora",
            released_at: "2015-02-27",
            svg: null,
            isFiltering: false,
          },
          {
            code: "dvd",
            name: "Duel Decks Anthology: Divine vs. Demonic",
            released_at: "2014-12-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "evg",
            name: "Duel Decks Anthology: Elves vs. Goblins",
            released_at: "2014-12-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "jvc",
            name: "Duel Decks Anthology: Jace vs. Chandra",
            released_at: "2014-12-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "gvl",
            name: "Duel Decks Anthology: Garruk vs. Liliana",
            released_at: "2014-12-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddn",
            name: "Duel Decks: Speed vs. Cunning",
            released_at: "2014-09-05",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddm",
            name: "Duel Decks: Jace vs. Vraska",
            released_at: "2014-03-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddl",
            name: "Duel Decks: Heroes vs. Monsters",
            released_at: "2013-09-06",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddk",
            name: "Duel Decks: Sorin vs. Tibalt",
            released_at: "2013-03-15",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddj",
            name: "Duel Decks: Izzet vs. Golgari",
            released_at: "2012-09-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddi",
            name: "Duel Decks: Venser vs. Koth",
            released_at: "2012-03-30",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddh",
            name: "Duel Decks: Ajani vs. Nicol Bolas",
            released_at: "2011-09-02",
            svg: null,
            isFiltering: false,
          },
          {
            code: "td2",
            name: "Duel Decks: Mirrodin Pure vs. New Phyrexia",
            released_at: "2011-05-14",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddg",
            name: "Duel Decks: Knights vs. Dragons",
            released_at: "2011-04-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddf",
            name: "Duel Decks: Elspeth vs. Tezzeret",
            released_at: "2010-09-03",
            svg: null,
            isFiltering: false,
          },
          {
            code: "dde",
            name: "Duel Decks: Phyrexia vs. the Coalition",
            released_at: "2010-03-19",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddd",
            name: "Duel Decks: Garruk vs. Liliana",
            released_at: "2009-10-30",
            svg: null,
            isFiltering: false,
          },
          {
            code: "ddc",
            name: "Duel Decks: Divine vs. Demonic",
            released_at: "2009-04-10",
            svg: null,
            isFiltering: false,
          },
          {
            code: "dd2",
            name: "Duel Decks: Jace vs. Chandra",
            released_at: "2008-11-07",
            svg: null,
            isFiltering: false,
          },
          {
            code: "dd1",
            name: "Duel Decks: Elves vs. Goblins",
            released_at: "2007-11-16",
            svg: null,
            isFiltering: false,
          },
        ],
      },
      {
        main: "archenemy",
        sub: [
          {
            code: "oe01",
            name: "Archenemy: Nicol Bolas Schemes",
            released_at: "2017-06-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "e01",
            name: "Archenemy: Nicol Bolas",
            released_at: "2017-06-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "oarc",
            name: "Archenemy Schemes",
            released_at: "2010-06-18",
            svg: null,
            isFiltering: false,
          },
          {
            code: "arc",
            name: "Archenemy",
            released_at: "2010-06-18",
            svg: null,
            isFiltering: false,
          },
          {
            code: "parc",
            name: "Promotional Schemes",
            released_at: "2010-06-18",
            svg: null,
            isFiltering: false,
          },
        ],
      },
      {
        main: "treasure_chest",
        sub: [
          {
            code: "pz2",
            name: "Treasure Chest",
            released_at: "2016-11-16",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pz1",
            name: "Legendary Cube Prize Pack",
            released_at: "2015-11-18",
            svg: null,
            isFiltering: false,
          },
        ],
      },
      /*       {
        main: "premium_deck",
        sub: [
          {
            code: "pd3",
            name: "Premium Deck Series: Graveborn",
            released_at: "2011-11-18",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pd2",
            name: "Premium Deck Series: Fire and Lightning",
            released_at: "2010-11-19",
            svg: null,
            isFiltering: false,
          },
          {
            code: "h09",
            name: "Premium Deck Series: Slivers",
            released_at: "2009-11-20",
            svg: null,
            isFiltering: false,
          },
        ],
      }, */
      {
        main: "vanguard",
        sub: [
          {
            code: "pmoa",
            name: "Magic Online Avatars",
            released_at: "2003-01-01",
            svg: null,
            isFiltering: false,
          },
          {
            code: "pvan",
            name: "Vanguard Series",
            released_at: "1997-05-01",
            svg: null,
            isFiltering: false,
          },
        ],
      },
    ],
    keywords_search_mode: "one of these",
    keywords: [
      {
        main: "Keyword Abilities",
        sub: [
          { code: "Absorb", isFiltering: false },
          { code: "Affinity", isFiltering: false },
          { code: "Afflict", isFiltering: false },
          { code: "Afterlife", isFiltering: false },
          { code: "Aftermath", isFiltering: false },
          { code: "Amplify", isFiltering: false },
          { code: "Annihilator", isFiltering: false },
          { code: "Ascend", isFiltering: false },
          { code: "Assist", isFiltering: false },
          { code: "Augment", isFiltering: false },
          { code: "Aura Swap", isFiltering: false },
          { code: "Awaken", isFiltering: false },
          { code: "Banding", isFiltering: false },
          { code: "Basic landcycling", isFiltering: false },
          { code: "Battle Cry", isFiltering: false },
          { code: "Bestow", isFiltering: false },
          { code: "Bloodthirst", isFiltering: false },
          { code: "Boast", isFiltering: false },
          { code: "Bushido", isFiltering: false },
          { code: "Buyback", isFiltering: false },
          { code: "Cascade", isFiltering: false },
          { code: "Champion", isFiltering: false },
          { code: "Changeling", isFiltering: false },
          { code: "Cipher", isFiltering: false },
          { code: "Commander ninjutsu", isFiltering: false },
          { code: "Companion", isFiltering: false },
          { code: "Conspire", isFiltering: false },
          { code: "Convoke", isFiltering: false },
          { code: "Crew", isFiltering: false },
          { code: "Cumulative upkeep", isFiltering: false },
          { code: "Cycling", isFiltering: false },
          { code: "Dash", isFiltering: false },
          { code: "Deathtouch", isFiltering: false },
          { code: "Defender", isFiltering: false },
          { code: "Delve", isFiltering: false },
          { code: "Demonstrate", isFiltering: false },
          { code: "Desertwalk", isFiltering: false },
          { code: "Dethrone", isFiltering: false },
          { code: "Devoid", isFiltering: false },
          { code: "Devour", isFiltering: false },
          { code: "Double strike", isFiltering: false },
          { code: "Dredge", isFiltering: false },
          { code: "Echo", isFiltering: false },
          { code: "Embalm", isFiltering: false },
          { code: "Emerge", isFiltering: false },
          { code: "Enchant", isFiltering: false },
          { code: "Encore", isFiltering: false },
          { code: "Entwine", isFiltering: false },
          { code: "Epic", isFiltering: false },
          { code: "Equip", isFiltering: false },
          { code: "Escalate", isFiltering: false },
          { code: "Escape", isFiltering: false },
          { code: "Eternalize", isFiltering: false },
          { code: "Evoke", isFiltering: false },
          { code: "Evolve", isFiltering: false },
          { code: "Exalted", isFiltering: false },
          { code: "Exploit", isFiltering: false },
          { code: "Extort", isFiltering: false },
          { code: "Fabricate", isFiltering: false },
          { code: "Fading", isFiltering: false },
          { code: "Fear", isFiltering: false },
          { code: "First strike", isFiltering: false },
          { code: "Flanking", isFiltering: false },
          { code: "Flash", isFiltering: false },
          { code: "Flashback", isFiltering: false },
          { code: "Flying", isFiltering: false },
          { code: "Forecast", isFiltering: false },
          { code: "Forestcycling", isFiltering: false },
          { code: "Forestwalk", isFiltering: false },
          { code: "Foretell", isFiltering: false },
          { code: "Fortify", isFiltering: false },
          { code: "Frenzy", isFiltering: false },
          { code: "Fuse", isFiltering: false },
          { code: "Graft", isFiltering: false },
          { code: "Gravestorm", isFiltering: false },
          { code: "Haste", isFiltering: false },
          { code: "Haunt", isFiltering: false },
          { code: "Hexproof", isFiltering: false },
          { code: "Hidden agenda", isFiltering: false },
          { code: "Hideaway", isFiltering: false },
          { code: "Horsemanship", isFiltering: false },
          { code: "Improvise", isFiltering: false },
          { code: "Indestructible", isFiltering: false },
          { code: "Infect", isFiltering: false },
          { code: "Ingest", isFiltering: false },
          { code: "Intimidate", isFiltering: false },
          { code: "Islandcycling", isFiltering: false },
          { code: "Islandwalk", isFiltering: false },
          { code: "Jump-start", isFiltering: false },
          { code: "Kicker", isFiltering: false },
          { code: "Landcycling", isFiltering: false },
          { code: "Landwalk", isFiltering: false },
          { code: "Legendary landwalk", isFiltering: false },
          { code: "Level Up", isFiltering: false },
          { code: "Lifelink", isFiltering: false },
          { code: "Living weapon", isFiltering: false },
          { code: "Madness", isFiltering: false },
          { code: "Megamorph", isFiltering: false },
          { code: "Melee", isFiltering: false },
          { code: "Menace", isFiltering: false },
          { code: "Mentor", isFiltering: false },
          { code: "Miracle", isFiltering: false },
          { code: "Modular", isFiltering: false },
          { code: "Morph", isFiltering: false },
          { code: "Mountaincycling", isFiltering: false },
          { code: "Mountainwalk", isFiltering: false },
          { code: "Multikicker", isFiltering: false },
          { code: "Mutate", isFiltering: false },
          { code: "Myriad", isFiltering: false },
          { code: "Ninjutsu", isFiltering: false },
          { code: "Nonbasic landwalk", isFiltering: false },
          { code: "Offering", isFiltering: false },
          { code: "Outlast", isFiltering: false },
          { code: "Overload", isFiltering: false },
          { code: "Partner", isFiltering: false },
          { code: "Persist", isFiltering: false },
          { code: "Phasing", isFiltering: false },
          { code: "Plainscycling", isFiltering: false },
          { code: "Plainswalk", isFiltering: false },
          { code: "Poisonous", isFiltering: false },
          { code: "Protection", isFiltering: false },
          { code: "Provoke", isFiltering: false },
          { code: "Prowess", isFiltering: false },
          { code: "Prowl", isFiltering: false },
          { code: "Rampage", isFiltering: false },
          { code: "Reach", isFiltering: false },
          { code: "Rebound", isFiltering: false },
          { code: "Recover", isFiltering: false },
          { code: "Reinforce", isFiltering: false },
          { code: "Renown", isFiltering: false },
          { code: "Replicate", isFiltering: false },
          { code: "Retrace", isFiltering: false },
          { code: "Riot", isFiltering: false },
          { code: "Ripple", isFiltering: false },
          { code: "Scavenge", isFiltering: false },
          { code: "Shadow", isFiltering: false },
          { code: "Shroud", isFiltering: false },
          { code: "Skulk", isFiltering: false },
          { code: "Slivercycling", isFiltering: false },
          { code: "Soulbond", isFiltering: false },
          { code: "Soulshift", isFiltering: false },
          { code: "Spectacle", isFiltering: false },
          { code: "Splice", isFiltering: false },
          { code: "Split second", isFiltering: false },
          { code: "Storm", isFiltering: false },
          { code: "Sunburst", isFiltering: false },
          { code: "Surge", isFiltering: false },
          { code: "Suspend", isFiltering: false },
          { code: "Swampcycling", isFiltering: false },
          { code: "Swampwalk", isFiltering: false },
          { code: "Totem armor", isFiltering: false },
          { code: "Trample", isFiltering: false },
          { code: "Transfigure", isFiltering: false },
          { code: "Transmute", isFiltering: false },
          { code: "Tribute", isFiltering: false },
          { code: "Typecycling", isFiltering: false },
          { code: "Undaunted", isFiltering: false },
          { code: "Undying", isFiltering: false },
          { code: "Unearth", isFiltering: false },
          { code: "Unleash", isFiltering: false },
          { code: "Vanishing", isFiltering: false },
          { code: "Vigilance", isFiltering: false },
          { code: "Wither", isFiltering: false },
          { code: "Wizardcycling", isFiltering: false },
        ],
      },
      {
        main: "Keyword Actions",
        sub: [
          { code: "Abandon", isFiltering: false },
          { code: "Activate", isFiltering: false },
          { code: "Adapt", isFiltering: false },
          { code: "Amass", isFiltering: false },
          { code: "Assemble", isFiltering: false },
          { code: "Attach", isFiltering: false },
          { code: "Bolster", isFiltering: false },
          { code: "Cast", isFiltering: false },
          { code: "Clash", isFiltering: false },
          { code: "Counter", isFiltering: false },
          { code: "Create", isFiltering: false },
          { code: "Destroy", isFiltering: false },
          { code: "Detain", isFiltering: false },
          { code: "Discard", isFiltering: false },
          { code: "Double", isFiltering: false },
          { code: "Exchange", isFiltering: false },
          { code: "Exert", isFiltering: false },
          { code: "Exile", isFiltering: false },
          { code: "Explore", isFiltering: false },
          { code: "Fateseal", isFiltering: false },
          { code: "Fight", isFiltering: false },
          { code: "Goad", isFiltering: false },
          { code: "Investigate", isFiltering: false },
          { code: "Learn", isFiltering: false },
          { code: "Manifest", isFiltering: false },
          { code: "Meld", isFiltering: false },
          { code: "Mill", isFiltering: false },
          { code: "Monstrosity", isFiltering: false },
          { code: "Planeswalk", isFiltering: false },
          { code: "Play", isFiltering: false },
          { code: "Populate", isFiltering: false },
          { code: "Proliferate", isFiltering: false },
          { code: "Regenerate", isFiltering: false },
          { code: "Reveal", isFiltering: false },
          { code: "Sacrifice", isFiltering: false },
          { code: "Scry", isFiltering: false },
          { code: "Set in motion", isFiltering: false },
          { code: "Shuffle", isFiltering: false },
          { code: "Support", isFiltering: false },
          { code: "Surveil", isFiltering: false },
          { code: "Tap", isFiltering: false },
          { code: "Transform", isFiltering: false },
          { code: "Untap", isFiltering: false },
          { code: "Vote", isFiltering: false },
        ],
      },
      {
        main: "Ability Words",
        sub: [
          { code: "Adamant", isFiltering: false },
          { code: "Addendum", isFiltering: false },
          { code: "Battalion", isFiltering: false },
          { code: "Bloodrush", isFiltering: false },
          { code: "Channel", isFiltering: false },
          { code: "Chroma", isFiltering: false },
          { code: "Cohort", isFiltering: false },
          { code: "Constellation", isFiltering: false },
          { code: "Converge", isFiltering: false },
          { code: "Council's dilemma", isFiltering: false },
          { code: "Delirium", isFiltering: false },
          { code: "Domain", isFiltering: false },
          { code: "Eminence", isFiltering: false },
          { code: "Enrage", isFiltering: false },
          { code: "Fateful hour", isFiltering: false },
          { code: "Ferocious", isFiltering: false },
          { code: "Formidable", isFiltering: false },
          { code: "Grandeur", isFiltering: false },
          { code: "Hellbent", isFiltering: false },
          { code: "Hero's Reward", isFiltering: false },
          { code: "Heroic", isFiltering: false },
          { code: "Imprint", isFiltering: false },
          { code: "Inspired", isFiltering: false },
          { code: "Join forces", isFiltering: false },
          { code: "Kinfall", isFiltering: false },
          { code: "Kinship", isFiltering: false },
          { code: "Landfall", isFiltering: false },
          { code: "Landship", isFiltering: false },
          { code: "Legacy", isFiltering: false },
          { code: "Lieutenant", isFiltering: false },
          { code: "Metalcraft", isFiltering: false },
          { code: "Morbid", isFiltering: false },
          { code: "Pack Tactics", isFiltering: false },
          { code: "Parley", isFiltering: false },
          { code: "Radiance", isFiltering: false },
          { code: "Raid", isFiltering: false },
          { code: "Rally", isFiltering: false },
          { code: "Revolt", isFiltering: false },
          { code: "Spell mastery", isFiltering: false },
          { code: "Strive", isFiltering: false },
          { code: "Sweep", isFiltering: false },
          { code: "Tempting offer", isFiltering: false },
          { code: "Threshold", isFiltering: false },
          { code: "Underdog", isFiltering: false },
          { code: "Undergrowth", isFiltering: false },
          { code: "Will of the council", isFiltering: false },
        ],
      },
    ],
    types: [
      {
        main: "Types",
        sub: [
          {
            code: "Artifact",
            isFiltering: false,
            toExclude: false,
            isPermanent: true,
            svg: svgArtifact,
          },
          {
            code: "Creature",
            isFiltering: false,
            toExclude: false,
            isPermanent: true,
            svg: svgCreature,
          },
          {
            code: "Enchantment",
            isFiltering: false,
            toExclude: false,
            isPermanent: true,
            svg: svgEnchantment,
          },
          {
            code: "Land",
            isFiltering: false,
            toExclude: false,
            isPermanent: true,
            svg: svgLandSymbol,
          },
          {
            code: "Basic",
            isFiltering: false,
            toExclude: false,
            isPermanent: true,
            svg: svgLandSymbolBasic,
          },
          {
            code: "Planeswalker",
            isFiltering: false,
            toExclude: false,
            isPermanent: true,
            svg: svgPW,
          },
          {
            code: "Instant",
            isFiltering: false,
            toExclude: false,
            isPermanent: true,
            svg: svgInstant,
          },
          {
            code: "Sorcery",
            isFiltering: false,
            toExclude: false,
            isPermanent: true,
            svg: svgSorcery,
          },
          {
            code: "Conspiracy",
            isFiltering: false,
            toExclude: false,
          },
          {
            code: "Phenomenon",
            isFiltering: false,
            toExclude: false,
          },
          {
            code: "Plane",
            isFiltering: false,
            toExclude: false,
          },
          {
            code: "Scheme",
            isFiltering: false,
            toExclude: false,
          },
          {
            code: "Vanguard",
            isFiltering: false,
            toExclude: false,
          },
          {
            code: "Dungeon",
            isFiltering: false,
            toExclude: false,
          },
        ],
      },
      {
        main: "Super Types",
        sub: [
          /* { code: "Basic", isFiltering: false, toExclude: false }, */
          { code: "Elite", isFiltering: false, toExclude: false },
          { code: "Legendary", isFiltering: false, toExclude: false },
          { code: "Ongoing", isFiltering: false, toExclude: false },
          { code: "Snow", isFiltering: false, toExclude: false },
          { code: "Token", isFiltering: false, toExclude: false },
          { code: "World", isFiltering: false, toExclude: false },
        ],
      },
      {
        main: "Artifact Types",
        sub: [
          { code: "Clue", isFiltering: false, toExclude: false },
          { code: "Contraption", isFiltering: false, toExclude: false },
          { code: "Equipment", isFiltering: false, toExclude: false },
          { code: "Food", isFiltering: false, toExclude: false },
          { code: "Fortification", isFiltering: false, toExclude: false },
          { code: "Gold", isFiltering: false, toExclude: false },
          { code: "Treasure", isFiltering: false, toExclude: false },
          { code: "Vehicle", isFiltering: false, toExclude: false },
        ],
      },
      {
        main: "Enchantment Types",
        sub: [
          { code: "Aura", isFiltering: false, toExclude: false },
          { code: "Cartouche", isFiltering: false, toExclude: false },
          { code: "Class", isFiltering: false, toExclude: false },
          { code: "Curse", isFiltering: false, toExclude: false },
          { code: "Saga", isFiltering: false, toExclude: false },
          { code: "Shrine", isFiltering: false, toExclude: false },
        ],
      },
      {
        main: "Land Types",
        sub: [
          { code: "Desert", isFiltering: false, toExclude: false },
          { code: "Forest", isFiltering: false, toExclude: false },
          { code: "Gate", isFiltering: false, toExclude: false },
          { code: "Island", isFiltering: false, toExclude: false },
          { code: "Lair", isFiltering: false, toExclude: false },
          { code: "Locus", isFiltering: false, toExclude: false },
          { code: "Mine", isFiltering: false, toExclude: false },
          { code: "Mountains", isFiltering: false, toExclude: false },
          { code: "Power-Plant", isFiltering: false, toExclude: false },
          { code: "Swamp", isFiltering: false, toExclude: false },
          { code: "Tower", isFiltering: false, toExclude: false },
          { code: "Urza's", isFiltering: false, toExclude: false },
        ],
      },
      {
        main: "Spell Types",
        sub: [
          { code: "Adventure", isFiltering: false, toExclude: false },
          { code: "Arcane", isFiltering: false, toExclude: false },
          { code: "Trap", isFiltering: false, toExclude: false },
        ],
      },
      {
        main: "Multiple Types",
        sub: [
          { code: "Artifact Land", isFiltering: false, toExclude: false },
          { code: "Artifact Creature", isFiltering: false, toExclude: false },
          {
            code: "Enchantment Artifact",
            isFiltering: false,
            toExclude: false,
          },
          {
            code: "Enchantment Artifact Creature",
            isFiltering: false,
            toExclude: false,
          },
          {
            code: "Enchantment Creature",
            isFiltering: false,
            toExclude: false,
          },
          { code: "Enchantment Land", isFiltering: false, toExclude: false },
          { code: "Instant Creature", isFiltering: false, toExclude: false },
          { code: "Land Creature", isFiltering: false, toExclude: false },
        ],
      },
      {
        main: "Creature Types",
        sub: [
          { code: "Advisor", isFiltering: false, toExclude: false },
          { code: "Aetherborn", isFiltering: false, toExclude: false },
          { code: "Ally", isFiltering: false, toExclude: false },
          { code: "Angel", isFiltering: false, toExclude: false },
          { code: "Antelope", isFiltering: false, toExclude: false },
          { code: "Ape", isFiltering: false, toExclude: false },
          { code: "Archer", isFiltering: false, toExclude: false },
          { code: "Archon", isFiltering: false, toExclude: false },
          { code: "Army", isFiltering: false, toExclude: false },
          { code: "Artificer", isFiltering: false, toExclude: false },
          { code: "Assassin", isFiltering: false, toExclude: false },
          { code: "Assembly-Worker", isFiltering: false, toExclude: false },
          { code: "Atog", isFiltering: false, toExclude: false },
          { code: "Aurochs", isFiltering: false, toExclude: false },
          { code: "Avatar", isFiltering: false, toExclude: false },
          { code: "Azra", isFiltering: false, toExclude: false },
          { code: "Badger", isFiltering: false, toExclude: false },
          { code: "Barbarian", isFiltering: false, toExclude: false },
          { code: "Bard", isFiltering: false, toExclude: false },
          { code: "Basilisk", isFiltering: false, toExclude: false },
          { code: "Bat", isFiltering: false, toExclude: false },
          { code: "Bear", isFiltering: false, toExclude: false },
          { code: "Beast", isFiltering: false, toExclude: false },
          { code: "Beeble", isFiltering: false, toExclude: false },
          { code: "Beholder", isFiltering: false, toExclude: false },
          { code: "Berserker", isFiltering: false, toExclude: false },
          { code: "Bird", isFiltering: false, toExclude: false },
          { code: "Blinkmoth", isFiltering: false, toExclude: false },
          { code: "Boar", isFiltering: false, toExclude: false },
          { code: "Bringer", isFiltering: false, toExclude: false },
          { code: "Brushwagg", isFiltering: false, toExclude: false },
          { code: "Camarid", isFiltering: false, toExclude: false },
          { code: "Camel", isFiltering: false, toExclude: false },
          { code: "Caribou", isFiltering: false, toExclude: false },
          { code: "Carrier", isFiltering: false, toExclude: false },
          { code: "Cat", isFiltering: false, toExclude: false },
          { code: "Centaur", isFiltering: false, toExclude: false },
          { code: "Cephalid", isFiltering: false, toExclude: false },
          { code: "Chimera", isFiltering: false, toExclude: false },
          { code: "Citizen", isFiltering: false, toExclude: false },
          { code: "Cleric", isFiltering: false, toExclude: false },
          { code: "Cockatrice", isFiltering: false, toExclude: false },
          { code: "Construct", isFiltering: false, toExclude: false },
          { code: "Coward", isFiltering: false, toExclude: false },
          { code: "Crab", isFiltering: false, toExclude: false },
          { code: "Crocodile", isFiltering: false, toExclude: false },
          { code: "Cyclops", isFiltering: false, toExclude: false },
          { code: "Dauthi", isFiltering: false, toExclude: false },
          { code: "Demigod", isFiltering: false, toExclude: false },
          { code: "Demon", isFiltering: false, toExclude: false },
          { code: "Deserter", isFiltering: false, toExclude: false },
          { code: "Devil", isFiltering: false, toExclude: false },
          { code: "Dinosaur", isFiltering: false, toExclude: false },
          { code: "Djinn", isFiltering: false, toExclude: false },
          { code: "Dog", isFiltering: false, toExclude: false },
          { code: "Dragon", isFiltering: false, toExclude: false },
          { code: "Drake", isFiltering: false, toExclude: false },
          { code: "Dreadnought", isFiltering: false, toExclude: false },
          { code: "Drone", isFiltering: false, toExclude: false },
          { code: "Druid", isFiltering: false, toExclude: false },
          { code: "Dryad", isFiltering: false, toExclude: false },
          { code: "Dwarf", isFiltering: false, toExclude: false },
          { code: "Efreet", isFiltering: false, toExclude: false },
          { code: "Egg", isFiltering: false, toExclude: false },
          { code: "Elder", isFiltering: false, toExclude: false },
          { code: "Eldrazi", isFiltering: false, toExclude: false },
          { code: "Elemental", isFiltering: false, toExclude: false },
          { code: "Elephant", isFiltering: false, toExclude: false },
          { code: "Elf", isFiltering: false, toExclude: false },
          { code: "Elk", isFiltering: false, toExclude: false },
          { code: "Eye", isFiltering: false, toExclude: false },
          { code: "Faerie", isFiltering: false, toExclude: false },
          { code: "Ferret", isFiltering: false, toExclude: false },
          { code: "Fish", isFiltering: false, toExclude: false },
          { code: "Flagbearer", isFiltering: false, toExclude: false },
          { code: "Fox", isFiltering: false, toExclude: false },
          { code: "Frog", isFiltering: false, toExclude: false },
          { code: "Fungus", isFiltering: false, toExclude: false },
          { code: "Gargoyle", isFiltering: false, toExclude: false },
          { code: "Germ", isFiltering: false, toExclude: false },
          { code: "Giant", isFiltering: false, toExclude: false },
          { code: "Gnoll", isFiltering: false, toExclude: false },
          { code: "Gnome", isFiltering: false, toExclude: false },
          { code: "Goat", isFiltering: false, toExclude: false },
          { code: "Goblin", isFiltering: false, toExclude: false },
          { code: "God", isFiltering: false, toExclude: false },
          { code: "Golem", isFiltering: false, toExclude: false },
          { code: "Gorgon", isFiltering: false, toExclude: false },
          { code: "Graveborn", isFiltering: false, toExclude: false },
          { code: "Gremlin", isFiltering: false, toExclude: false },
          { code: "Griffin", isFiltering: false, toExclude: false },
          { code: "Hag", isFiltering: false, toExclude: false },
          { code: "Halfling", isFiltering: false, toExclude: false },
          { code: "Hamster", isFiltering: false, toExclude: false },
          { code: "Harpy", isFiltering: false, toExclude: false },
          { code: "Hellion", isFiltering: false, toExclude: false },
          { code: "Hippo", isFiltering: false, toExclude: false },
          { code: "Hippogriff", isFiltering: false, toExclude: false },
          { code: "Homarid", isFiltering: false, toExclude: false },
          { code: "Homunculus", isFiltering: false, toExclude: false },
          { code: "Horror", isFiltering: false, toExclude: false },
          { code: "Horse", isFiltering: false, toExclude: false },
          { code: "Human", isFiltering: false, toExclude: false },
          { code: "Hydra", isFiltering: false, toExclude: false },
          { code: "Hyena", isFiltering: false, toExclude: false },
          { code: "Illusion", isFiltering: false, toExclude: false },
          { code: "Imp", isFiltering: false, toExclude: false },
          { code: "Incarnation", isFiltering: false, toExclude: false },
          { code: "Insect", isFiltering: false, toExclude: false },
          { code: "Jackal", isFiltering: false, toExclude: false },
          { code: "Jellyfish", isFiltering: false, toExclude: false },
          { code: "Juggernaut", isFiltering: false, toExclude: false },
          { code: "Kavu", isFiltering: false, toExclude: false },
          { code: "Kirin", isFiltering: false, toExclude: false },
          { code: "Kithkin", isFiltering: false, toExclude: false },
          { code: "Knight", isFiltering: false, toExclude: false },
          { code: "Kobold", isFiltering: false, toExclude: false },
          { code: "Kor", isFiltering: false, toExclude: false },
          { code: "Kraken", isFiltering: false, toExclude: false },
          { code: "Lamia", isFiltering: false, toExclude: false },
          { code: "Lammasu", isFiltering: false, toExclude: false },
          { code: "Leech", isFiltering: false, toExclude: false },
          { code: "Leviathan", isFiltering: false, toExclude: false },
          { code: "Lhurgoyf", isFiltering: false, toExclude: false },
          { code: "Licid", isFiltering: false, toExclude: false },
          { code: "Lizard", isFiltering: false, toExclude: false },
          { code: "Manticore", isFiltering: false, toExclude: false },
          { code: "Masticore", isFiltering: false, toExclude: false },
          { code: "Mercenary", isFiltering: false, toExclude: false },
          { code: "Merfolk", isFiltering: false, toExclude: false },
          { code: "Metathran", isFiltering: false, toExclude: false },
          { code: "Minion", isFiltering: false, toExclude: false },
          { code: "Minotaur", isFiltering: false, toExclude: false },
          { code: "Mole", isFiltering: false, toExclude: false },
          { code: "Monger", isFiltering: false, toExclude: false },
          { code: "Mongoose", isFiltering: false, toExclude: false },
          { code: "Monk", isFiltering: false, toExclude: false },
          { code: "Monkey", isFiltering: false, toExclude: false },
          { code: "Moonfolk", isFiltering: false, toExclude: false },
          { code: "Mouse", isFiltering: false, toExclude: false },
          { code: "Mutant", isFiltering: false, toExclude: false },
          { code: "Myr", isFiltering: false, toExclude: false },
          { code: "Mystic", isFiltering: false, toExclude: false },
          { code: "Naga", isFiltering: false, toExclude: false },
          { code: "Nautilus", isFiltering: false, toExclude: false },
          { code: "Nephilim", isFiltering: false, toExclude: false },
          { code: "Nightmare", isFiltering: false, toExclude: false },
          { code: "Nightstalker", isFiltering: false, toExclude: false },
          { code: "Ninja", isFiltering: false, toExclude: false },
          { code: "Noble", isFiltering: false, toExclude: false },
          { code: "Noggle", isFiltering: false, toExclude: false },
          { code: "Nomad", isFiltering: false, toExclude: false },
          { code: "Nymph", isFiltering: false, toExclude: false },
          { code: "Octopus", isFiltering: false, toExclude: false },
          { code: "Ogre", isFiltering: false, toExclude: false },
          { code: "Ooze", isFiltering: false, toExclude: false },
          { code: "Orb", isFiltering: false, toExclude: false },
          { code: "Orc", isFiltering: false, toExclude: false },
          { code: "Orgg", isFiltering: false, toExclude: false },
          { code: "Otter", isFiltering: false, toExclude: false },
          { code: "Ouphe", isFiltering: false, toExclude: false },
          { code: "Ox", isFiltering: false, toExclude: false },
          { code: "Oyster", isFiltering: false, toExclude: false },
          { code: "Pangolin", isFiltering: false, toExclude: false },
          { code: "Peasant", isFiltering: false, toExclude: false },
          { code: "Pegasus", isFiltering: false, toExclude: false },
          { code: "Pentavite", isFiltering: false, toExclude: false },
          { code: "Pest", isFiltering: false, toExclude: false },
          { code: "Phelddagrif", isFiltering: false, toExclude: false },
          { code: "Phoenix", isFiltering: false, toExclude: false },
          { code: "Pilot", isFiltering: false, toExclude: false },
          { code: "Pincher", isFiltering: false, toExclude: false },
          { code: "Pirate", isFiltering: false, toExclude: false },
          { code: "Plant", isFiltering: false, toExclude: false },
          { code: "Praetor", isFiltering: false, toExclude: false },
          { code: "Prism", isFiltering: false, toExclude: false },
          { code: "Processor", isFiltering: false, toExclude: false },
          { code: "Rabbit", isFiltering: false, toExclude: false },
          { code: "Rat", isFiltering: false, toExclude: false },
          { code: "Rebel", isFiltering: false, toExclude: false },
          { code: "Reflection", isFiltering: false, toExclude: false },
          { code: "Rhino", isFiltering: false, toExclude: false },
          { code: "Rigger", isFiltering: false, toExclude: false },
          { code: "Rogue", isFiltering: false, toExclude: false },
          { code: "Sable", isFiltering: false, toExclude: false },
          { code: "Salamander", isFiltering: false, toExclude: false },
          { code: "Samurai", isFiltering: false, toExclude: false },
          { code: "Sand", isFiltering: false, toExclude: false },
          { code: "Saproling", isFiltering: false, toExclude: false },
          { code: "Satyr", isFiltering: false, toExclude: false },
          { code: "Scarecrow", isFiltering: false, toExclude: false },
          { code: "Scion", isFiltering: false, toExclude: false },
          { code: "Scorpion", isFiltering: false, toExclude: false },
          { code: "Scout", isFiltering: false, toExclude: false },
          { code: "Sculpture", isFiltering: false, toExclude: false },
          { code: "Serf", isFiltering: false, toExclude: false },
          { code: "Serpent", isFiltering: false, toExclude: false },
          { code: "Servo", isFiltering: false, toExclude: false },
          { code: "Shade", isFiltering: false, toExclude: false },
          { code: "Shaman", isFiltering: false, toExclude: false },
          { code: "Shapeshifter", isFiltering: false, toExclude: false },
          { code: "Shark", isFiltering: false, toExclude: false },
          { code: "Sheep", isFiltering: false, toExclude: false },
          { code: "Siren", isFiltering: false, toExclude: false },
          { code: "Skeleton", isFiltering: false, toExclude: false },
          { code: "Slith", isFiltering: false, toExclude: false },
          { code: "Sliver", isFiltering: false, toExclude: false },
          { code: "Slug", isFiltering: false, toExclude: false },
          { code: "Snake", isFiltering: false, toExclude: false },
          { code: "Soldier", isFiltering: false, toExclude: false },
          { code: "Soltari", isFiltering: false, toExclude: false },
          { code: "Spawn", isFiltering: false, toExclude: false },
          { code: "Specter", isFiltering: false, toExclude: false },
          { code: "Spellshaper", isFiltering: false, toExclude: false },
          { code: "Sphinx", isFiltering: false, toExclude: false },
          { code: "Spider", isFiltering: false, toExclude: false },
          { code: "Spike", isFiltering: false, toExclude: false },
          { code: "Spirit", isFiltering: false, toExclude: false },
          { code: "Splinter", isFiltering: false, toExclude: false },
          { code: "Sponge", isFiltering: false, toExclude: false },
          { code: "Squid", isFiltering: false, toExclude: false },
          { code: "Squirrel", isFiltering: false, toExclude: false },
          { code: "Starfish", isFiltering: false, toExclude: false },
          { code: "Surrakar", isFiltering: false, toExclude: false },
          { code: "Survivor", isFiltering: false, toExclude: false },
          { code: "Tentacle", isFiltering: false, toExclude: false },
          { code: "Tetravite", isFiltering: false, toExclude: false },
          { code: "Thalakos", isFiltering: false, toExclude: false },
          { code: "Thopter", isFiltering: false, toExclude: false },
          { code: "Thrull", isFiltering: false, toExclude: false },
          { code: "Tiefling", isFiltering: false, toExclude: false },
          { code: "Treefolk", isFiltering: false, toExclude: false },
          { code: "Trilobite", isFiltering: false, toExclude: false },
          { code: "Triskelavite", isFiltering: false, toExclude: false },
          { code: "Troll", isFiltering: false, toExclude: false },
          { code: "Turtle", isFiltering: false, toExclude: false },
          { code: "Unicorn", isFiltering: false, toExclude: false },
          { code: "Vampire", isFiltering: false, toExclude: false },
          { code: "Vedalken", isFiltering: false, toExclude: false },
          { code: "Viashino", isFiltering: false, toExclude: false },
          { code: "Volver", isFiltering: false, toExclude: false },
          { code: "Wall", isFiltering: false, toExclude: false },
          { code: "Warlock", isFiltering: false, toExclude: false },
          { code: "Warrior", isFiltering: false, toExclude: false },
          { code: "Weird", isFiltering: false, toExclude: false },
          { code: "Werewolf", isFiltering: false, toExclude: false },
          { code: "Whale", isFiltering: false, toExclude: false },
          { code: "Wizard", isFiltering: false, toExclude: false },
          { code: "Wolf", isFiltering: false, toExclude: false },
          { code: "Wolverine", isFiltering: false, toExclude: false },
          { code: "Wombat", isFiltering: false, toExclude: false },
          { code: "Worm", isFiltering: false, toExclude: false },
          { code: "Wraith", isFiltering: false, toExclude: false },
          { code: "Wurm", isFiltering: false, toExclude: false },
          { code: "Yeti", isFiltering: false, toExclude: false },
          { code: "Zombie", isFiltering: false, toExclude: false },
          { code: "Zubera", isFiltering: false, toExclude: false },
        ],
      },
    ],
  }

  const filtersReducer = (state, action) => {
    switch (action.type) {
      /*       case "SET_SEARCH_MODE":
        return { ...state, search_mode: action.mode } */
      case "SWITCH_SEARCH_MODE":
        let newMode
        if (state.search_mode === "exact") newMode = "including"
        else newMode = "exact"
        return {
          ...state,
          search_mode: newMode,
          indexes: {
            from: 0,
            to: 30,
          },
        }
      case "SWITCH_KEYWORDS_SEARCH_MODE":
        let newKeywordsMode
        if (state.keywords_search_mode === "one of these")
          newKeywordsMode = "all of these"
        else newKeywordsMode = "one of these"
        return {
          ...state,
          keywords_search_mode: newKeywordsMode,
          indexes: {
            from: 0,
            to: 30,
          },
        }
      case "DELETE_TEMP_FILTER":
        // action object -->  {type: 'DELETE_TEMP-FILTER', key: colors|legalities, code: code}
        const newArray = state[action.key].map(obj => {
          if (obj.code === action.code) {
            return {
              ...obj,
              isFiltering: false,
              hasBeenClickedBefore: false,
            }
          } else {
            return obj
          }
        })
        return { ...state, [action.key]: newArray }
      case "SWITCH_IS_FILTERING":
        const newMoreColorsArrayTwo = state[action.key].map(obj => {
          if (obj.code === action.code) {
            return {
              ...obj,
              isFiltering: !obj.isFiltering,
              hasBeenClickedBefore: true,
            }
          } else {
            return obj
          }
        })
        return {
          ...state,
          [action.key]: newMoreColorsArrayTwo,
          indexes: {
            from: 0,
            to: 30,
          },
        }
      case "SWITCH_SUGGEST":
        const newSwitchIsFilteringType = state[action.key].map(typeObj => {
          return {
            ...typeObj,
            indexes: {
              from: 0,
              to: 30,
            },
            sub: typeObj.sub.map(suggestion => {
              if (suggestion.code === action.code) {
                return {
                  ...suggestion,
                  /*                   isFiltering: !suggestion.isFiltering, */
                  [action.propToSwitch]: !suggestion[action.propToSwitch],
                  hasBeenClickedBefore: true,
                  //hasBeen UES
                }
              } else {
                return {
                  ...suggestion,
                }
              }
            }),
          }
        })
        return { ...state, [action.key]: newSwitchIsFilteringType }
      case "DELETE_SUGGESTION":
        const newDeleteType = state[action.key].map(typeObj => {
          return {
            ...typeObj,
            sub: typeObj.sub.map(suggestion => {
              if (suggestion.code === action.code) {
                return {
                  ...suggestion,
                  isFiltering: false,
                  toExclude: false,
                  hasBeenClickedBefore: false,
                }
              } else {
                return {
                  ...suggestion,
                }
              }
            }),
          }
        })
        return { ...state, [action.key]: newDeleteType }
      case "SEARCH_CARD_TEXT":
        return {
          ...state,
          card_text: action.cardText,
          indexes: {
            from: 0,
            to: 30,
          },
        }
      case "SEARCH_CARD_NAME":
        return {
          ...state,
          card_name: action.cardName,
          indexes: {
            from: 0,
            to: 30,
          },
        }
      case "SET_POWER":
        return {
          ...state,
          power: action.powerInput,
          indexes: {
            from: 0,
            to: 30,
          },
        }
      case "SET_TOUGHNESS":
        return {
          ...state,
          toughness: action.toughnessInput,
          indexes: {
            from: 0,
            to: 30,
          },
        }
      case "PAGE_CHANGE":
        if (action.direction === "next") {
          return {
            ...state,
            indexes: {
              from: state.indexes.from + 30,
              to: state.indexes.to + 30,
            },
          }
        } else {
          return {
            ...state,
            indexes: {
              from: state.indexes.from - 30,
              to: state.indexes.to - 30,
            },
          }
        }
      case "reset":
      default:
        return filtersEmptyState
    }
  }

  const [filterParams, dispatchFilterParams] = useReducer(
    filtersReducer,
    filtersEmptyState
  )

  const handleClickSwitchSearchMode = () =>
    dispatchFilterParams({ type: "SWITCH_SEARCH_MODE" })

  const handleSearchCardText = inputCardText =>
    dispatchFilterParams({
      type: "SEARCH_CARD_TEXT",
      cardText: inputCardText,
    })
  const handleSearchCardName = inputCardName =>
    dispatchFilterParams({
      type: "SEARCH_CARD_NAME",
      cardName: inputCardName,
    })

  const handleSwitchIsFiltering = (key, code) => {
    dispatchFilterParams({
      type: "SWITCH_IS_FILTERING",
      key,
      code,
    })
  }
  const handleSwitchSuggest = (key, code, propToSwitch) => {
    dispatchFilterParams({
      type: "SWITCH_SUGGEST",
      key,
      code,
      propToSwitch,
    })
  }
  const handleDeleteSuggestion = (key, code) => {
    dispatchFilterParams({
      type: "DELETE_SUGGESTION",
      key,
      code,
    })
  }
  const handleSetPower = powerInput => {
    dispatchFilterParams({
      type: "SET_POWER",
      powerInput,
    })
  }
  const handleSetToughness = toughnessInput => {
    dispatchFilterParams({
      type: "SET_TOUGHNESS",
      toughnessInput,
    })
  }
  const handleDeleteTempFilter = (key, code) => {
    dispatchFilterParams({
      type: "DELETE_TEMP_FILTER",
      key,
      code,
    })
  }

  const handlePageChange = direction => {
    dispatchFilterParams({ type: "PAGE_CHANGE", direction })
  }

  const handleClickSwitchKeywordsSearchMode = () =>
    dispatchFilterParams({ type: "SWITCH_KEYWORDS_SEARCH_MODE" })

  return [
    filterParams,
    dispatchFilterParams,
    handleSearchCardText,
    handleSearchCardName,
    handleSwitchIsFiltering,
    handleSwitchSuggest,
    handleDeleteSuggestion,
    handleClickSwitchSearchMode,
    handleSetPower,
    handleSetToughness,
    handleDeleteTempFilter,
    handlePageChange,
    handleClickSwitchKeywordsSearchMode,
  ]
}
