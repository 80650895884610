import React from "react"

export default (
  <svg viewBox="0 0 600 1200" width={16} height={24}>
    <defs>
      <clipPath id="a">
        <path d="M0 300h147V0H0v300z" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#a)"
      transform="translate(-128.125 398.842) matrix(4.08163 0 0 -4.08163 128.125 815.484)"
    >
      <path
        d="M0 0c-1.245 32.734-4.061 45.164-5.927 45.164-1.894 0-2.49-18.131-4.979-34.153-2.49-15.985-6.874-34.113-6.874-34.113l-11.204 4.268s-3.141 23.131-4.385 50.851c-1.216 27.721-2.164 51.931-5.63 51.931-3.382.029-4.031-22.762-5.276-52.296-1.246-29.517-5.601-45.865-5.601-45.865l-10.283 1.433s-4.98 25.602-6.848 103.807c-.433 18.509-4.951 22.223-4.951 22.223s-4.52-3.714-4.953-22.223C-78.777 12.822-83.785-12.78-83.785-12.78l-10.257-1.433s-4.382 16.348-5.627 45.865c-1.245 29.534-1.869 52.325-5.276 52.296-3.438 0-4.386-24.21-5.659-51.931-1.216-27.72-4.33-50.851-4.33-50.851l-11.204-4.268s-4.382 18.128-6.872 34.113c-2.489 16.022-3.113 34.153-4.979 34.153-1.868 0-4.681-12.43-5.927-45.164-1.245-32.693-1.542-39.084-1.542-39.084s36.777-15.67 51.093-56.223c14.343-40.529 17.969-75.72 18.077-79.627.188-6.064 4.33-6.836 4.33-6.836s3.6.772 4.33 6.836c.459 3.879 3.734 39.098 18.075 79.627C-35.235-54.754 1.542-39.084 1.542-39.084S1.243-32.693 0 0"
        fill="#000"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        transform="translate(145.458 184.26)"
      />
    </g>
  </svg>
)
