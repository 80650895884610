import React, { useState } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

import Suggest from "./suggest"
import ButtonMana from "./buttonMana"
import ButtonType from "./buttonType"
import ButtonFormat from "./buttonFormat"
import ButtonSet from "../components/buttonSet"
import ButtonMotion from "../components/buttonMotion"
import ButtonKeyword from "../components/buttonKeyword"

const variants = {
  open: { x: 0 },
  closed: {
    x: "-100%",
    transition: {
      delay: 0,
      x: { type: "tween" },
    },
  },
}

const listVariants = {
  open: { opacity: 1, height: "auto" },
  closed: { opacity: 0, height: 0 },
}

const AdvancedFilters = ({
  showFilters,
  setShowFilters,
  filterParams,
  handleSwitchSuggest,
  handleSwitchIsFiltering,
  buttonTypeArr,
  buttonSetArr,
  buttonKeywordsArr,
  handleDeleteSuggestion,
  handleDeleteTempFilter,
  handleClickSwitchKeywordsSearchMode,
}) => {
  const [isColorsOpen, switchIsColors] = useState(true)
  const [isTypesOpen, switchIsTypes] = useState(true)
  const [isLegalitiesOpen, switchIsLegalities] = useState(true)
  const [isSetsOpen, switchIsSets] = useState(true)
  const [isKeywordsOpen, switchIsKeywords] = useState(true)

  return (
    <AdvFilters
      variants={variants}
      initial="closed"
      animate={showFilters ? "open" : "closed"}
      transition={{ damping: 300 }}
    >
      <ButtonCloseAdvFilters>
        <ButtonMotion
          handleClick={() => setShowFilters(prev => !prev)}
          text="Binder"
        />
      </ButtonCloseAdvFilters>
      <FilterList>
        <ButtonMotion
          handleClick={() => switchIsColors(prev => !prev)}
          text="Color"
          propRadius="1rem"
        />
        <FilterListAccordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isColorsOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          {filterParams.colors.map(colorObj => (
            <ButtonMana
              key={colorObj.code}
              handleSwitchIsFiltering={handleSwitchIsFiltering}
              handleDeleteTempFilter={handleDeleteTempFilter}
              svg={colorObj.svg}
              mode="advanced"
              {...colorObj}
            />
          ))}
        </FilterListAccordion>
      </FilterList>
      <FilterList>
        <ButtonMotion
          handleClick={() => switchIsKeywords(prev => !prev)}
          text="Keywords"
          propRadius="1rem"
        />
        <FilterListAccordion
          variants={listVariants}
          style={
            isKeywordsOpen ? { overflow: "visible" } : { overflow: "hidden" }
          }
          initial="open"
          animate={isKeywordsOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          <Suggest
            handleSwitchSuggest={handleSwitchSuggest}
            suggestData={filterParams.keywords}
            suggestKey={"keywords"}
          />
          <ButtonMotion
            handleClick={handleClickSwitchKeywordsSearchMode}
            searchMode={filterParams.keywords_search_mode}
          />
          {buttonKeywordsArr.map(suggestionObj => {
            return (
              <ButtonKeyword
                key={suggestionObj.code}
                handleSwitchSuggest={handleSwitchSuggest}
                handleDeleteSuggestion={handleDeleteSuggestion}
                {...suggestionObj}
              />
            )
          })}
        </FilterListAccordion>
      </FilterList>
      <FilterList>
        <ButtonMotion
          handleClick={() => switchIsTypes(prev => !prev)}
          text="Type"
          propRadius="1rem"
        />
        <FilterListAccordion
          variants={listVariants}
          /*           style={{ overflow: "hidden" }} */
          style={isTypesOpen ? { overflow: "visible" } : { overflow: "hidden" }}
          initial="open"
          animate={isTypesOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          <Suggest
            handleSwitchSuggest={handleSwitchSuggest}
            suggestData={filterParams.types}
            suggestKey={"types"}
          />
          {buttonTypeArr.map(suggestionObj => {
            return (
              <ButtonType
                key={suggestionObj.code}
                handleSwitchSuggest={handleSwitchSuggest}
                handleDeleteSuggestion={handleDeleteSuggestion}
                svg={suggestionObj.svg}
                {...suggestionObj}
              />
            )
          })}
        </FilterListAccordion>
      </FilterList>
      <FilterList>
        <ButtonMotion
          handleClick={() => switchIsLegalities(prev => !prev)}
          text="Format"
          propRadius="1rem"
        />
        <FilterListAccordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isLegalitiesOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          {filterParams.legalities.map(formatObj => {
            return (
              <ButtonFormat
                key={formatObj.code}
                handleSwitchIsFiltering={handleSwitchIsFiltering}
                handleDeleteTempFilter={handleDeleteTempFilter}
                mode="advanced"
                {...formatObj}
              />
            )
          })}
        </FilterListAccordion>
      </FilterList>
      <FilterList>
        <ButtonMotion
          handleClick={() => switchIsSets(prev => !prev)}
          text="Set"
          propRadius="1rem"
        />
        <FilterListAccordion
          variants={listVariants}
          /*           style={{ overflow: "hidden" }} */
          style={isSetsOpen ? { overflow: "visible" } : { overflow: "hidden" }}
          initial="open"
          animate={isSetsOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          <Suggest
            handleSwitchSuggest={handleSwitchSuggest}
            suggestData={filterParams.sets}
            suggestKey={"sets"}
          />
          {buttonSetArr.map(suggestionObj => {
            return (
              <ButtonSet
                key={suggestionObj.code}
                handleSwitchSuggest={handleSwitchSuggest}
                handleDeleteSuggestion={handleDeleteSuggestion}
                svg={suggestionObj.svg}
                {...suggestionObj}
              />
            )
          })}
        </FilterListAccordion>
      </FilterList>
    </AdvFilters>
  )
}

const AdvFilters = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: radial-gradient(#555, #111);
  padding: 1rem;
  z-index: 1000000;

  display: flex;
  flex-direction: column;
  /*   justify-content: flex-start; */
  /*   align-items: flex-start; */
`

const FilterList = styled(motion.div)`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
`

const FilterListAccordion = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem;
`

const ButtonCloseAdvFilters = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
`

export default AdvancedFilters
