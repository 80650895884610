import React from "react"

export default (
  <svg viewBox="100 0 500 500" width="24" height="24" fill="#BF4427">
    <path
      d="M3485 4900c-685-42-1338-427-1729-1020-192-290-305-575-367-927-30-168-37-510-15-683 27-205 114-539 141-540 25 0 133 200 188 347l57 153-12 77c-20 131-15 448 10 583 42 232 114 438 216 624l55 98-33 34c-58 60-71 144-34 241 16 44 38 74 92 128 98 97 135 109 295 100l120-7 68 49c208 150 402 242 638 303 507 131 1054 42 1475-240 48-32 83-46 134-56 114-22 175-77 211-189 10-31 37-75 71-115 200-234 346-513 419-800 44-175 57-283 60-505 2-205 3-212 33-305 17-52 45-146 62-208 37-139 46-157 106-212l48-44 19 59c37 122 67 254 88 400 19 130 21 179 16 370-5 227-21 356-68 537-89 352-266 690-507 968-340 394-807 662-1311 751-95 16-378 42-416 37-5 0-64-4-130-8z"
      transform="matrix(.1 0 0 -.1 0 512)"
    />
    <path
      d="M3995 4066c-88-39-130-165-81-241 9-13 65-58 126-100 112-78 139-109 154-180 6-26-3-44-62-132-38-56-92-128-119-160-28-32-55-72-61-90-12-36-2-91 23-129 19-29 11-27-43 14-42 32-108 40-151 17-25-12-41-34-65-87l-33-70-185 329c-308 548-396 674-505 733-31 17-53 18-246 13-132-4-268-1-358 6-167 14-190 10-252-53-71-71-97-161-60-204 11-13 49-28 100-41 124-30 153-59 153-151 0-61-54-229-109-338-56-112-56-148 3-206l43-44h-38c-39-1-87-21-107-44-6-8-49-129-96-269-77-232-161-469-251-705-72-188-255-420-386-490-72-39-101-89-96-162 6-85 66-148 140-146 32 1 62 14 128 56 157 99 310 134 542 124 107-4 146-3 172 8 57 24 90 97 79 174-10 65-41 104-104 129s-67 34-69 148c-3 188 71 436 220 735 53 107 100 207 103 222 9 38-14 87-58 125-41 37-44 43-16 36 11-3 41 0 66 7 54 15 99 64 146 159 18 36 34 68 35 69 6 12 53-18 109-70 155-145 302-435 451-888 102-312 185-650 219-895 9-66 23-132 30-147 24-46 75-78 125-78 64 0 63-1 381 302 157 149 179 175 197 224 12 30 21 67 21 82 0 30-81 231-154 382-81 171-84 251-17 495 52 192 63 266 45 323-7 24-20 52-30 63-25 28-9 23 43-12 53-37 124-44 167-16 51 34 68 19 154-135 216-387 391-891 366-1056-7-50-47-95-159-180-84-63-95-79-95-136 0-109 37-160 124-172 41-6 52-3 115 35 140 85 256 111 497 111 166 0 285-19 417-65 143-51 182-53 245-15 44 27 67 101 51 164-13 51-65 103-225 224-90 67-153 134-179 188-9 20-32 93-51 162-86 314-201 627-376 1026-13 28-68 143-124 256-114 230-117 244-130 505-5 95-12 140-25 166-34 69-90 86-195 62-69-16-80-15-300 10-336 38-345 39-379 23z"
      transform="matrix(.1 0 0 -.1 0 512)"
    />
    <path
      d="M5085 1233c-34-4-52-16-115-77-298-286-635-463-1015-531-124-23-385-31-515-16-415 48-797 229-1107 524l-62 59-193 1c-143 0-204-3-234-14l-42-14 77-95c323-401 774-687 1261-799 310-71 690-71 1000 0 515 118 1002 441 1317 873l52 71-47 12c-44 10-290 14-377 6z"
      transform="matrix(.1 0 0 -.1 0 512)"
    />
  </svg>
)
