import React from "react"

export default (
  <svg viewBox="0 -50 600 800" width={20} height={24}>
    <path
      d="M371.577 908.615c-.987-.987-.946-4.026.09-6.754 1.037-2.727 39.211-63.714 84.832-135.525 45.62-71.812 84.606-133.668 86.633-137.459 5.863-10.962 3.045-25.256-5.971-30.292-7.107-3.97-24.222-1.78-193.599 24.756-102.19 16.01-189.334 29.07-193.65 29.023-12.631-.14-23.668-10.784-23.668-22.83 0-12.774 13.71-39.256 96.334-186.086 76.15-135.322 74.835-130.018 34.293-138.383-13.287-2.741-18.946-12.094-15.537-25.676 2.927-11.662 79.523-137.501 91.128-149.713 5.667-5.963 15.432-12.38 21.702-14.257 14.405-4.316 326.25-4.831 337.49-.558 11.073 4.21 14.825 12.987 10.184 23.823-2.092 4.882-78.067 85.998-168.835 180.257-90.768 94.258-166.691 174.543-168.717 178.41-2.965 5.66-2.887 8.12.402 12.619 3.934 5.38 8.103 4.777 111.688-16.122 59.18-11.94 112.666-21.699 118.857-21.686 14.927.033 20.998 6.645 29.312 31.924 15.487 47.095 20.461 50.828 63.564 47.7 32.04-2.323 40.741.872 37.503 13.775-1.003 3.996-25.177 30.122-53.72 58.058s-89.04 87.358-134.438 132.048C375.3 915.292 376.837 913.875 371.577 908.615z"
      fill="#000"
      transform="translate(-126.245 -111.906)"
    />
  </svg>
)
