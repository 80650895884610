import React, { useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import {
  ShortcutTable,
  ShortcutFilters,
  ShortcutPrevPage,
  ShortcutNextPage,
} from "../utils/shortcuts"
import { useFilters } from "../utils/filtersStore"

import RaccoglitoreCarta from "../components/raccoglitoreCarta"
import AdvancedFilters from "../components/advFilters"
import ButtonBinderTable from "./buttonBinderTable"
import ButtonMana from "../components/buttonMana"
import ButtonCost from "../components/buttonCost"
import ButtonType from "../components/buttonType"
import ButtonFormat from "../components/buttonFormat"
import ButtonSet from "../components/buttonSet"
import ButtonRarity from "../components/buttonRarity"
import ButtonMotion from "../components/buttonMotion"
import InputComponent from "../components/inputComponent"
import ButtonKeyword from "./buttonKeyword"
import HelperView from "./HelperView"
import Card from "./../components/card"

function selectCards(cardsArr, from, to) {
  return cardsArr.slice(from, to)
}

function calculateFlexChosen(chosenCards) {
  const arrChosenCards = []

  for (let i = 0; i < chosenCards.length; i++) {
    let curCard = chosenCards[i]

    if (curCard.type_line.includes("Basic")) {
      continue
    } else {
      /*       curCard.position = {
        x: windowSize.width - 200,
        y: cardsNotDraggedCounter++ * 25,
      } */
      arrChosenCards.push(curCard)
    }

    arrChosenCards
      .sort((a, b) =>
        a.multiverse_ids.length > 0 && b.multiverse_ids.length > 0
          ? a.multiverse_ids[0] - b.multiverse_ids[0]
          : a.cmc - b.cmc
      )
      .sort((a, b) => a.cmc - b.cmc)
  }

  return arrChosenCards
}

const RaccoglitoreComponent = ({
  displayTable,
  setDisplayTable,
  displayRaccoglitore,
  setDisplayRaccoglitore,
  cardPool,
  chosenCards,
  setChosenCards,
  showCTA,
  setShowCTA,
  userTier,
}) => {
  const [
    filterParams,
    dispatchFilterParams,
    handleSearchCardText,
    handleSearchCardName,
    handleSwitchIsFiltering,
    handleSwitchSuggest,
    handleDeleteSuggestion,
    handleClickSwitchSearchMode,
    handleSetPower,
    handleSetToughness,
    handleDeleteTempFilter,
    handlePageChange,
    handleClickSwitchKeywordsSearchMode,
  ] = useFilters()
  let filteredCards = [...cardPool]
  const [showFilters, setShowFilters] = useState(false)
  const [showHelperView, setShowHelperView] = useState(false)

  const arrChosenCards = useMemo(() => calculateFlexChosen(chosenCards), [
    chosenCards,
  ])

  /*   console.log("raccoglitore mounted!") */
  // RARITY
  const filterByRarityBoolean = filterParams.rarities.some(
    rarityObj => rarityObj.isFiltering
  )
  if (filterByRarityBoolean) {
    filteredCards = filteredCards.filter(dbcard => {
      return filterParams.rarities.some(manaCostObj =>
        manaCostObj.isFiltering ? manaCostObj.code === dbcard.rarity : false
      )
    })
  }

  // MANA COST
  const filterByManaCostBoolean = filterParams.manaCosts.some(
    manaCostObj => manaCostObj.isFiltering
  )
  if (filterByManaCostBoolean) {
    filteredCards = filteredCards.filter(dbcard => {
      return filterParams.manaCosts.some(manaCostObj => {
        if (manaCostObj.isFiltering) {
          if (manaCostObj.code === Infinity) {
            return dbcard.cmc > 6
          }
          return dbcard.cmc === manaCostObj.code
        }
        return false
      })
    })
  }

  // COLOR
  const filterByColorsBoolean = filterParams.colors.some(
    colorObj => colorObj.isFiltering
  )
  if (filterByColorsBoolean) {
    filteredCards = filteredCards.filter(dbcard => {
      if (filterParams.search_mode === "exact") {
        return filterParams.colors.some(moreColObj => {
          if (moreColObj.isFiltering) {
            if (dbcard.type_line.includes("Land")) {
              if (moreColObj.code === "") {
                return dbcard.produced_mana?.join("") === "C"
              }
              return (
                dbcard.produced_mana?.sort().join("") ===
                moreColObj.code.split("").sort().join("")
              )
            } else if (dbcard.colors) {
              return (
                dbcard.colors.sort().join("") ===
                moreColObj.code.split("").sort().join("")
              )
            } else if (
              dbcard.card_faces?.length > 0 &&
              dbcard.card_faces[0].colors
            ) {
              return (
                dbcard.card_faces[0].colors.sort().join("") ===
                moreColObj.code.split("").sort().join("")
              )
            }
            /*             if (dbcard.produced_mana) {
              return (
                dbcard.produced_mana.sort().join("") ===
                moreColObj.code.split("").sort().join("")
              )
            } else {
              return (
                dbcard.colors.sort().join("") ===
                moreColObj.code.split("").sort().join("")
              )
            } */
          } else {
            return false
          }
        })
      } else if (filterParams.search_mode === "including") {
        return filterParams.colors.some(moreColObj => {
          if (moreColObj.isFiltering) {
            if (dbcard.type_line.includes("Land") && dbcard.produced_mana) {
              let point = 0
              for (let i = 0; i < dbcard.produced_mana.length; i++) {
                let dbCardLetter = dbcard.produced_mana[i]
                if (moreColObj.code.includes(dbCardLetter)) {
                  point++
                }
              }
              return point >= 0 && point === moreColObj.code.length
            } else if (dbcard.colors) {
              let point = 0
              for (let i = 0; i < dbcard.colors.length; i++) {
                let dbCardLetter = dbcard.colors[i]
                if (moreColObj.code.includes(dbCardLetter)) {
                  point++
                }
              }
              return point >= 0 && point === moreColObj.code.length
            } else if (
              dbcard.card_faces?.length > 0 &&
              dbcard.card_faces[0].colors
            ) {
              let point = 0
              for (let i = 0; i < dbcard.card_faces[0].colors.length; i++) {
                let dbCardLetter = dbcard.card_faces[0].colors[i]
                if (moreColObj.code.includes(dbCardLetter)) {
                  point++
                }
              }
              return point >= 0 && point === moreColObj.code.length
            }
            // il problema è il fatto che le due STRING, una non includes MAI per il modo
            // in cui vengono sortate le lettere! Cinque lettere saranno
            // SEMPRE sortate in maniera diversa IN BASE ALLA COMBINAZIONE!
            // BGRUW.includes(BRW) sarà sempre FALSO! ma BGRUW includes BRW!
            /*             if (dbcard.produced_mana) {
              return dbcard.produced_mana
                .sort()
                .join("")
                .includes(moreColObj.code.split("").sort().join(""))
            } else {
              return dbcard.colors
                .sort()
                .join("")
                .includes(moreColObj.code.split("").sort().join(""))
            } */
          } else {
            return false
          }
        })
      }
    })
  }

  // KEYWORDS
  const buttonKeywordsArr = []
  filterParams.keywords.forEach(keyObj => {
    keyObj.sub.forEach(subObj => {
      if (subObj.isFiltering || subObj.hasBeenClickedBefore) {
        buttonKeywordsArr.push(subObj)
      }
    })
  })
  const filterByKeywordsBoolean = buttonKeywordsArr.some(
    subObj => subObj.isFiltering
  )
  if (filterByKeywordsBoolean) {
    if (filterParams.keywords_search_mode === "one of these") {
      filteredCards = filteredCards.filter(dbcard =>
        buttonKeywordsArr.some(subObj =>
          subObj.isFiltering ? dbcard.keywords.includes(subObj.code) : false
        )
      )
    } else {
      const filteringKeywords = []
      buttonKeywordsArr.forEach(keyObj => {
        if (keyObj.isFiltering) {
          filteringKeywords.push(keyObj.code)
        }
      })
      filteredCards = filteredCards.filter(dbcard => {
        if (filteringKeywords.length > dbcard.keywords.length) {
          return false
        }
        for (let i = 0; i < filteringKeywords.length; i++) {
          if (!dbcard.keywords.includes(filteringKeywords[i])) {
            return false
          }
        }
        return true
      })
    }
  }

  // SET
  const buttonSetArr = []
  filterParams.sets.forEach(setObj => {
    setObj.sub.forEach(suggestionObj => {
      if (suggestionObj.isFiltering || suggestionObj.hasBeenClickedBefore) {
        buttonSetArr.push(suggestionObj)
      }
    })
  })
  const filterBySetBoolean = buttonSetArr.some(setObj => setObj.isFiltering)
  if (filterBySetBoolean) {
    filteredCards = filteredCards.filter(dbcard =>
      buttonSetArr.some(setObj =>
        setObj.isFiltering
          ? dbcard.set === setObj.code ||
            (dbcard.set === "tafr" && setObj.code === "afr")
          : false
      )
    )
  }

  // TYPE
  const buttonTypeArr = []
  filterParams.types.forEach(typeObj => {
    typeObj.sub.forEach(suggestionObj => {
      if (
        suggestionObj.isFiltering ||
        suggestionObj.isPermanent ||
        suggestionObj.hasBeenClickedBefore
      ) {
        buttonTypeArr.push(suggestionObj)
      }
    })
  })
  const filterByTypeBoolean = buttonTypeArr.some(typeObj => typeObj.isFiltering)
  if (filterByTypeBoolean) {
    filteredCards = filteredCards.filter(dbcard => {
      let dbcardMatchesFilter = false
      let dbcardIsNotToExclude = true
      for (let i = 0; i < buttonTypeArr.length; i++) {
        let currentTypeObject = buttonTypeArr[i]
        if (currentTypeObject.isFiltering) {
          if (dbcard.type_line.includes(currentTypeObject.code)) {
            if (!currentTypeObject.toExclude) {
              if (
                currentTypeObject.code === "Land" &&
                dbcard.type_line.includes("Basic")
              ) {
                dbcardMatchesFilter = false
              } else dbcardMatchesFilter = true
            } else if (currentTypeObject.toExclude) {
              dbcardIsNotToExclude = false
            }
          }

          /*           if (!currentTypeObject.toExclude) { // toExclude = false
            if (dbcard.type_line.includes(currentTypeObject.code)) {
              dbcardMatchesFilter = true
            }
          } else if (currentTypeObject.toExclude) {
              if (dbcard.type_line.includes(currentTypeObject.code)) {
                dbcardIsNotToExclude = false // card MUST BE EXCLUDED
              }
          } */
        }
        if (!dbcardIsNotToExclude) {
          break
        }
      }
      return dbcardMatchesFilter && dbcardIsNotToExclude
    })
  }

  // LEGALITIY
  const filterByLegalitiesBoolean = filterParams.legalities.some(
    formatObj => formatObj.isFiltering
  )
  if (filterByLegalitiesBoolean) {
    filteredCards = filteredCards.filter(dbcard =>
      filterParams.legalities.some(formatObj =>
        formatObj.isFiltering
          ? dbcard.legalities[formatObj.code] === "legal"
          : false
      )
    )
  }

  // POWER & TOUGHNESS
  if (filterParams.power.length > 0) {
    filteredCards = filteredCards.filter(dbcard => {
      if (dbcard.colors) {
        return dbcard.power === filterParams.power
      } else if (dbcard.card_faces?.length > 0 && dbcard.card_faces[0].colors) {
        return dbcard.card_faces[0].power === filterParams.power
      }
    })
  }
  if (filterParams.toughness.length > 0) {
    filteredCards = filteredCards.filter(dbcard => {
      if (dbcard.colors) {
        return dbcard.toughness === filterParams.toughness
      } else if (dbcard.card_faces?.length > 0 && dbcard.card_faces[0].colors) {
        return dbcard.card_faces[0].toughness === filterParams.toughness
      }
    })
  }

  // CARD NAME
  if (filterParams.card_name.length > 0) {
    filteredCards = filteredCards.filter(dbcard => {
      return dbcard.name
        .toLowerCase()
        .includes(filterParams.card_name.toLowerCase())
    })
  }

  // CARD TEXT
  if (filterParams.card_text.length > 0) {
    filteredCards = filteredCards.filter(dbcard => {
      return dbcard.oracle_text
        ?.toLowerCase()
        .includes(filterParams.card_text.toLowerCase())
    })
  }

  let nowShowing = selectCards(
    filteredCards,
    filterParams.indexes.from,
    filterParams.indexes.to
  )

  return (
    <RaccoglitoreWrapper displayRaccoglitore={displayRaccoglitore}>
      {displayRaccoglitore !== "none" && (
        <>
          <ShortcutTable
            setDisplayRaccoglitore={setDisplayRaccoglitore}
            setDisplayTable={setDisplayTable}
          />
          <ShortcutFilters setShowFilters={setShowFilters} />
        </>
      )}
      <AdvancedFilters
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        filterParams={filterParams}
        handleSwitchSuggest={handleSwitchSuggest}
        handleSwitchIsFiltering={handleSwitchIsFiltering}
        buttonTypeArr={buttonTypeArr}
        buttonSetArr={buttonSetArr}
        buttonKeywordsArr={buttonKeywordsArr}
        handleDeleteSuggestion={handleDeleteSuggestion}
        handleDeleteTempFilter={handleDeleteTempFilter}
        handleClickSwitchKeywordsSearchMode={
          handleClickSwitchKeywordsSearchMode
        }
      />
      <HelperView
        showHelperView={showHelperView}
        setShowHelperView={setShowHelperView}
        helperPage="binder"
      />
      <RaccoglitoreTools>
        <ButtonBinderTable
          goTo="Table"
          setDisplayRaccoglitore={setDisplayRaccoglitore}
          setDisplayTable={setDisplayTable}
        />
        <ButtonMotion
          handleClick={() => setShowFilters(prev => !prev)}
          text="Filters"
          propPadTop={"0.1rem"}
          propFontSize={"2.2rem"}
        />
        <ButtonMotion
          handleClick={handleClickSwitchSearchMode}
          text="Color Mode:"
          searchMode={filterParams.search_mode}
        />
        {filterParams.colors.map(colorObj => (
          <ButtonMana
            key={colorObj.code}
            handleSwitchIsFiltering={handleSwitchIsFiltering}
            handleDeleteTempFilter={handleDeleteTempFilter}
            svg={colorObj.svg}
            {...colorObj}
          />
        ))}
        <InputComponent
          width="11rem"
          placeholder="name..."
          type="text"
          value={filterParams.card_name}
          handleOnChange={handleSearchCardName}
        />
        <InputComponent
          width="9rem"
          placeholder="text..."
          type="text"
          value={filterParams.card_text}
          handleOnChange={handleSearchCardText}
        />
        {buttonTypeArr.map(suggestionObj => {
          return (
            <ButtonType
              key={suggestionObj.code}
              handleSwitchSuggest={handleSwitchSuggest}
              handleDeleteSuggestion={handleDeleteSuggestion}
              svg={suggestionObj.svg}
              {...suggestionObj}
            />
          )
        })}
        {buttonSetArr.map(suggestionObj => {
          return (
            <ButtonSet
              key={suggestionObj.code}
              handleSwitchSuggest={handleSwitchSuggest}
              handleDeleteSuggestion={handleDeleteSuggestion}
              svg={suggestionObj.svg}
              {...suggestionObj}
            />
          )
        })}
        {filterParams.manaCosts.map(manaObj => {
          return (
            <ButtonCost
              key={manaObj.code}
              handleSwitchIsFiltering={handleSwitchIsFiltering}
              svg={manaObj.svg}
              {...manaObj}
            />
          )
        })}
        {filterParams.rarities.map(rarityObj => (
          <ButtonRarity
            key={rarityObj.code}
            handleSwitchIsFiltering={handleSwitchIsFiltering}
            svg={rarityObj.svg}
            {...rarityObj}
          />
        ))}

        <PowerToughness>
          <InputComponent
            width="5rem"
            placeholder="x"
            type="text"
            value={filterParams.power}
            handleOnChange={handleSetPower}
          />
          <span style={{ color: "#cccccc" }}>/</span>
          <InputComponent
            width="5rem"
            placeholder="x"
            type="text"
            value={filterParams.toughness}
            handleOnChange={handleSetToughness}
          />
        </PowerToughness>
        {filterParams.legalities.map(formatObj => {
          return (
            <ButtonFormat
              key={formatObj.code}
              handleSwitchIsFiltering={handleSwitchIsFiltering}
              handleDeleteTempFilter={handleDeleteTempFilter}
              {...formatObj}
            />
          )
        })}
        {buttonKeywordsArr.map(keywordObj => {
          return (
            <ButtonKeyword
              key={keywordObj.code}
              handleSwitchSuggest={handleSwitchSuggest}
              handleDeleteSuggestion={handleDeleteSuggestion}
              {...keywordObj}
            />
          )
        })}
        {/*         <ButtonMotion
          handleClick={() => {
            dispatchFilterParams({ type: "reset" })
          }}
          text="Reset"
          title="Reset parameters"
        /> */}
        {filterParams.indexes.from > 0 && (
          <ButtonMotion
            handleClick={() => handlePageChange("prev")}
            text="&#x2190;"
          />
        )}
        {filterParams.indexes.from > 0 && displayRaccoglitore !== "none" && (
          <>
            <ShortcutPrevPage doThis={() => handlePageChange("prev")} />
          </>
        )}
        {filterParams.indexes.to < filteredCards.length && (
          <ButtonMotion
            handleClick={() => handlePageChange("next")}
            text="&#x2192;"
          />
        )}
        {filterParams.indexes.to < filteredCards.length &&
          displayRaccoglitore !== "none" && (
            <>
              <ShortcutNextPage doThis={() => handlePageChange("next")} />
            </>
          )}
        <HelperToggle onClick={() => setShowHelperView(true)}>i</HelperToggle>
      </RaccoglitoreTools>
      {!userTier && showCTA ? (
        <>
          <MyText
            as={"p"}
            style={{
              "--font-size": "2.4rem",
              "--margin": "0.5rem",
              "--text-shadow": "0px 7px 4px black",
              "--text-shadow-hover": "0px 7px 4px black",
              /* "--text-shadow": "0px 4px 2px black",
          "--text-shadow-hover": "0px 4px 2px black", */
            }}
          >
            To export, save decks and take advantage of all premium features,
            please consider subscribing!&nbsp;
            <br />
            Each page has a different help section with suggestions and
            shortcuts, just click on&nbsp;
            <GraphicText as={"span"} onClick={() => setShowCTA(false)}>
              &nbsp;i&nbsp;
            </GraphicText>{" "}
            at the top-right of the screen.
            <br />
            Now choose your cards and then press Table.
            <br />~ close this info window&nbsp;
            <GraphicText as={"span"} onClick={() => setShowCTA(false)}>
              &nbsp;x&nbsp;
            </GraphicText>
            &nbsp;~
          </MyText>
        </>
      ) : null}
      <RaccoglitoreMain>
        {nowShowing.map(carta => {
          return (
            <RaccoglitoreCarta
              setChosenCards={setChosenCards}
              key={carta.id}
              {...carta}
            />
          )
        })}
      </RaccoglitoreMain>
      {arrChosenCards.length > 0 ? (
        <AboveChosen>
          <ButtonBinderTable
            goTo="Table"
            setDisplayRaccoglitore={setDisplayRaccoglitore}
            setDisplayTable={setDisplayTable}
          />
        </AboveChosen>
      ) : null}
      <FLEX_Chosen>
        {arrChosenCards.map((card, i) => {
          return (
            <Card
              key={card.unico}
              setChosenCards={setChosenCards}
              {...card}
              isInBinder={true}
            />
          )
        })}
      </FLEX_Chosen>
    </RaccoglitoreWrapper>
  )
}

const RaccoglitoreWrapper = styled.div`
  display: ${props => props.displayRaccoglitore};
  /*   background-color: #ddd; */
  position: relative;
  /*   padding-top: 1rem; */

  background: radial-gradient(#555, #111);
`

const RaccoglitoreTools = styled.div`
  /* background-color: lightgoldenrodyellow; */
  /*  display: relative; */

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 2rem;

  /*   margin-bottom: 1rem; */
`

const RaccoglitoreMain = styled.main`
  /* background-color: lightseagreen; */
  /*   position: relative; */

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 1rem 0.7rem;
`
const AboveChosen = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem;
`

const PowerToughness = styled.div``

export default RaccoglitoreComponent

const HelperToggle = styled.button`
  position: absolute;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  background-color: #cccccc;
  width: 26px;
  height: 26px;
  font-size: 22px;
  top: 10px;
  right: 5px;
  color: #000000;
`
const MyText = styled.p`
  cursor: default;
  font-size: var(--font-size);
  color: #cccccc;
  margin: var(--margin);
  text-shadow: var(--text-shadow);
  text-align: center;

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`

const GraphicText = styled.span`
  border-radius: 50%;
  background-color: #cccccc;
  font-size: 22px;
  color: #000000;
  text-shadow: none;
`

const FLEX_Chosen = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-left: var(--overlap-card);
  margin: 0rem 3rem 0 3rem;
  padding-bottom: 3rem;
  justify-content: space-evenly;
`
