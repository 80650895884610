import React from "react"

export default (
  <svg
    width="1280.000000pt"
    height="1138.000000pt"
    viewBox="0 0 1280.000000 1138.000000"
  >
    <path
      d="M10550 10764c0-463-69-1095-170-1569-12-55-24-115-27-132l-6-33-99 93c-522 487-932 792-1426 1061-505 275-1112 484-1657 571-55 8-112 17-128 20-88 15-318 36-486 45-418 23-906-28-1356-141-859-217-1635-678-2315-1375-641-657-1211-1582-1565-2534-57-154-115-327-115-344 0-5 24 13 53 40 71 67 147 124 307 231 372 248 771 455 1219 635 135 53 134 53 146-11 26-145 131-523 205-736 85-242 255-618 313-689l23-29 38 154c66 266 194 635 320 916 355 796 876 1430 1457 1774 324 192 708 317 1139 371 156 19 582 16 740-6 775-108 1461-414 2163-965 106-83 122-101 91-101-25 0-211-55-391-116-408-136-755-298-1068-498l-80-51 80-7c228-20 477-75 760-170 707-235 1425-654 2150-1253 153-127 195-157 195-141 0 2 11 46 24 97 69 268 113 574 158 1099 17 197 17 998 0 1195-36 422-63 636-123 965-98 535-280 1118-509 1630-28 63-53 116-55 119-3 2-5-49-5-115zM1736 5629c-14-42-131-768-146-904-40-366-44-454-44-925-1-550 12-739 85-1245 94-655 276-1289 542-1893l72-162 2 92c8 320 35 718 69 978 29 228 126 764 140 779 3 3 53-42 112-100 292-287 630-559 987-795 675-445 1421-732 2190-843 293-42 355-46 705-46 402 1 554 14 895 81 1211 236 2259 928 3096 2046 474 634 857 1365 1120 2138 21 63 39 118 39 123 0 4-28-17-63-48-289-258-964-638-1527-859l-115-45-11 37c-7 20-26 98-44 172-62 265-144 525-247 790-68 172-195 434-236 484l-24 29-31-129c-144-587-406-1188-727-1667-570-853-1248-1294-2170-1413-180-23-562-23-745 0-773 98-1486 416-2200 980-47 37-86 71-88 75-1 4 37 18 85 31 129 33 478 150 628 210 238 95 515 235 739 374l99 61-69 7c-731 73-1658 474-2479 1072-101 73-510 412-597 494-27 26-39 32-42 21z"
      transform="matrix(.1 0 0 -.1 0 1138)"
    />
  </svg>
)
